import React, { FunctionComponent } from 'react'
import Badge, { BadgeKind } from '../Badge'
import IconButton, { IconButtonKind } from '../IconButton'
import { AddIcon, EditIcon } from '../Icons'

export type SectionCardProps = {
  children: React.ReactNode
  badgeValue?: number
  showAddButton?: boolean
  showSpecialButton?: boolean
  onAdd?: () => void
  onEdit?: () => void
  title: string
  showAllEntries: boolean
  isExpanded: boolean
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>
}

const SectionCard: FunctionComponent<SectionCardProps> = ({
  children,
  title,
  badgeValue,
  showAddButton,
  showSpecialButton,
  onAdd,
  onEdit,
  showAllEntries,
  setExpanded,
  isExpanded,
}) => (
  <div className="relative w-full bg-white overflow-hidden rounded-md border border-solid border-gray-400 my-6 font-body">
    <div className="bg-white px-4 py-2 border-b border-gray-400 sm:pl-4 sm:pr-4 flex justify-between">
      <div className="flex w-full items-center">
        <h3 className="flex text-base leading-6 font-medium text-gray-700">{title}</h3>
        {showAllEntries ? (
          ''
        ) : (
          <span className={badgeValue ? 'block pl-2' : 'hidden'}>
            <Badge kind={BadgeKind.secondary}>{badgeValue}</Badge>
          </span>
        )}
      </div>
      <div className={showAddButton ? 'flex' : 'hidden'}>
        <IconButton additionalCss="-mt-1 -mb-1" icon={<AddIcon />} onClick={onAdd} kind={IconButtonKind.default} />
      </div>
      <div className={showSpecialButton ? 'flex' : 'hidden'}>
        <IconButton additionalCss="-mt-1 -mb-1" icon={<EditIcon />} onClick={onEdit} kind={IconButtonKind.default} />
      </div>
    </div>
    {showAllEntries ? (
      <ul className={`all overflow-hidden h-auto`}>{children}</ul>
    ) : (
      <ul className={`allNo overflow-hidden ${isExpanded ? 'h-auto' : 'max-h-300px'}`}>{children}</ul>
    )}
    {showAllEntries ? (
      ''
    ) : (
      <>
        {isExpanded ? (
          ''
        ) : (
          <div className="flex absolute z-30 h-16 bg-gradient-to-t from-transparent-white-opacity-85 to-transparent-opacity-0 w-full -mt-16"></div>
        )}
        <div className="bg-white px-2 py-2 border-t border-gray-400 sm:px-4 flex justify-center font-body">
          <span className="text-white text-base hover:text-white bg-gray-600 hover:bg-gray-700 active:bg-gray-900 rounded-full px-4 py-1">
            <a onClick={() => (setExpanded ? setExpanded((prev) => !prev) : null)} href="#">
              {isExpanded ? 'weniger anzeigen' : 'mehr anzeigen'}
            </a>
          </span>
        </div>
      </>
    )}
  </div>
)

export default SectionCard
