import React, { FunctionComponent, useEffect, useState } from 'react'

export enum InputSearchPanelKind {
  overlay = 'bg-white rounded-md shadow-lg box-border flex flex-col overflow-hidden',
  flat = 'bg-white rounded-md box-border border-solid border border-gray-300 flex flex-col overflow-hidden',
}

export type InputSearchPanelProps = {
  name?: string
  id?: string
  kind: InputSearchPanelKind
  children?: React.ReactNode
  placeholderText?: string
  expandedContent?: React.ReactNode
  onType?: (data: string) => void
  selectedPharmacy?: string
  isListViewedActivePharmaciesTable?: boolean
  handleOnBlur?: () => void
  handleOnClick?: () => void
  value?: string
  inputClass?: string
  wrapperClass?: string
  disableShadow?: boolean
  displayCol?: boolean
}

export type Ref = HTMLInputElement

const InputSearchPanel = React.forwardRef<Ref, InputSearchPanelProps>(
  (
    {
      isListViewedActivePharmaciesTable,
      kind = InputSearchPanelKind.overlay,
      children,
      expandedContent,
      placeholderText,
      onType,
      selectedPharmacy = '',
      handleOnBlur,
      handleOnClick,
      value,
      inputClass,
      wrapperClass,
      disableShadow,
      name,
      id,
      displayCol,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
      if (selectedPharmacy) {
        setInputValue(selectedPharmacy)
      } else if (value) {
        setInputValue(value)
      }
    }, [selectedPharmacy, value])

    return (
      <div className={`${kind} ${disableShadow && 'shadow-none'}`}>
        <div
          className={`flex relative right-0 w-full ${isListViewedActivePharmaciesTable ? '' : 'flex-col'}  ${
            displayCol ? 'flex-col' : '2lg:flex-row '
          }`}
        >
          <div className={`${wrapperClass} items-center input w-1/2`}>
            <input
              name={name}
              id={id}
              ref={ref}
              value={inputValue}
              className={
                inputClass
                  ? inputClass
                  : 'box-border form-input font-body block w-full h-12 xl:my-1 xl:mx-1 bg-white px-4 py-2 text-base leading-6 rounded-none border-r border-t-0 border-b-0 border-l-0 focus:outline-none focus:shadow-none focus:bg-blue-50 border-gray-300 focus:border-gray-300 sm:text-base sm:leading-5'
              }
              placeholder={placeholderText}
              onChange={(e) => {
                setInputValue(e.target.value)
                onType(e.target.value)
              }}
              onClick={handleOnClick}
              onBlur={handleOnBlur}
            />
          </div>
          <div className="w-full flex justify-end controls bg-white text-gray-700 items-center">{children}</div>
        </div>
        <div className="flex w-full extend border-solid border-t">{expandedContent}</div>
      </div>
    )
  },
)
InputSearchPanel.displayName = 'InputSearchPanel'
export default InputSearchPanel
