import React from 'react'

const CrmLogo: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="697" height="125.4208" viewBox="0 0 697 125.4208">
    <g id="Ebene_1-2" data-name="Ebene 1">
      <path
        d="M227.55,103.12a5.0935,5.0935,0,0,1,3.51,1.04,4.2908,4.2908,0,0,1,1.14,3.32v8.84h-1.55l-.41-1.84h-.1a5.9841,5.9841,0,0,1-1.79,1.57,5.4524,5.4524,0,0,1-2.57.51,4.5485,4.5485,0,0,1-2.93-.93,3.4955,3.4955,0,0,1-1.16-2.92,3.43,3.43,0,0,1,1.52-2.99,8.8312,8.8312,0,0,1,4.7-1.15l2.2-.07v-.77a2.9269,2.9269,0,0,0-.7-2.25,2.88,2.88,0,0,0-1.99-.63,6.2489,6.2489,0,0,0-1.94.3,13.4044,13.4044,0,0,0-1.72.69l-.65-1.6a9.4637,9.4637,0,0,1,2.01-.79A8.9087,8.9087,0,0,1,227.55,103.12Zm2.54,6.85-1.91.07a6.5259,6.5259,0,0,0-3.35.77,2.2251,2.2251,0,0,0-.93,1.91,1.8688,1.8688,0,0,0,.67,1.6,2.7175,2.7175,0,0,0,1.71.51,4.1013,4.1013,0,0,0,2.72-.91,3.44,3.44,0,0,0,1.1-2.8v-1.15Z"
        fill="#fff"
      />
      <path
        d="M242.46,103.1a4.1462,4.1462,0,0,1,4.7,4.77v8.45h-2.11v-8.3q0-3.12-2.91-3.12a3.34,3.34,0,0,0-2.98,1.21,6.188,6.188,0,0,0-.82,3.49v6.73h-2.13V103.35h1.72l.31,1.77h.12a3.8208,3.8208,0,0,1,1.74-1.51A5.6971,5.6971,0,0,1,242.46,103.1Z"
        fill="#fff"
      />
      <path d="M262.63,116.32h-6.25v-1.26l2.03-.46V100.78l-2.03-.48V99.04h6.25v1.26l-2.03.48V114.6l2.03.46Z" fill="#fff" />
      <path
        d="M281.05,107.65a10.7712,10.7712,0,0,1-1.27,5.41,6.5481,6.5481,0,0,1-3.76,3.06l4.14,4.31h-3.12l-3.34-3.9c-.1,0-.2,0-.3.01s-.21.01-.3.01a8.3232,8.3232,0,0,1-4.47-1.11,6.7047,6.7047,0,0,1-2.65-3.12,11.7669,11.7669,0,0,1-.87-4.7,11.5347,11.5347,0,0,1,.87-4.64,6.7866,6.7866,0,0,1,2.65-3.11,8.3481,8.3481,0,0,1,4.49-1.11,8.1319,8.1319,0,0,1,4.36,1.1,6.9209,6.9209,0,0,1,2.67,3.1A11.375,11.375,0,0,1,281.05,107.65Zm-13.65,0a8.5007,8.5007,0,0,0,1.38,5.14,5.869,5.869,0,0,0,8.61,0,8.5907,8.5907,0,0,0,1.37-5.14,8.484,8.484,0,0,0-1.36-5.12,4.9615,4.9615,0,0,0-4.29-1.85,5.0521,5.0521,0,0,0-4.33,1.85A8.4155,8.4155,0,0,0,267.4,107.65Z"
        fill="#fff"
      />
      <path
        d="M296.81,99.03l-6.17,17.29h-2.18l-6.17-17.29h2.28l3.9,11.09c.26.71.47,1.38.64,2s.32,1.21.45,1.78c.11-.56.26-1.16.43-1.79.18-.63.4-1.31.65-2.03l3.87-11.04h2.3Z"
        fill="#fff"
      />
      <path d="M304.03,116.32h-6.25v-1.26l2.03-.46V100.78l-2.03-.48V99.04h6.25v1.26l-2.03.48V114.6l2.03.46Z" fill="#fff" />
      <path
        d="M318.21,116.32l-2.08-5.35h-6.85l-2.06,5.35h-2.2l6.75-17.36h1.96l6.73,17.36Zm-2.73-7.29-1.94-5.23c-.05-.13-.13-.36-.24-.7s-.22-.69-.33-1.05-.19-.64-.26-.83c-.13.5-.26.99-.4,1.46-.14.48-.25.85-.35,1.13L310,109.04h5.48Z"
        fill="#fff"
      />
      <path
        d="M330.97,97.92v4.48c0,.55-.01,1.06-.04,1.54-.02.48-.05.85-.08,1.13h.12a4.5189,4.5189,0,0,1,1.52-1.38,5.5057,5.5057,0,0,1,6.38,1.12,7.4469,7.4469,0,0,1,1.46,5.02,7.4527,7.4527,0,0,1-1.48,5.04,5.46,5.46,0,0,1-6.36,1.13,4.6222,4.6222,0,0,1-1.52-1.33h-.17l-.43,1.65h-1.53V97.92Zm3.66,6.97a3.1755,3.1755,0,0,0-2.87,1.19,6.82,6.82,0,0,0-.79,3.68v.1a6.9731,6.9731,0,0,0,.79,3.67,3.1594,3.1594,0,0,0,2.92,1.27,2.9244,2.9244,0,0,0,2.6-1.28,6.6806,6.6806,0,0,0,.86-3.7Q338.14,104.885,334.63,104.89Z"
        fill="#fff"
      />
      <path
        d="M354.58,103.34v12.98h-1.74l-.32-1.72h-.1a3.7225,3.7225,0,0,1-1.74,1.49,5.9,5.9,0,0,1-2.37.47,4.8951,4.8951,0,0,1-3.53-1.13,4.7732,4.7732,0,0,1-1.19-3.6v-8.5h2.16v8.35c0,2.07.96,3.1,2.88,3.1a3.371,3.371,0,0,0,2.99-1.21,6.103,6.103,0,0,0,.83-3.49v-6.75h2.13Z"
        fill="#fff"
      />
      <path
        d="M367.14,112.73a3.2669,3.2669,0,0,1-1.4,2.86,6.4937,6.4937,0,0,1-3.78.97,10.6948,10.6948,0,0,1-2.34-.22,7.317,7.317,0,0,1-1.73-.61v-1.94a10.7827,10.7827,0,0,0,1.88.71,7.7383,7.7383,0,0,0,2.24.33,4.1076,4.1076,0,0,0,2.35-.52,1.6444,1.6444,0,0,0,.73-1.39,1.4937,1.4937,0,0,0-.27-.87,2.7481,2.7481,0,0,0-.96-.77,14.8125,14.8125,0,0,0-1.97-.87,17.9623,17.9623,0,0,1-2.15-.97,3.9148,3.9148,0,0,1-1.38-1.16,2.9338,2.9338,0,0,1-.48-1.74,2.8851,2.8851,0,0,1,1.34-2.54,6.23,6.23,0,0,1,3.52-.9,9.8112,9.8112,0,0,1,2.22.24,11.0777,11.0777,0,0,1,1.92.63l-.73,1.69a13.3576,13.3576,0,0,0-1.72-.58,7.0918,7.0918,0,0,0-1.84-.24,3.893,3.893,0,0,0-2,.42,1.37,1.37,0,0,0-.37,2.07,3.5562,3.5562,0,0,0,1.05.73,20.983,20.983,0,0,0,1.97.82,15.2365,15.2365,0,0,1,2.11.95,3.6517,3.6517,0,0,1,1.33,1.17A3.035,3.035,0,0,1,367.14,112.73Z"
        fill="#fff"
      />
      <path
        d="M371.38,98.47a1.27,1.27,0,0,1,.86.33,1.6186,1.6186,0,0,1,0,2.06,1.27,1.27,0,0,1-.86.33,1.2985,1.2985,0,0,1-.9-.33,1.6535,1.6535,0,0,1,0-2.06A1.2985,1.2985,0,0,1,371.38,98.47Zm1.04,4.87v12.98h-2.13V103.34Z"
        fill="#fff"
      />
      <path
        d="M382.78,103.1a4.1462,4.1462,0,0,1,4.7,4.77v8.45h-2.11v-8.3q0-3.12-2.91-3.12a3.34,3.34,0,0,0-2.98,1.21,6.188,6.188,0,0,0-.82,3.49v6.73h-2.13V103.35h1.72l.32,1.77h.12a3.8208,3.8208,0,0,1,1.74-1.51A5.6181,5.6181,0,0,1,382.78,103.1Z"
        fill="#fff"
      />
      <path
        d="M396.51,103.1a5.4082,5.4082,0,0,1,2.86.73,4.723,4.723,0,0,1,1.85,2.05,6.9619,6.9619,0,0,1,.64,3.09v1.28h-8.89a4.8552,4.8552,0,0,0,1.13,3.35,3.9114,3.9114,0,0,0,3.01,1.15,9.6241,9.6241,0,0,0,2.19-.23,10.9187,10.9187,0,0,0,1.97-.67v1.86a9.7359,9.7359,0,0,1-1.96.64,11.6431,11.6431,0,0,1-2.3.21,6.86,6.86,0,0,1-3.27-.75,5.184,5.184,0,0,1-2.19-2.23,7.7422,7.7422,0,0,1-.79-3.66,8.6883,8.6883,0,0,1,.71-3.66,5.4708,5.4708,0,0,1,2.01-2.35A5.65,5.65,0,0,1,396.51,103.1Zm-.03,1.74a3.0907,3.0907,0,0,0-2.41.98,4.58,4.58,0,0,0-1.05,2.72h6.61a4.5974,4.5974,0,0,0-.77-2.68A2.7787,2.7787,0,0,0,396.48,104.84Z"
        fill="#fff"
      />
      <path
        d="M413.6,112.73a3.2669,3.2669,0,0,1-1.4,2.86,6.4937,6.4937,0,0,1-3.78.97,10.6948,10.6948,0,0,1-2.34-.22,7.317,7.317,0,0,1-1.73-.61v-1.94a10.7827,10.7827,0,0,0,1.88.71,7.7383,7.7383,0,0,0,2.24.33,4.1076,4.1076,0,0,0,2.35-.52,1.6444,1.6444,0,0,0,.73-1.39,1.4943,1.4943,0,0,0-.27-.87,2.7481,2.7481,0,0,0-.96-.77,14.8125,14.8125,0,0,0-1.97-.87,17.9623,17.9623,0,0,1-2.15-.97,3.9148,3.9148,0,0,1-1.38-1.16,2.9338,2.9338,0,0,1-.48-1.74,2.8853,2.8853,0,0,1,1.34-2.54,6.23,6.23,0,0,1,3.52-.9,9.8112,9.8112,0,0,1,2.22.24,11.0777,11.0777,0,0,1,1.92.63l-.73,1.69a13.3524,13.3524,0,0,0-1.72-.58,7.0918,7.0918,0,0,0-1.84-.24,3.893,3.893,0,0,0-2,.42,1.37,1.37,0,0,0-.37,2.07,3.5562,3.5562,0,0,0,1.05.73,20.983,20.983,0,0,0,1.97.82,15.2365,15.2365,0,0,1,2.11.95,3.6517,3.6517,0,0,1,1.33,1.17A3.035,3.035,0,0,1,413.6,112.73Z"
        fill="#fff"
      />
      <path
        d="M425.2,112.73a3.2669,3.2669,0,0,1-1.4,2.86,6.4937,6.4937,0,0,1-3.78.97,10.6948,10.6948,0,0,1-2.34-.22,7.3186,7.3186,0,0,1-1.73-.61v-1.94a10.7827,10.7827,0,0,0,1.88.71,7.7393,7.7393,0,0,0,2.24.33,4.1076,4.1076,0,0,0,2.35-.52,1.6444,1.6444,0,0,0,.73-1.39,1.4936,1.4936,0,0,0-.27-.87,2.7481,2.7481,0,0,0-.96-.77,14.8125,14.8125,0,0,0-1.97-.87,17.9623,17.9623,0,0,1-2.15-.97,3.9148,3.9148,0,0,1-1.38-1.16,2.9338,2.9338,0,0,1-.48-1.74,2.8851,2.8851,0,0,1,1.34-2.54,6.23,6.23,0,0,1,3.52-.9,9.8112,9.8112,0,0,1,2.22.24,11.0816,11.0816,0,0,1,1.92.63l-.73,1.69a13.3524,13.3524,0,0,0-1.72-.58,7.0912,7.0912,0,0,0-1.84-.24,3.8933,3.8933,0,0,0-2,.42,1.37,1.37,0,0,0-.37,2.07,3.5541,3.5541,0,0,0,1.05.73,20.983,20.983,0,0,0,1.97.82,15.2365,15.2365,0,0,1,2.11.95,3.6517,3.6517,0,0,1,1.33,1.17A3.115,3.115,0,0,1,425.2,112.73Z"
        fill="#fff"
      />
      <path
        d="M340.6,79.47a4.1525,4.1525,0,0,1-4.15-4.15V50.26a12.51,12.51,0,1,0-25.02,0c0,.42,0,7.95.01,8.37a12.4374,12.4374,0,0,0,12.51,12.09,4.15,4.15,0,1,1,0,8.3,20.7019,20.7019,0,0,1-20.8-20.21c-.01-.25-.01-8.07-.01-8.54h0v-.03a20.8,20.8,0,0,1,41.6.03V75.33A4.1291,4.1291,0,0,1,340.6,79.47Z"
        fill="#fff"
      />
      <path
        d="M359.47,79.47a4.1525,4.1525,0,0,1-4.15-4.15V50.26a20.831,20.831,0,0,1,20.8-20.81,4.15,4.15,0,0,1,0,8.3,12.5216,12.5216,0,0,0-12.51,12.51V75.33A4.1291,4.1291,0,0,1,359.47,79.47Z"
        fill="#fff"
      />
      <path
        d="M204.23,95.3a4.1525,4.1525,0,0,1-4.15-4.15V50.25a20.805,20.805,0,0,1,41.61-.03h0v.02h0c0,.47,0,8.28-.01,8.54a20.6954,20.6954,0,0,1-20.8,20.21,4.15,4.15,0,0,1,0-8.3,12.4481,12.4481,0,0,0,12.51-12.15c0-.38.01-7.89.01-8.31a12.51,12.51,0,0,0-25.02,0v40.9A4.1568,4.1568,0,0,1,204.23,95.3Z"
        fill="#fff"
      />
      <path
        d="M272.41,29.45a20.6949,20.6949,0,0,0-12.51,4.22V17.31a4.15,4.15,0,1,0-8.3,0V74.87a4.15,4.15,0,1,0,8.3,0V50.26a12.51,12.51,0,1,1,25.02,0V74.88a4.15,4.15,0,0,0,8.3,0V50.26A20.84,20.84,0,0,0,272.41,29.45Z"
        fill="#fff"
      />
      <path
        d="M404.39,79a20.7019,20.7019,0,0,1-20.8-20.21c-.01-.25-.01-8.02-.01-8.53h0v-.05a20.8,20.8,0,1,1,41.6.12v4.35a4.1525,4.1525,0,0,1-4.15,4.15H404.37a4.15,4.15,0,1,1,0-8.3h12.51v-.21a12.7241,12.7241,0,0,0-12.51-12.6,12.5216,12.5216,0,0,0-12.51,12.51c0,.42,0,7.95.01,8.37a12.4374,12.4374,0,0,0,12.51,12.09,4.155,4.155,0,0,1,.01,8.31Z"
        fill="#fff"
      />
      <path d="M337.83,22.55H312.97a4.15,4.15,0,0,1,0-8.3h24.86a4.15,4.15,0,1,1,0,8.3Z" fill="#fff" />
      <path
        d="M164.34,61.25a4.0942,4.0942,0,0,1-2.61-.93,17.3787,17.3787,0,0,1,21.9-26.99,4.1449,4.1449,0,0,1-5.22,6.44,9.09,9.09,0,0,0-11.45,14.12,4.1445,4.1445,0,0,1,.61,5.83A4.2066,4.2066,0,0,1,164.34,61.25Z"
        fill="#fff"
      />
      <path
        d="M173.25,79.46a17.267,17.267,0,0,1-10.93-3.88,4.1449,4.1449,0,0,1,5.22-6.44,9.0856,9.0856,0,1,0,11.45-14.11,4.1449,4.1449,0,1,1,5.22-6.44,17.3793,17.3793,0,0,1-10.96,30.87Z"
        fill="#fff"
      />
      <path
        d="M39.33,79.47a4.1525,4.1525,0,0,1-4.15-4.15V50.26a12.51,12.51,0,1,0-25.02,0c0,.42,0,7.93.01,8.37a12.4326,12.4326,0,0,0,12.51,12.1,4.15,4.15,0,0,1,0,8.3A20.7019,20.7019,0,0,1,1.88,58.82c-.01-.25-.01-8.07-.01-8.54h0v-.02a20.8,20.8,0,0,1,41.6.03V75.33A4.1291,4.1291,0,0,1,39.33,79.47Z"
        fill="#fff"
      />
      <path
        d="M125.73,79a20.7019,20.7019,0,0,1-20.8-20.21c-.01-.25-.01-8.07-.01-8.54h0v-.02a20.8,20.8,0,0,1,41.6-.01v.05c0,.5,0,8.28-.01,8.53A20.6693,20.6693,0,0,1,125.73,79ZM113.21,50.25c0,.42,0,7.93.01,8.37a12.5161,12.5161,0,0,0,25.02-.05c0-.38.01-7.89.01-8.31a12.52,12.52,0,0,0-25.04-.01Z"
        fill="#fff"
      />
      <path
        d="M58.21,95.3a4.1525,4.1525,0,0,1-4.15-4.15V50.25a20.805,20.805,0,0,1,41.61-.03h0v.02h0c0,.47,0,8.28-.01,8.54a20.6954,20.6954,0,0,1-20.8,20.21,4.15,4.15,0,0,1,0-8.3A12.4481,12.4481,0,0,0,87.37,58.54c0-.38.01-7.89.01-8.31a12.51,12.51,0,1,0-25.02,0v40.9A4.1568,4.1568,0,0,1,58.21,95.3Z"
        fill="#fff"
      />
    </g>
    <g id="C" style={{ isolation: 'isolate' }}>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M549.4648,80.3877q-4.6273-4.8252-4.9589-13.2139-.0675-1.7974-.0674-5.8584,0-4.1265.0674-5.9912.331-8.3877,4.9589-13.2149,4.626-4.8252,13.4805-4.8261A22.0106,22.0106,0,0,1,572.998,39.38a14.8774,14.8774,0,0,1,8.5206,12.7491,1.2014,1.2014,0,0,1-.4327.9648,1.5451,1.5451,0,0,1-1.0322.3662h-3.3281a1.6041,1.6041,0,0,1-1.0645-.333,2.0963,2.0963,0,0,1-.6-1.1318q-1.1324-5.1255-4.0937-7.19a13.8227,13.8227,0,0,0-8.0225-2.0635q-11.5825,0-12.0488,12.9151-.0673,1.7973-.0664,5.5254t.0664,5.6582q.4644,12.8481,12.0488,12.8486a13.8372,13.8372,0,0,0,7.9893-2.0644q2.9955-2.0625,4.1269-7.1231a2.1036,2.1036,0,0,1,.6-1.1318,1.6146,1.6146,0,0,1,1.0645-.3321h3.3281a1.5452,1.5452,0,0,1,1.0322.3663,1.2026,1.2026,0,0,1,.4327.9648,14.8773,14.8773,0,0,1-8.5206,12.748,21.9961,21.9961,0,0,1-10.0527,2.0977Q554.0919,85.2148,549.4648,80.3877Z"
          fill="#fff"
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M549.4648,80.3877q-4.6273-4.8252-4.9589-13.2139-.0675-1.7974-.0674-5.8584,0-4.1265.0674-5.9912.331-8.3877,4.9589-13.2149,4.626-4.8252,13.4805-4.8261A22.0106,22.0106,0,0,1,572.998,39.38a14.8774,14.8774,0,0,1,8.5206,12.7491,1.2014,1.2014,0,0,1-.4327.9648,1.5451,1.5451,0,0,1-1.0322.3662h-3.3281a1.6041,1.6041,0,0,1-1.0645-.333,2.0963,2.0963,0,0,1-.6-1.1318q-1.1324-5.1255-4.0937-7.19a13.8227,13.8227,0,0,0-8.0225-2.0635q-11.5825,0-12.0488,12.9151-.0673,1.7973-.0664,5.5254t.0664,5.6582q.4644,12.8481,12.0488,12.8486a13.8372,13.8372,0,0,0,7.9893-2.0644q2.9955-2.0625,4.1269-7.1231a2.1036,2.1036,0,0,1,.6-1.1318,1.6146,1.6146,0,0,1,1.0645-.3321h3.3281a1.5452,1.5452,0,0,1,1.0322.3663,1.2026,1.2026,0,0,1,.4327.9648,14.8773,14.8773,0,0,1-8.5206,12.748,21.9961,21.9961,0,0,1-10.0527,2.0977Q554.0919,85.2148,549.4648,80.3877Z"
          fill="none"
          stroke="#fff"
          strokeWidth="3.3286"
        />
      </g>
    </g>
    <line id="Linie_1" data-name="Linie 1" x1="515.481" y1="3" x2="465.5524" y2="119.5" fill="none" stroke="#fff" strokeWidth="3.3286" />
    <g id="RM" style={{ isolation: 'isolate' }}>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M599.958,84.1162a1.554,1.554,0,0,1-.3994-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.3814,1.3814,0,0,1,1.0654-.4326h16.2432q7.5894,0,11.8828,3.5615t4.294,10.3516a12.3643,12.3643,0,0,1-9.586,12.8486l10.252,17.7744a1.66,1.66,0,0,1,.1992.7324,1.365,1.365,0,0,1-1.3311,1.3311h-2.93a2.3935,2.3935,0,0,1-1.5645-.4658,4.3734,4.3734,0,0,1-1.0313-1.3321l-9.7861-17.042h-11.85V83.0175a1.4364,1.4364,0,0,1-.4658,1.0987,1.6046,1.6046,0,0,1-1.1318.4326h-3.1954A1.3783,1.3783,0,0,1,599.958,84.1162ZM617,60.25q10.0518,0,10.0527-8.4541T617,43.3408H605.8164V60.25Z"
          fill="#fff"
        />
        <path
          d="M651.45,84.1162a1.4849,1.4849,0,0,1-.4326-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.4638,1.4638,0,0,1,1.1318-.4326h3.1289a1.68,1.68,0,0,1,1.6641.998l14.18,27.2276L685.834,38.9472a1.7121,1.7121,0,0,1,1.5976-.998h3.1289a1.4639,1.4639,0,0,1,1.1319.4326,1.65,1.65,0,0,1,.3994,1.165V83.0175a1.48,1.48,0,0,1-1.5313,1.5313H687.499a1.3783,1.3783,0,0,1-1.0654-.4326,1.554,1.554,0,0,1-.3994-1.0987V49.9316L674.65,71.9668a2.2218,2.2218,0,0,1-2.13,1.3984h-1.998a2.3058,2.3058,0,0,1-2.13-1.3984L657.0752,49.9316V83.0175a1.48,1.48,0,0,1-1.5313,1.5313h-2.9951A1.4852,1.4852,0,0,1,651.45,84.1162Z"
          fill="#fff"
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M599.958,84.1162a1.554,1.554,0,0,1-.3994-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.3814,1.3814,0,0,1,1.0654-.4326h16.2432q7.5894,0,11.8828,3.5615t4.294,10.3516a12.3643,12.3643,0,0,1-9.586,12.8486l10.252,17.7744a1.66,1.66,0,0,1,.1992.7324,1.365,1.365,0,0,1-1.3311,1.3311h-2.93a2.3935,2.3935,0,0,1-1.5645-.4658,4.3734,4.3734,0,0,1-1.0313-1.3321l-9.7861-17.042h-11.85V83.0175a1.4364,1.4364,0,0,1-.4658,1.0987,1.6046,1.6046,0,0,1-1.1318.4326h-3.1954A1.3783,1.3783,0,0,1,599.958,84.1162ZM617,60.25q10.0518,0,10.0527-8.4541T617,43.3408H605.8164V60.25Z"
          fill="none"
          stroke="#fff"
          strokeWidth="3.3286"
        />
        <path
          d="M651.45,84.1162a1.4849,1.4849,0,0,1-.4326-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.4638,1.4638,0,0,1,1.1318-.4326h3.1289a1.68,1.68,0,0,1,1.6641.998l14.18,27.2276L685.834,38.9472a1.7121,1.7121,0,0,1,1.5976-.998h3.1289a1.4639,1.4639,0,0,1,1.1319.4326,1.65,1.65,0,0,1,.3994,1.165V83.0175a1.48,1.48,0,0,1-1.5313,1.5313H687.499a1.3783,1.3783,0,0,1-1.0654-.4326,1.554,1.554,0,0,1-.3994-1.0987V49.9316L674.65,71.9668a2.2218,2.2218,0,0,1-2.13,1.3984h-1.998a2.3058,2.3058,0,0,1-2.13-1.3984L657.0752,49.9316V83.0175a1.48,1.48,0,0,1-1.5313,1.5313h-2.9951A1.4852,1.4852,0,0,1,651.45,84.1162Z"
          fill="none"
          stroke="#fff"
          strokeWidth="3.3286"
        />
      </g>
    </g>
  </svg>
)

export default CrmLogo
