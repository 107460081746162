import {
  Button,
  ButtonKind,
  LeftSidebar,
  PlusSignIcon,
  NavOpened,
  NavClosed,
  ExpandIcon,
  OfftimeLabel,
  OffTimeMutationVariables,
  TagToggleButton,
  toast,
  OfftimeKind,
  TimeSlotKind,
  useNavProvider,
} from '@aposphaere/ui-components'
import React, { useCallback, useMemo, useState } from 'react'
import CrmCalendar from '../CrmCalendar'
import { useAuth, UserRole, convertToUTCForBackend, parseUTC } from '@aposphaere/core-kit'
import { useCreateOfftimeMutation, useOfftimesQuery } from '../../hooks/graphql'
import { CalendarDay, CalendarTimeSlot } from '../CrmCalendar/useCalendarData'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { isSameDay } from 'date-fns'
import { ModalKind, useModal } from '../../contexts/modalContext'

const getOffTimeLabels = (canMarkUnoccupied: boolean) => {
  const labels: { value: OfftimeKind; name: string }[] = [
    { value: OfftimeKind.HomeOffice, name: 'Home Office' },
    { value: OfftimeKind.Urlaub, name: 'Urlaub' },
    { value: OfftimeKind.Privat, name: 'Privat' },
    { value: OfftimeKind.Tagung, name: 'Tagung' },
    { value: OfftimeKind.Krank, name: 'Krank' },
  ]

  if (canMarkUnoccupied) {
    labels.push({ value: OfftimeKind.Unbesetzt, name: 'Unbesetzt' })
  }
  return labels
}

type OfftimeSelectorState = { isActive: false } | { isActive: true; selectedKind: OfftimeKind | undefined }

/*
 * PROPS
 */
interface CalendarPanelProps {
  onHide: () => void
  onNewAppointmentClick: () => void
  canCreateAppointment: boolean
  setSelectedPharmacy: React.Dispatch<React.SetStateAction<number | undefined>>
}
/*
 * COMPONENT
 */
const CalenderPanel = ({ onHide, canCreateAppointment, setSelectedPharmacy, onNewAppointmentClick }: CalendarPanelProps) => {
  const { user } = useAuth()

  const canMarkUnoccupied = !!user?.roles.find((each) => [UserRole.Manager, UserRole.Admin, UserRole.Superadmin].includes(each.name as UserRole))

  const { openModal } = useModal()

  const { mutate: createOfftimeMutation } = useCreateOfftimeMutation()
  const { setSelectedDay, selectedDay, setShowRouteWithAllPharmacy, isShowRouteWithAllPharmacy } = useRoutePlanner()
  const offtimesQuery = useOfftimesQuery()
  const [offtimeSelector, setOfftimeSelector] = useState<OfftimeSelectorState>({ isActive: false })

  const offtimeLabels: { value: OfftimeKind; name: string }[] = useMemo(() => getOffTimeLabels(canMarkUnoccupied), [canMarkUnoccupied])

  const createUserTimeOff = useCallback(
    (variables: OffTimeMutationVariables) => {
      try {
        createOfftimeMutation(variables)
        toast.show({
          headline: 'Auszeit wurde erfolgreich erstellt',
          type: 'success',
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [createOfftimeMutation],
  )

  const onDayClick = useCallback(
    (day: CalendarDay) => {
      if (!offtimeSelector.isActive) {
        if (selectedDay && isShowRouteWithAllPharmacy && isSameDay(selectedDay, day.date)) {
          setSelectedDay(undefined)
          setShowRouteWithAllPharmacy(false)
          return
        }
        if (!selectedDay) {
          setSelectedDay(day.date)
        }
        if (selectedDay) {
          if (!isSameDay(selectedDay, day.date)) {
            setSelectedDay(day.date)
          } else {
            setShowRouteWithAllPharmacy((prev: boolean) => !prev)
          }
        }
      }

      if (offtimeSelector.isActive && offtimeSelector.selectedKind) {
        void createUserTimeOff({ whole_day: true, date: convertToUTCForBackend(day.date) || '', offtime_type: offtimeSelector.selectedKind })
      }
    },
    [createUserTimeOff, offtimeSelector, selectedDay, setSelectedDay, setShowRouteWithAllPharmacy, isShowRouteWithAllPharmacy],
  )

  const onTimeSlotClick = useCallback(
    (slot: CalendarTimeSlot) => {
      if (slot.type === 'free') {
        if (offtimeSelector.isActive && offtimeSelector.selectedKind) {
          void createUserTimeOff({ whole_day: false, date: convertToUTCForBackend(slot.date) || '', offtime_type: offtimeSelector.selectedKind })
        }
      }

      if (slot.type === 'offtime') {
        openModal({ kind: ModalKind.OfftimesDelete, offtimes: [slot.data] })
      }

      if (slot.type === 'appointment') {
        if (selectedDay && !isSameDay(parseUTC(slot.date), selectedDay)) {
          setSelectedDay(slot.date)
        }
        const canOpenUnselectedDayAppointment =
          selectedDay &&
          !isSameDay(selectedDay, slot.date) &&
          [TimeSlotKind.booked, TimeSlotKind.missed, TimeSlotKind.finished, TimeSlotKind.deleted].includes(slot.kind)
        if (canOpenUnselectedDayAppointment) {
          setSelectedDay(undefined)
        }
        if (slot.data?.pharmacy?.id) {
          setSelectedPharmacy((prevSelected) => (prevSelected === slot?.data?.pharmacy?.id ? undefined : slot?.data?.pharmacy?.id))
        }
      }
    },
    [createUserTimeOff, offtimeSelector, openModal, setSelectedPharmacy, selectedDay, setSelectedDay],
  )

  let offtimeButtonText = offtimeSelector.isActive ? 'Auszeiten anlegen: off' : 'Auszeiten anlegen: on'
  if (offtimesQuery.isLoading) {
    offtimeButtonText = 'Laden Auszeiten...'
  }
  const { toggleNav, navExpanded } = useNavProvider()
  return (
    <>
      <LeftSidebar
        headerContent={
          <div className="w-full">
            <div className="flex">
              <button
                onClick={toggleNav}
                className={`${
                  navExpanded ? 'bg-blue-600' : 'bg-turquoise-50'
                } w-13 h-13 flex rounded-none outline-none active:outline-none items-center justify-center`}
              >
                {navExpanded ? <NavClosed /> : <NavOpened />}
              </button>
              <div className="ml-8 flex border-b border-solid border-gray-400 justify-between w-full items-center">
                <TagToggleButton
                  disabled={offtimesQuery.isLoading}
                  customCSS="my-1 mx-1 text-sm xl:text-base"
                  selected={offtimeSelector.isActive}
                  onClick={() => setOfftimeSelector((prev) => ({ isActive: !prev.isActive } as OfftimeSelectorState))}
                >
                  {offtimeButtonText}
                </TagToggleButton>
                <div className="flex text-gray-700 items-center">
                  <Button disabled={!canCreateAppointment} kind={ButtonKind.outlinedPrimary} icon={<PlusSignIcon />} onClick={onNewAppointmentClick}>
                    {'Termin'}
                  </Button>
                </div>

                <div className="w-12 h-12 flex items-center justify-center border-l">
                  <button className="w-12 h-12 flex items-center justify-center hover:bg-turquoise-50" onClick={() => onHide()}>
                    <ExpandIcon />
                  </button>
                </div>
              </div>
            </div>

            {offtimeSelector.isActive && (
              <div className="flex py-4 w-full overflow-x justify-center items-center">
                {offtimeLabels.map(({ value, name }) => (
                  <OfftimeLabel
                    offtimeType={offtimeSelector.selectedKind}
                    onClick={(kind: OfftimeKind | undefined) => setOfftimeSelector({ isActive: true, selectedKind: kind })}
                    key={name}
                    kind={value}
                    labelInfo={name}
                  />
                ))}
              </div>
            )}
          </div>
        }
        showMax={true}
      >
        <div className="relative">
          <CrmCalendar isEditingOfftimes={offtimeSelector.isActive} onDayClick={onDayClick} onTimeSlotClick={onTimeSlotClick} />
        </div>
      </LeftSidebar>
    </>
  )
}
export default CalenderPanel
