import { useQuery } from 'react-query'
import { getTasks, Reminder, ReminderState, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { useCrmContext } from '../../contexts/crmContext'
import { QueryOptions } from './utils'

export const useTasksQuery = (options?: QueryOptions<Reminder[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  const { activeCluster } = useCrmContext()

  return useQuery(
    ['tasks', `cluster-${activeCluster.id}`],
    async () => {
      const data = await gqlClient.request<{ tasks: Reminder[] }>(getTasks)
      return data?.tasks?.filter((task) => task.pharmacy?.pharmacy_cluster?.id === activeCluster.id && task.state === ReminderState.Active) || []
    },
    { enabled: !!auth.user, ...options },
  )
}
