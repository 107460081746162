import { gql } from 'graphql-request'

export const allEmployees = gql`
  {
    ptas {
      id
      first_name
      last_name
      email
      is_campus_user
      completed_trainings
      zipcode
      pharmacy {
        id
        okid
      }
    }
  }
`

export const getEmployee = gql`
  query pta($id: ID) {
    pta(id: $id) {
      id
      first_name
      last_name
      email
      is_campus_user
      completed_trainings
      zipcode
      pharmacy {
        id
        okid
      }
    }
  }
`
