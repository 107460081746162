import React from 'react'

const BackIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Gruppe_123" data-name="Gruppe 123">
      <line
        id="Linie_47"
        data-name="Linie 47"
        x1="6"
        y1="18"
        x2="14"
        y2="26"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_48"
        data-name="Linie 48"
        x1="6"
        y1="18"
        x2="30"
        y2="18"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_47-2"
        data-name="Linie 47"
        x1="6"
        y1="18"
        x2="14"
        y2="10"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default BackIcon
