import React from 'react'

interface WeekNoteSectionProps {
  onUpdate: () => void
  onDelete: () => void
  note: string
}

const WeekNoteSection = ({ onUpdate, note }: WeekNoteSectionProps) => (
  <section onClick={onUpdate} className="ml-2 px-2 py-2  bg-yellow-100 cursor-pointer rounded border border-yellow-400  flex items-center">
    <span className="font-semibold text-xs text-black">Notiz:</span>
    <p className="text-gray-900 text-xs pl-2 truncate font-normal">{note}</p>
  </section>
)

export default WeekNoteSection
