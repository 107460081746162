import { useAuth } from '@aposphaere/core-kit'
import React from 'react'
import { useCrmContext } from '../../contexts/crmContext'
import { usePharmacyClustersQuery } from '../../hooks/graphql'

const PharmacyClusterSwitcher: React.FC = () => {
  const { activeCluster, setActiveClusterId } = useCrmContext()

  const { user } = useAuth()
  const { data: pharmacyClusters } = usePharmacyClustersQuery()

  return (
    <select
      value={activeCluster.id}
      onChange={(event) => setActiveClusterId(event.currentTarget.value)}
      disabled={!user?.pharmacy_clusters?.length}
      className="w-full form-select font-body h-10 text-blue-800 bg-blue-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-blue-700 focus:border-blue-400"
    >
      {pharmacyClusters?.map((cluster) => (
        <option key={cluster.id} value={cluster.id}>
          {`${cluster.name || ''} - ${cluster?.agent?.name || ''}`}
        </option>
      ))}
    </select>
  )
}

export default PharmacyClusterSwitcher
