const plugin = require('tailwindcss/plugin')

module.exports = {
  purge: ['./src/**/*.html', './src/**/*.jsx', './src/**/*.js'],
  theme: {
    fontFamily: {
      display: ['Rubik', 'sans-serif'],
      body: ['Rubik', 'sans-serif'],
    },
    borderBottomColor: {
      white: '#fff',
    },
    fill: (theme) => ({
      red: theme('colors.red.700'),
      green: theme('colors.green.600'),
      blue: theme('colors.blue.700'),
      purple: theme('colors.purple.700'),
      yellow: theme('colors.yellow.600'),
      gray: theme('colors.gray.700'),
      white: '#fff',
      noColor: 'rgba(0,0,0,0)',
      orange: '#F3905a',
    }),
    scale: {
      65: '.65',
    },
    extend: {
      opacity: {
        45: '0.45',
        65: '0.65',
      },
      border: {
        half: '0.5px',
      },
      keyframes: {
        wiggle: {
          '0%, 100%': { transform: 'rotate(-94deg)' },
          '50%': { transform: 'rotate(60deg)' },
        },
        bob: {
          '0%, 100%': { transform: 'rotate(-94deg)' },
          '50%': { transform: 'rotate(60deg)' },
        },
        'slide-in': {
          from: { transform: 'translateX(30px)', opacity: 0 },
          to: { transform: 'translateX(0px)', opacity: 1 },
        },
      },
      margin: {
        '-450': '-450px',
        '-350': '-350px',
        16.5: '3.85rem',
        17: '4rem',
      },
      backgroundImage: (theme) => ({
        login: "url('/bg-login.svg')",
        'striped-gray': 'repeating-linear-gradient(135deg, #e9eef4, #e9eef4 3px, #F8F9FA 3px, #F8F9FA 6px);',
        'striped-darkgray': 'repeating-linear-gradient(135deg, #D6DBE0, #e9eef4 3px, #F8F9FA 3px, #F8F9FA 6px);',
        'striped-yellow': 'repeating-linear-gradient(135deg, #FFF5AA, #FFF5AA 3px, #FFFEF5 3px, #FFFEF5 6px);',
        'striped-orange': 'repeating-linear-gradient(135deg, #FFEDCC, #FFEDCC 3px, #FFFDE8 3px, #FFFDE8 6px);',
        'striped-green': 'repeating-linear-gradient(135deg, #D1FAE5, #D1FAE5 3px, #FBFFF2 3px, #FBFFF2 6px);',
        'striped-blue': 'repeating-linear-gradient(135deg, #DBEAFE, #DBEAFE 3px, #FBFFF2 3px, #FBFFF2 6px);',
        'striped-purple': 'repeating-linear-gradient(135deg, #EBE0FF, #EBE0FF 3px, #F9F5FF 3px, #F9F5FF 6px);',
        'striped-brown': 'repeating-linear-gradient(135deg, #EDE4DE, #EDE4DE 3px, #FAF7F4 3px, #FAF7F4 6px);',
        'striped-pink': 'repeating-linear-gradient(135deg, #FDF2F8, #FDF2F8 3px, #FFF7FE 3px, #FFF7FE 6px);',
        'striped-red': 'repeating-linear-gradient(135deg, #FEE2E2, #FEE2E2 3px, #FFF7FE 3px, #FFF7FE 6px);',
        'pink-gradient': 'linear-gradient(to top, #f4ada1, #f6b6a7, #f8bfad, #f9c8b5, #fbd1bd)',
        'blue-gradient': 'linear-gradient(to bottom, #4c91ce, #52a0d5, #5dafdc, #6bbde2, #7ccbe7)',
      }),
      animation: {
        'spin-slow': 'spin 60s linear infinite',
        'spin-veryslow': 'spin 100s linear infinite',
        wiggle: 'wiggle 30s ease-in-out infinite',
        bob: 'wiggle 30s ease-in-out infinite',
        'slide-in': 'slide-in 0.5s',
      },
      screens: {
        '2lg': '1480px',
        '2xl': '1600px',
      },
      fontSize: {
        xxs: '10px',
        xxxs: '9px',
        sm: '12px',
        smx: '13px',
        base: '14px',
        base2: '15px',
        base3: '17px',
        lg: '18px',
        '2lg': '36px',
        xl: '21px',
        xl1: '24px',
        xl2: '28px',
        '2xl': '40px',
        xxl: '50px',
        '2xxl': '90px',
      },
      width: {
        '5px': '5px',
        '19px': '19px',
        '42px': '42px',
        fit: 'fit-content',
        '80pc': '80%',
        '40pc': '40%',
        '40vw': '40vw',
        '50vw': '50vw',
        '75vh': '75vh',
        '18pc': '18%',
        '15pc': '15%',
        '17pc': '17%',
        '5pc': '5%',
        '10pc': '10%',
        'placeholder-kw': 'calc(15% + 1.5rem)',
        '310px': '310px',
        '700px': '700px',
        map: 'calc(100% - 700px)',
        110: '110%',
        '450px': '450px',
        auto: 'auto',
      },
      minWidth: {
        8: '2rem',
        '600px': '600px',
        '512px': '512px',
        '130px': '130px',
        '320px': '320px',
        '1200px': '1200px',
        '1024px': '1024px',
      },
      maxWidth: {
        32: '8rem',
        48: '12rem',
        '700px': '700px',
        '570px': '570px',
        '660px': '660px',
        '990px': '990px',
        '1024px': '1024px',
        '1150px': '1150px',
        '1700px': '1700px',
      },
      maxHeight: {
        '750px': '750px',
        '650px': '650px',
        '690px': '690px',
        '530px': '530px',
        '300px': '300px',
        '55vh': '55vh',
      },
      minHeight: {
        20: '5rem',
        '36px': '36px',
        '68px': '68px',
        '750px': '750px',
        '150px': '150px',
      },
      height: {
        fit: 'fit-content',
        14: '3.75rem',
        '80vh': '80vh',
        'full-sidebar': 'calc(100vh - 60px)',
        '750px': '750px',
        'full-route-modal': 'calc(100% - 40px)',
        'full-route-modal2': 'calc(100% - 28px)',
      },
      inset: {
        17: '4.313rem',
      },
      boxShadow: {
        focus: '0px 2px 2px 0px rgba(0,0,0,0.10)',
        active: '0px 2px 6px 0px rgba(0,0,0,0.16)',
        whitebborder: '0px 1px 0px 0px rgba(255,255,255,1), 1px 0px 0px 0px rgba(255,255,255,1), -1px 0px 0px 0px rgba(255,255,255,1)',
        deleteborder2: 'inset 0 1px 2px #ffffff',
        deleteborder: 'inset 0 1px 2px #000000',
        'xl-blur': '0px 5px 42px 0px rgba(0,0,0,0.2)',
        scrollarea: '0 0px 8px 0px rgb(0 0 0 / 14%)',
        scrollareaBottom: '0 0px 8px 0px rgb(0 0 0 / 14%)',
        top: '0px 0px 12px 0px rgba(0,0,0,0.2)',
      },
      zIndex: {
        999: '999',
        9999: '9999',
        reduce: '-1',
      },
      borderWidth: {
        1: '0.5px',
      },
      colors: {
        transparent: {
          'opacity-0': 'rgba(255, 255, 255, 0)',
          'white-opacity-85': 'rgba(255, 255, 255, .85)',
          'grey-opacity-87': 'rgb(237 237 237 / 87%)',
          'black-opacity-63': 'rgb(40, 71, 88 / 63%)',
        },
        blue: {
          50: '#EFF7FC',
          100: '#E3F8FF', //bg selected main tab (level 1)
          200: '#E4F4F8', //hover button outlined primary
          '300-hi': '#50DDFF', //hight intensity for dark BG
          300: '#ADD2D6',
          500: '#FFF',
          400: '#5EABCC', //border focused input field
          600: '#228BB8', //main and primary blue
          700: '#116C93', //blue text
          800: '#0B4569', //button primary hover
          'gray-800': '#324E58', // bg Rote Modal
        },
        turquoise: {
          50: '#EFFBFF',
          100: '#E0F7FF',
          200: '#38F4D7',
        },
        green: {
          50: '#EFFFF6',
          60: '#e2f8eb',
          100: '#D3FFE4',
          200: '#8cab95',
          '200b': '#EEFAB5',
          300: '#D8EFE2',
          '300-hi': '#BCFF50',
          400: '#B7E5D0',
          450: '#96e9bb',
          '450-hi': '#00A847',
          500: '#009D85',
          550: '#009181',
          600: '#008379',
          650: '#227771',
          700: '#006052',
          750: '#2BA04F',
          850: '#07c1a5',
          900: '#004548',
        },
        yellow: {
          100: '#f7f8e7',
          200: '#FFF5AA',
          300: '#f5f4c5',
          400: '#f8f49e',
          600: '#FFBC24',
          700: '#faf704',
          900: '#E2BF00',
        },
        gold: {
          500: '#CBA43C',
        },
        purple: {
          200: '#C6A8FF',
          '200b': '#EBE0FF',
          500: '#9B5AB7',
          700: '#6034A3',
          900: '#A385DB',
        },
        orange: {
          100: '#F8EDE7',
          200: '#FFEDCC',
          300: '#f5d5c5',
          400: '#f8be9e',
          600: '#f3905a',
          700: '#ef5806',
          900: '#FAAA12',
        },
        pink: {
          100: '#FCE7F3',
          200: '#FBCFE8',
          300: '#F9A8D4',
          400: '#F472B6',
          500: '#fa3661',
          600: '#E0115f',
        },
        brown: {
          200: '#EDE4DE',
          900: '#B29876',
        },
        red: {
          200: '#FECACA',
          300: '#F4DCE5',
          400: '#E5B4C7',
          500: '#FF50A1',
          600: '#D92656', //Danger buttons, notifications
          700: '#D9184C', //red text
          800: '#AC0035', //Danger button hover
        },
        gray: {
          100: '#F8F9FA', //bg inputfields

          200: '#e9eef4',
          300: '#E9EEF4', // hover outlined secondary btn
          350: '#D9D9D9',
          400: '#D6DBE0', //border Inputs, boxes, lines
          500: '#B0B8C3', //Text labels infoblock uppercase, placeholder text input fields
          600: '#989FA8', // bg secondary btn
          700: '#798596', //Icons, gray text, unselected tabs
          900: '#1a202c', //Hover secondary button
        },
        black: {
          100: '#2F2F2F',
          200: '#4B4B4B',
          300: '#284758',
        },
        map: {
          purple: '#6A287E',
          pink: '#F535AA',
          yellow: '#FFFF00',
          paleBlue: '#50DDFF',
          green: '#008000',
          darkBlue: '#000080',
          red: '#ff0000',
          sand: '#C0C0C0',
          black: '#000000',
          coral: '#D92656',
          magenta: '#E238EC',
        },
      },
    },
  },
  variants: {
    backgroundColor: ['responsive', 'hover', 'focus', 'checked'],
    borderColor: ['responsive', 'hover', 'focus', 'checked'],
    borderWidth: ['responsive', 'hover', 'focus', 'last'],
    scale: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    padding: ['responsive', 'hover', 'focus', 'first', 'last'],
    margin: ['responsive', 'hover', 'focus', 'last'],
  },
  plugins: [
    require('@tailwindcss/ui'),
    plugin(function ({ addComponents, theme }) {
      // Add your custom styles here
      const customStyles = {
        '.day': {
          border: `1px solid ${theme('colors.gray.350')}`,
        },
        '.max-w-fit': {
          maxWidth: 'fit-content',
        },
        '.border-l-1px': {
          borderLeft: `1px solid ${theme('colors.gray.350')}`,
        },
        '.day-center': {
          borderLeft: `1px solid ${theme('colors.gray.350')}`,
          '& >  *': {
            borderRight: `1px solid ${theme('colors.gray.350')}`,
            borderBottom: `1px solid ${theme('colors.gray.350')}`,
          },
          '& >  *:nth-child(5n+5)': {
            borderRight: 'none',
          },
          '& >  *:nth-child(n+6)': {
            borderBottom: 'none',
          },
        },
        '.day-center-3': {
          borderLeft: `1px solid ${theme('colors.gray.350')}`,
          '& >  *': {
            borderRight: `1px solid ${theme('colors.gray.350')}`,
            borderBottom: `1px solid ${theme('colors.gray.350')}`,
          },

          '& >  *:nth-child(5n+5)': {
            borderRight: 'none',
          },

          '& >  *:nth-child(n+11)': {
            borderBottom: 'none',
          },
        },
      }

      addComponents(customStyles)
    }),
  ],
}
