import { getUserPharmacyClusters, UserPharmacyClustersResponse, useAuth, useGqlClient, PharmacyCluster } from '@aposphaere/core-kit'
import { useQuery } from 'react-query'
import { QueryOptions } from './utils'

export const usePharmacyClustersQuery = (options?: QueryOptions<PharmacyCluster[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery(
    'pharmacy_clusters',
    async () => (await gqlClient.request<UserPharmacyClustersResponse>(getUserPharmacyClusters))?.me?.pharmacy_clusters ?? [],
    { enabled: !!auth.user, staleTime: Infinity, ...options },
  )
}
