import React, { createContext, useContext } from 'react'
import { useOverviewProvider, SummaryWithProjectType } from '../hooks/useOverviewProvider'
import { SummaryAppointmentsInQuarter } from '@aposphaere/core-kit'
/**
 * Definition for the Overview Context
 */
export interface IOverviewContext {
  /**
   * The overview information
   */
  summaryInfo: {
    totalAppointmentsInQuarter?: number
    pastAppointmentsInQuarter?: number
    futureAppointmentsInQuarter?: number
    summaryOfAppointmentsInQuarter?: SummaryAppointmentsInQuarter
  }
  summaryOfProjectList: SummaryWithProjectType[]
}
export const OverviewContext = createContext<IOverviewContext | null>(null)

const OverviewProvider: React.FC = ({ children }) => {
  const overviewData = useOverviewProvider()
  return <OverviewContext.Provider value={overviewData}>{children}</OverviewContext.Provider>
}

/*
 * Hook
 */
export const useOverviewContext = () => {
  const context = useContext(OverviewContext)
  if (!context) {
    throw new Error("Provider not found: Attemting to use the crmContext, without it's provider")
  }
  return context
}

export default OverviewProvider
