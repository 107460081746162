import React from 'react'

const ArrowUpIcon: React.FC<{ dimension?: string; rotate?: string }> = ({ dimension = '24', rotate = '0' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimension}
    height={dimension}
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    className="feather feather-chevron-up"
    viewBox="0 0 24 24"
    transform={`rotate(${rotate})`}
  >
    <path d="M18 15L12 9 6 15"></path>
  </svg>
)
export default ArrowUpIcon
