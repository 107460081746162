import React from 'react'

const MapMarkerHomeIcon: React.FC = () => (
  <svg className="w-5 h-auto mx-auto" xmlns="http://www.w3.org/2000/svg" width="27.448" height="29.8772" viewBox="0 0 27.448 29.8772">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <polyline
          points="18.153 16.384 9.366 16.384 9.366 28.877 1 28.877 1 9.858 13.724 1 26.448 9.858 26.448 28.877 18.329 28.877"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default MapMarkerHomeIcon
