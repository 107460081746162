import { updatePTA } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, TextInput, toast } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { GENERIC_ERROR_MESSAGE } from '../../constants'

import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery } from '../../hooks/graphql'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type PersonenFormValues = {
  pharmacy_id?: number
  first_name?: string
  last_name?: string
  email?: string
  address?: string
  zipcode?: string
  gender?: string
}

type Props =
  | {
      mode: 'edit' | 'delete'
      id: number
      pharmacyId: number
    }
  | {
      mode: 'create'
      pharmacyId: number
    }

const PersonenModal: React.FC<Props> = ({ mode, pharmacyId, ...props }) => {
  const { closeModal } = useModal()

  const employeeId = 'id' in props ? props.id : undefined
  const { data: pharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId)
  const employee = useMemo(() => {
    if (!employeeId || !pharmacy?.employees?.length) {
      return null
    }

    return pharmacy.employees.find((_employee) => _employee.id === String(employeeId)) ?? null
  }, [pharmacy, employeeId])

  const updatePTAMutation = useAuthenticatedMutation(updatePTA)

  const getMutation = (values: PersonenFormValues) => {
    if (mode === 'create') {
      return () => updatePTAMutation(values)
    }

    if (mode === 'delete' && employeeId) {
      return () => updatePTAMutation({ id: employeeId })
    }
    if (mode === 'edit' && employeeId) {
      return () =>
        updatePTAMutation({
          ...values,
          id: employeeId,
        })
    }
    return null
  }

  const initialFormValues: PersonenFormValues = {
    pharmacy_id: pharmacy ? pharmacy.id : undefined,
    first_name: employee?.first_name,
    last_name: employee?.last_name,
    email: employee?.email,
    gender: employee?.gender,
    zipcode: employee?.zipcode,
    address: employee?.address,
  }

  const PersonenValidationScheme = Yup.object().shape({
    first_name: Yup.string().required('Pflichtfeld'),
    last_name: Yup.string().required('Pflichtfeld'),
    email: Yup.string().required('Pflichtfeld'),
    gender: Yup.string().required('Pflichtfeld'),
    zipcode: Yup.string().required('Pflichtfeld'),
    address: Yup.string().required('Pflichtfeld'),
  })

  const onFormSubmit = async (values: PersonenFormValues) => {
    const mutation = getMutation(values)
    if (!mutation) {
      return
    }

    const noteResponse = await mutation()
    if (noteResponse.errors !== undefined) {
      alert(noteResponse.errors)
      return
    }

    const { notificationText } = getTextObject()
    try {
      await refetchPharmacy()
      toast.show({
        type: 'success',
        headline: notificationText,
      })
    } catch (e) {
      toast.show({
        type: 'error',
        headline: GENERIC_ERROR_MESSAGE,
      })
    }

    closeModal()
  }

  const getTextObject = useCallback(() => {
    if (mode === 'delete') {
      return {
        titleModalText: 'Personal löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Personal erfolgreich gelöscht',
      }
    } else if (mode === 'edit') {
      return {
        titleModalText: 'Personal speichern',
        submitButtonText: 'Personal speichern',
        notificationText: 'Personal erfolgreich gespeichert',
      }
    }
    return {
      titleModalText: 'Personal anlegen',
      submitButtonText: 'Personal anlegen',
      notificationText: 'Personal erfolgreich erstellt',
    }
  }, [mode])

  return (
    <Modal kind={ModalKind.ms} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      <Formik key="personal-creation-form" initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={PersonenValidationScheme}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <InputLabel>{'Vorname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.first_name || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('first_name', event.currentTarget.value)}
                      />
                      {errors.first_name && touched.first_name ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.first_name}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Nachname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.last_name || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('last_name', event.currentTarget.value)}
                      />
                      {errors.last_name && touched.last_name ? <InputMessage kind={InputMessageKind.error}>{errors.last_name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <InputLabel>{'Email:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.email || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('email', event.currentTarget.value)}
                      />
                      {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <InputLabel>{'Geschlecht:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.gender || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('gender', event.currentTarget.value)}
                      />
                      {errors.gender && touched.gender ? <InputMessage kind={InputMessageKind.error}>{errors.gender}</InputMessage> : null}
                    </div>
                  </div>

                  <div className="sm:col-span-5">
                    <InputLabel>{'die Anschrift:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.address || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('address', event.currentTarget.value)}
                      />
                      {errors.address && touched.address ? <InputMessage kind={InputMessageKind.error}>{errors.address}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-1">
                    <InputLabel>{'Postleitzahl:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.zipcode || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('zipcode', event.currentTarget.value)}
                      />
                      {errors.zipcode && touched.zipcode ? <InputMessage kind={InputMessageKind.error}>{errors.zipcode}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={mode === 'delete' ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {getTextObject().submitButtonText}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default PersonenModal
