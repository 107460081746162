import { availableReports, useQuery, download, useAuth } from '@aposphaere/core-kit'
import { Button, ButtonKind, DownloadIcon, IconButton, IconButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import Spinner from '../loader.gif'

interface IExportModal {
  onClose: () => void
}
const ExportModal: React.FC<IExportModal> = ({ onClose }) => {
  const [listOfReports, setListOfReports] = useState<Record<string, string> | null>(null)
  const reports = useQuery<{ availableReports: string }>(availableReports)
  const { token } = useAuth()
  useEffect(() => {
    if (reports.data) {
      const parsedReports = JSON.parse(reports.data.availableReports) as Record<string, string>
      setListOfReports(parsedReports)
    }
  }, [reports.data])
  return (
    <Modal kind={ModalKind.sm} title="Datensätze als CSV downloaden" onClose={onClose} onBack={onClose}>
      <div className={`flex flex-wrap w-full`}>
        <div className="flex flex-wrap w-full pb-2">
          <div className="w-full p-4">
            {listOfReports ? (
              <ul className="h-auto">
                {Object.keys(listOfReports).map((el, index) => (
                  <li className="w-full border-b border-gray-400 last:border-0" key={index}>
                    <div className="flex w-full py-1.5">
                      <div className="flex w-5/6 items-center">
                        <div className="text-base leading-6 font-medium">
                          <span className="font-medium">{el}</span>
                          <br />
                        </div>
                      </div>
                      <div className="flex w-1/6 justify-end">
                        <IconButton
                          icon={<DownloadIcon />}
                          onClick={() => download(`${listOfReports[el]}?token=${token}`, el)}
                          kind={IconButtonKind.default}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="w-content relative top-10 h-64 bg-white flex fixed items-center justify-center">
                <div className="flex items-center justify-center flex-wrap max-w-48">
                  <img className="flex w-24 -mt-16" src={Spinner} alt="" />
                  <br />
                  <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
                    {' Daten werden geladen.'}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
          <Button kind={ButtonKind.secondary} onClick={onClose}>
            {'Abbrechen'}
          </Button>
          <div className="space-x-4" />
        </div>
      </div>
    </Modal>
  )
}

export default ExportModal
