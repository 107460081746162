import React from 'react'

const AddIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <line
          id="Linie_187"
          data-name="Linie 187"
          x1="9.5"
          y1="18"
          x2="26.5"
          y2="18"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Linie_188"
          data-name="Linie 188"
          x1="18"
          y1="26.5"
          x2="18"
          y2="9.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default AddIcon
