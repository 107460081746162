import { svgToDataUri } from '@aposphaere/core-kit'

export interface MapIcon {
  path: string
}

export const grayMarker = svgToDataUri(`
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="52.588" viewBox="0 0 38 52.588">
      <defs>
          <filter id="xjbgiv64za" width="38" height="52.588" x="0" y="0" filterUnits="userSpaceOnUse">
              <feOffset/>
              <feGaussianBlur result="blur" stdDeviation="1.5"/>
              <feFlood flood-opacity=".161"/>
              <feComposite in2="blur" operator="in"/>
              <feComposite in="SourceGraphic"/>
          </filter>
      </defs>
      <g filter="url(#xjbgiv64za)">
          <g fill="#808080">
              <path d="M14.519 41.778c-1.459-2.219-4.756-7.272-7.711-12.59C4.158 24.421 1 18.004 1 14.51c0-3.61 1.404-7.002 3.954-9.553C7.504 2.405 10.894 1 14.5 1c3.606 0 6.996 1.405 9.546 3.957C26.596 7.508 28 10.9 28 14.51c0 2.137-1.21 6.788-6.977 16.807-2.631 4.572-5.224 8.546-6.504 10.461z" transform="translate(4.5 4.5)"/>
              <path fill="#fff" d="M14.5 2c-3.339 0-6.477 1.301-8.838 3.663C3.3 8.026 2 11.168 2 14.51c0 .933.284 2.734 1.64 6.02.952 2.311 2.312 5.06 4.042 8.172 2.466 4.44 5.176 8.698 6.842 11.257 1.434-2.192 3.587-5.583 5.67-9.204C26.115 20.452 27 16.155 27 14.51c0-3.342-1.3-6.484-3.662-8.847C20.978 3.301 17.838 2 14.5 2m0-2C22.508 0 29 6.496 29 14.51c0 7.996-14.483 29.078-14.5 29.078C14.545 43.498 0 22.523 0 14.51 0 6.496 6.492 0 14.5 0z" transform="translate(4.5 4.5)"/>
          </g>
      </g>
    </svg>
`)
