import { Appointment, formattedDateString, ILeg, Pharmacy, parseUTC } from '@aposphaere/core-kit'
import { MapMarker, MapMarkerColor, MapMarkerHomeIcon, RouteModal, RouteModalItem, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import Fade from '../Transitions/Fade'
import React from 'react'
import { useViewport } from '../../hooks/useViewPort'
import { getAppointmentRanking } from '../Map'
import { googleMapColors } from '../Map/constants'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { format } from 'date-fns'
import { useCancelledStatusesIds } from '../../hooks/graphql'

interface IDot {
  from: Appointment[]
  to: Appointment[]
  leg: ILeg
  fromPlace?: Pharmacy
}

const colorMap = {
  green: 'bg-green-500',
  red: 'bg-red-700',
  yellow: 'bg-yellow-700',
}

const TrafficLight = ({ color }: { color: 'green' | 'yellow' | 'red' }) => {
  const colorClass = colorMap[color]
  return <div className={`w-4 h-4 rounded-full absolute right-0 top-4 ${colorClass}`} />
}

const CustomRouteModal = () => {
  const { width } = useViewport()
  const {
    setSelectedDay,
    setRouteResponse,
    routeResponse,
    selectedDay,
    sortedAppointmentsToday,
    pharmaciesHasAppointmentsToday,
    showSelectedDayOverview,
    toggleShowSelectedDayOverview,
  } = useRoutePlanner()

  const filteredLegs = routeResponse?.routes[0].legs.filter((leg: ILeg) => !!leg.duration.value) ?? []
  const cancelledStatusesIds = useCancelledStatusesIds()

  const filteredSortedAppointmentsToday =
    sortedAppointmentsToday
      ?.filter((each) => !cancelledStatusesIds.includes(String(each.status?.id)))
      ?.reduce((res: Record<number, Appointment[]>, appointment) => {
        const index = Object.values(res).length
        if (index !== 0) {
          const lastAppointmentPharmacyId = res[index - 1][0].pharmacy?.id
          const currentAppointmentPharmacyId = appointment.pharmacy?.id
          if (lastAppointmentPharmacyId === currentAppointmentPharmacyId) {
            res[index - 1].push(appointment)
            return res
          }
        }
        res[index] = [appointment]
        return res
      }, {}) ?? []

  const resultObjectData = filteredLegs?.reduce((result: Record<string, IDot>, leg, index) => {
    if (index === 0) {
      result[index] = {
        from: filteredSortedAppointmentsToday[index],
        to: filteredSortedAppointmentsToday[index],
        leg,
      }
    } else if (filteredSortedAppointmentsToday[index - 1]) {
      const pharmacyIdInAppointment = filteredSortedAppointmentsToday[index - 1][0].pharmacy?.id

      result[index] = {
        from: filteredSortedAppointmentsToday[index - 1],
        fromPlace: pharmaciesHasAppointmentsToday?.find((pharmacy) => pharmacy.id === pharmacyIdInAppointment),
        to: filteredSortedAppointmentsToday[index] || filteredSortedAppointmentsToday[index - 1],
        leg,
      }
    }
    return result
  }, {})

  const calculateTimeSpend = (obj: Appointment[]) => {
    const date = obj[0].date ? parseUTC(obj[0].date) : null
    const endDateTime = obj[0].end_date ? parseUTC(obj[0].end_date) : null
    if (!date || !endDateTime) {
      return
    }

    const startTime = format(date, 'HH:mm')

    const endTime = format(endDateTime, 'HH:mm')

    return `${startTime}-${endTime}`
  }

  const getTourStartForFirstApointment = (obj: IDot) => {
    const timeOFFirstAppointment = new Date(resultObjectData[0].from[0]?.date?.toString().replace(/\s/, 'T') || '').getHours() * 3600
    const timeToFirstAppointment = obj.leg.duration.value + 60 * 15
    return timeOFFirstAppointment - timeToFirstAppointment
  }

  const getTourStartForApointment = (obj: IDot) => {
    const endtimeOfLastppointment = new Date(obj.from[obj.from.length - 1]?.date?.toString().replace(/\s/, 'T') || '').getHours() * 3600 + 3600
    return endtimeOfLastppointment
  }

  const getTourEndForApointment = (currentObj: IDot) => getTourStartForApointment(currentObj) + currentObj.leg.duration.value + 15 * 60

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    if (hours >= 24) {
      const day = parseInt(`${hours / 24}`, 10)
      const dayHour = hours % 24
      const dayString = day > 1 ? 'Tage' : 'Tag'
      const dayHourString = dayHour > 1 ? 'Stunden' : 'Stunde'
      const minuteString = minutes > 1 ? 'Minuten' : 'Minute'
      return `${day} ${dayString} ${dayHour} ${dayHourString} und ${minutes} ${minuteString}`
    }
    return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`
  }

  const fitsJourney = (currentIndex: number): 'red' | 'green' | 'yellow' => {
    //for first item
    if (!(currentIndex - 1 < 0)) {
      //coming from home
      const prevObj = resultObjectData[currentIndex - 1]
      const currentObj = resultObjectData[currentIndex]
      if (currentIndex === 1) {
        const initialTimeInSec = getTourStartForFirstApointment(prevObj)

        const prevAppointmentDurationPlusRoadTimeToCurrentLocation = initialTimeInSec + prevObj.leg.duration.value
        const startTimeOfCurrentLocationString = calculateTimeSpend([currentObj.from[0]])
        const startTimeOfCurrentLocationHrs = Number(startTimeOfCurrentLocationString?.split('-')[0].split(':')[0]) * 3600
        const startTimeOfCurrentLocationMins = Number(startTimeOfCurrentLocationString?.split('-')[0].split(':')[1]) * 60
        const startTimeOfCurrentLocationInSec = startTimeOfCurrentLocationHrs + startTimeOfCurrentLocationMins

        if (startTimeOfCurrentLocationInSec - prevAppointmentDurationPlusRoadTimeToCurrentLocation >= 15 * 60) {
          return 'green'
        }
        if (startTimeOfCurrentLocationInSec - prevAppointmentDurationPlusRoadTimeToCurrentLocation >= 5 * 60) {
          return 'yellow'
        }
        return 'red'
      }
      //coming from previous pharmacy
      if (currentIndex >= 2) {
        const endTimeOfPreviousAppointment = getTourStartForApointment(prevObj)

        const prevAppointmentDurationPlusRoadTimeToCurrentLocation = endTimeOfPreviousAppointment + currentObj.leg.duration.value
        const startTimeOfCurrentLocationInSec = getTourStartForApointment(currentObj)

        if (startTimeOfCurrentLocationInSec - prevAppointmentDurationPlusRoadTimeToCurrentLocation >= 15 * 60) {
          return 'green'
        }

        if (startTimeOfCurrentLocationInSec - prevAppointmentDurationPlusRoadTimeToCurrentLocation >= 5 * 60) {
          return 'yellow'
        }

        return 'red'
      }
    }
    return 'red'
  }

  const getPharmacyAppointmentRanking = (pharmacy: Pharmacy | undefined, appointmentsToday: Appointment[]) => {
    if (!pharmacy || !appointmentsToday) {
      return ''
    }
    const pharmacyAppointments = appointmentsToday.filter((appointment) => (appointment.pharmacy?.id || '0') === pharmacy.id)

    if (pharmacyAppointments.length === 1) {
      const rank = getAppointmentRanking(pharmacyAppointments[0].date)
      return rank
    } else if (pharmacyAppointments.length > 1) {
      const rankText: string[] = []
      pharmacyAppointments.forEach((appointment) => {
        const rank = getAppointmentRanking(appointment.date)
        if (rank) {
          rankText.push(rank)
        }
      })
      return rankText.join('/')
    } else {
      return ''
    }
  }

  const RouteColorMarker = ({ index }: { index: number }) => {
    const colorIndex = Object.keys(resultObjectData).length === 2 ? 0 : index
    const className = `h-full-route-modal w-1 border-l-2 border-map-${googleMapColors[colorIndex]} border-solid ml-3.5 pl-4`
    return <div className={className} />
  }

  if (!routeResponse || !sortedAppointmentsToday || !pharmaciesHasAppointmentsToday) {
    return null
  }

  return (
    <Fade inProp={showSelectedDayOverview}>
      <RouteModal
        title={`Route für den ${selectedDay ? formattedDateString(selectedDay) : ''}`}
        onClose={() => {
          setSelectedDay(undefined)
          setRouteResponse(null)
        }}
        viewPortWidth={width}
        headerAdditionalButtons={
          <IconButton
            kind={IconButtonKind.custom}
            additionalCss="text-gray-600 flex flex-col justify-center mr-2 mt-2.5"
            onClick={toggleShowSelectedDayOverview}
            icon={<div className="h-0.5 bg-white w-4" />}
          />
        }
      >
        {Object.keys(resultObjectData).map((key, index) => {
          if (key === '0') {
            return (
              <RouteModalItem key={key}>
                <div className="w-8 flex flex-wrap my-3">
                  <div className="w-full h-8 mt-2 font-bold  items-center text-center text-white pl-0.5">
                    <MapMarkerHomeIcon />
                  </div>
                  <RouteColorMarker index={index} />
                </div>

                <div className="w-full pl-2 relative">
                  <span className="block mt-3 font-bold">{`Tourstart: ${formatTime(getTourStartForFirstApointment(resultObjectData[key]))}`}</span>
                  <span className="block py-3 text-white">{`${resultObjectData[key].leg.distance.text} / ${resultObjectData[key].leg.duration.text}`}</span>
                </div>
              </RouteModalItem>
            )
          }
          if (key === `${Object.keys(resultObjectData).length - 1}`) {
            const letter = getPharmacyAppointmentRanking(resultObjectData[key].fromPlace, resultObjectData[key].from)
            return (
              <React.Fragment key={key}>
                <RouteModalItem>
                  <div className="w-8 flex flex-wrap mt-2">
                    <div className="w-full h-10 font-bold justify-center items-center text-center">
                      <MapMarker customCSS="h-auto mx-auto w-6" color={MapMarkerColor.transparent} letter={letter} />
                    </div>
                    <RouteColorMarker index={index} />
                  </div>
                  <div className="w-full pl-2 relative">
                    <span className="block mt-2">
                      {`${resultObjectData[key].fromPlace?.name || ''}`}
                      <br />
                      {`${resultObjectData[key].fromPlace?.address.zipcode || ''} ${resultObjectData[key].fromPlace?.address.address_name || ''}`}
                      <br />
                      {resultObjectData[key].from.map((appointment) => (
                        <span className="block mt-2" key={appointment.id}>
                          {calculateTimeSpend([appointment])}
                        </span>
                      ))}
                    </span>
                    <span className="block py-3 text-white">{`${resultObjectData[key].leg.distance.text} / ${resultObjectData[key].leg.duration.text}`}</span>
                    <TrafficLight color={fitsJourney(Number(key))} />
                  </div>
                </RouteModalItem>
                <RouteModalItem>
                  <div className="w-8 flex flex-wrap">
                    <div className="w-8 h-8 mt-3 font-bold justify-center items-center text-center text-white pl-0.5">
                      <MapMarkerHomeIcon />
                    </div>
                  </div>
                  <div className="w-full pl-2 relative">
                    <span className="block mt-3 font-bold">{`Tourende: ${formatTime(getTourEndForApointment(resultObjectData[key]))}`}</span>
                  </div>
                </RouteModalItem>
              </React.Fragment>
            )
          }
          const letter = getPharmacyAppointmentRanking(resultObjectData[key].fromPlace, resultObjectData[key].from)
          return (
            <RouteModalItem key={key}>
              <div className="w-8 flex flex-wrap my-2">
                <div className="w-full h-10 font-bold justify-center items-center text-center">
                  <MapMarker customCSS="h-auto mx-auto w-6" color={MapMarkerColor.transparent} letter={letter} />
                </div>
                <RouteColorMarker index={index} />
              </div>
              <div className="w-full pl-2 relative">
                <span className="block mt-2">
                  {`${resultObjectData[key]?.fromPlace?.name || ''}`}
                  <br />
                  {`${resultObjectData[key]?.fromPlace?.address.zipcode || ''} ${resultObjectData[key]?.fromPlace?.address.address_name || ''}`}
                  <br />
                  {resultObjectData[key].from.map((appointment) => (
                    <span className="block mt-2" key={appointment.id}>
                      {calculateTimeSpend([appointment])}
                    </span>
                  ))}
                </span>
                <span className="block py-3">{`${resultObjectData[key].leg.distance.text} / ${resultObjectData[key].leg.duration.text}`}</span>
                <TrafficLight color={fitsJourney(Number(key))} />
              </div>
            </RouteModalItem>
          )
        })}
      </RouteModal>
    </Fade>
  )
}

export default CustomRouteModal
