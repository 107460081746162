import { gql } from 'graphql-request'

export const getFinishedPresentationsForAppointment = gql`
  query getFinishedPresentationsForAppointment($appointmentId: ID!) {
    finishedPresentationsForAppointment(appointment_id: $appointmentId) {
      id
      endtime
      starttime
      number_of_participants
      project {
        name
        id
      }
    }
  }
`
