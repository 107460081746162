import React, { FunctionComponent } from 'react'

export type TabBarProps = {
  justifyContent?: string
}

const TabBar: FunctionComponent<TabBarProps> = ({ justifyContent, children }) => (
  <div className="w-full">
    <nav className={`w-full justify-start items-center flex space-x-1 ${justifyContent}`}>{children}</nav>
  </div>
)

export default TabBar
