import React, { PropsWithChildren } from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { useMap } from '../../contexts/mapContext'

const defaultContainerStyle = {
  width: '100%',
  height: '100%',
  overflow: 'visible !important',
}

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954,
}

interface ICustomGoogleMap {
  containerStyle?: {
    width: string
    height: string
    visible?: string
  }
  center?: {
    lat: number
    lng: number
  }
}

const SimpleGoogleMap: React.FC<PropsWithChildren<ICustomGoogleMap>> = ({ children, containerStyle, center }) => {
  const { initializeMap } = useMap()

  return (
    <GoogleMap
      center={center || defaultCenter}
      mapContainerStyle={containerStyle || defaultContainerStyle}
      zoom={8}
      onLoad={initializeMap}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      {children}
    </GoogleMap>
  )
}

export default React.memo(SimpleGoogleMap)
