/* eslint-disable react/jsx-no-literals */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Appointment, parseUTC, formattedDateString, formattedTimeString, Pharmacy, useAbilities, AppointmentStatusType } from '@aposphaere/core-kit'
import { InfoWindow } from '@react-google-maps/api'
import React, { useMemo, useEffect, useState } from 'react'
import { useCrmContext } from '../../contexts/crmContext'
import QuickActions from './QuickActions'
import { CloseIcon, EditIcon, IconButton, IconButtonKind, InfoIcon, CheckIcon } from '@aposphaere/ui-components'
import { IPointType } from '../PharmacyDataSheetPanel/PharmacyDataSheetTypes'
import QuickActionsLegend from '../QuickActionsLegend'
import { AppointmentOrderItem } from '@aposphaere/core-kit/build/models/appointment'
import { useActiveProjectsQuery, useAppointmentsQuery, useCancelledStatusesIds, usePharmaciesQuery, usePharmacyQuery } from '../../hooks/graphql'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { isFuture, isSameDay } from 'date-fns'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { IMapFilterType } from '../../contexts/reducers/mapFilterReducer'
import { isRemoteAppointment } from './utils'

type gskDataType = { isApoTrainer: boolean; class: string; BM: string }

const Section = ({ children }: { children: React.ReactNode }) => <div className="border-b py-2">{children}</div>

interface Props {
  setSelectedPharmacy: (id: number | undefined) => void
  selectedPharmacyId: number | undefined
  onClose: () => void
  onCreateAppointmentClick: () => void
  onPharmacyDetailClick: () => void
}

const InfoWindowContainer = ({ onCreateAppointmentClick, onPharmacyDetailClick, setSelectedPharmacy, selectedPharmacyId, onClose }: Props) => {
  const {
    currentPointHook: { setCurrentPoint },
    infoRef,
    filialenRef,
    termineRef,
    projekteRef,
    windowRef,
    notizenRef,
  } = useCrmContext()

  const { openModal } = useModal()

  const { activeQuarter } = useCrmContext()
  const { data: pharmacies } = usePharmaciesQuery()
  const { data: activePharmacy, isLoading: isActivePharmacyLoading, refetch } = usePharmacyQuery(selectedPharmacyId)
  const { data: activeProjects } = useActiveProjectsQuery()
  const { data: appointments } = useAppointmentsQuery()
  const { dispatchFilterState } = usePharmacyFilterContext()

  const initialPharmacy: null | Pharmacy = useMemo(() => {
    if (activePharmacy || !pharmacies?.length || !selectedPharmacyId) {
      return null
    }

    return pharmacies.find(({ id }) => id === selectedPharmacyId) ?? null
  }, [activePharmacy, pharmacies, selectedPharmacyId])

  const abilities = useAbilities()

  useEffect(() => {
    setShowQuickActionsLegend(false)
  }, [selectedPharmacyId])

  useEffect(() => {
    refetch().catch((err) => console.error(err))
  }, [pharmacies, refetch])

  const pharmacyAdditionalData = activePharmacy?.additional_data
  const pharmacyProjectsId = activePharmacy?.projects?.map(({ id }) => id) || []
  const pharmacyActiveProjects = activeProjects?.filter((project) => pharmacyProjectsId.includes(project.id)) || []
  const pharmacyBranchesLength = activePharmacy?.branches?.length
  const openRelatedTab = (ref: React.RefObject<HTMLHeadingElement>, point: IPointType) => {
    onPharmacyDetailClick()
    setCurrentPoint(point)
    setTimeout(() => {
      if (ref && windowRef && ref.current && windowRef.current) {
        windowRef.current.scroll({ behavior: 'smooth', top: ref.current.offsetTop - 20 })
      }
    }, 500)
  }

  const mainBranch = useMemo<Pharmacy | undefined>(() => {
    if (!pharmacies || !activePharmacy || !activePharmacy.parent?.id) {
      return undefined
    }
    let left = 0
    let right = pharmacies.length - 1
    let mid = Math.floor((left + right) / 2)

    if (`${pharmacies[mid].id}` === `${activePharmacy.parent.id}`) {
      return pharmacies[mid]
    }
    while (pharmacies[mid].id !== activePharmacy.parent.id && left <= right) {
      if (parseInt(`${pharmacies[mid].id}`) < parseInt(`${activePharmacy.parent.id}`)) {
        left = mid + 1
      } else {
        right = mid - 1
      }
      mid = Math.floor((left + right) / 2)
    }
    return pharmacies[mid].id === activePharmacy.parent.id ? pharmacies[mid] : undefined
  }, [activePharmacy, pharmacies])

  const futureAppointments = useMemo<Appointment[] | undefined>(() => {
    if (activePharmacy === undefined) {
      return undefined
    }

    const pharmacyAppointments: Appointment[] = appointments?.filter((appointment) => appointment.pharmacy?.id === activePharmacy?.id) || []
    const future = pharmacyAppointments.filter(
      (appointment) => appointment?.date && (isFuture(parseUTC(appointment?.date)) || isSameDay(parseUTC(appointment?.date), new Date())),
    )
    return future
  }, [activePharmacy, appointments])

  const cancelledStatusesIds = useCancelledStatusesIds()

  const cancelledFutureAppointments = useMemo<Appointment[] | undefined>(
    () => futureAppointments?.filter((appointment) => cancelledStatusesIds.includes(appointment?.status?.id || '')),
    [futureAppointments, cancelledStatusesIds],
  )

  const nonCancelledFutureAppointments = useMemo<Appointment[] | undefined>(
    () => futureAppointments?.filter((appointment) => !cancelledStatusesIds.includes(appointment?.status?.id || '')),
    [futureAppointments, cancelledStatusesIds],
  )

  const pharmacyQuarterVisits = useMemo<Appointment[] | undefined>(() => {
    if (activePharmacy === undefined) {
      return undefined
    }
    const currentQuarterAppointments = appointments?.filter(
      (appointment) => appointment.quarter?.id === activeQuarter?.id && appointment.status_id !== AppointmentStatusType.Canceled,
    )
    const currentVisits = currentQuarterAppointments?.filter((appointment) => `${appointment.appointmentType?.id || 0}` === '4') || []
    const pharmacyVisits: Appointment[] = currentVisits?.filter((appointment) => appointment.pharmacy?.id === activePharmacy?.id) || []
    return pharmacyVisits
  }, [appointments, activePharmacy, activeQuarter])

  const [showQuickActionsLegend, setShowQuickActionsLegend] = useState(false)

  const gskData = useMemo(() => {
    if (!pharmacyAdditionalData) {
      return null
    }
    try {
      const parsedAdditionalData = JSON.parse(pharmacyAdditionalData)?.gsk as gskDataType

      return parsedAdditionalData
    } catch (error) {
      console.error('failed to parse activePharmacy additonal_data:', error)
    }
  }, [pharmacyAdditionalData])

  const pharmacy = activePharmacy || initialPharmacy

  const lat = pharmacy?.address.latitude ?? 0
  const lng = pharmacy?.address.longitude ?? 0
  const siblingBranches = useMemo(() => mainBranch?.branches?.filter((each) => each?.id !== pharmacy?.id), [mainBranch, pharmacy])
  return (
    <React.Fragment>
      {pharmacy ? (
        <InfoWindow
          position={{
            lng: lng,
            lat: lat,
          }}
          options={{ pixelOffset: { height: -50 } }}
          onCloseClick={onClose}
        >
          <div className="font-body font-normal h-96 flex flex-col justify-end overflow-hidden text-base leading-6 p-1 text-blue-700 min-w-320px max-w-xs">
            <div className="pb-6 flex-1 overflow-y-auto">
              <div className="px-2 pb-2 -mt-1 z-9999 right-0 top-8 absolute">
                <IconButton
                  kind={IconButtonKind.custom}
                  additionalCss="h-6 w-6 text-blue-700 hover:bg-blue-200 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
                  icon={<InfoIcon />}
                  onClick={() => setShowQuickActionsLegend((prev) => !prev)}
                />
              </div>
              {showQuickActionsLegend ? (
                <QuickActionsLegend />
              ) : (
                <>
                  <Section>
                    <span className="font-medium text-lg block mb-0.5 pr-4">{pharmacy.name}</span>
                    <p>{`(ID: ${pharmacy.id} / ${pharmacy.okid || ''})`}</p>
                    <p>{`${pharmacy.address?.address_name || ''}, ${pharmacy.address?.zipcode || ''} ${pharmacy.address.city || ''}`}</p>
                  </Section>
                  {isActivePharmacyLoading ? (
                    <Section>Loading...</Section>
                  ) : (
                    <>
                      <Section>
                        <p className={'mt-2'}>
                          <span className="font-medium pr-2">
                            <a href={`tel:${pharmacy.phone}`}>{'Tel.:'}</a>
                          </span>
                          {`${pharmacy.phone || '_'}`}
                        </p>
                      </Section>
                      <Section>
                        <p>
                          <a aria-disabled={!pharmacy?.email} href={pharmacy.email ? `mailto:${pharmacy.email}` : undefined}>
                            <span className="font-medium pr-2">{'E-Mail:'}</span>
                            {`${pharmacy.email || '_'}`}
                          </a>
                        </p>
                      </Section>

                      <Section>
                        <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(infoRef, 'info')}>
                          {'Ansprechpartner:'}
                        </span>
                        {`${pharmacy.contact_person || '_'}`}
                      </Section>
                      <Section>
                        <p>
                          <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(projekteRef, 'projekte')}>
                            {'Projekte:'}
                          </span>
                          {pharmacyActiveProjects.length
                            ? pharmacyActiveProjects.map((el, i) => (
                                <span key={`${i}${el.name}`}>{`${el.name}${i !== pharmacyActiveProjects.length - 1 ? ',' : ''}`}</span>
                              ))
                            : '_'}
                        </p>
                      </Section>

                      <Section>
                        <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(termineRef, 'termine')}>
                          Nächste Termine:
                        </span>
                        {nonCancelledFutureAppointments && nonCancelledFutureAppointments.length > 0
                          ? nonCancelledFutureAppointments.map((item, i) => (
                              <div key={item.id} className="flex items-start justify-between">
                                <div className="relative" key={i}>
                                  <span className="font-normal">
                                    {item.date ? formattedDateString(parseUTC(item.date)) : ''}
                                    {' – '}
                                    {item.date ? formattedTimeString(parseUTC(item.date)) : ''}
                                    {' Uhr'}
                                  </span>

                                  {item?.order_items?.length &&
                                    item?.order_items?.map((orderItem: AppointmentOrderItem, index) => (
                                      <span key={`${orderItem.id}-${index}`} className="font-medium">
                                        {index === 0 ? <span className="font-normal ml-1">{'('}</span> : ''}
                                        {orderItem?.project?.name} {isRemoteAppointment(item) ? '- Remote' : ''}
                                        <span className="font-medium ">{index !== item?.order_items?.length - 1 ? ', ' : ''}</span>
                                      </span>
                                    ))}
                                  {item?.order_items?.length ? ')' : ''}

                                  <span className="font-medium ">{i !== nonCancelledFutureAppointments.length - 1 ? ', ' : ''}</span>
                                </div>
                                <span>
                                  {abilities.edit_appointments && (
                                    <span>
                                      {abilities.edit_appointments && (
                                        <button className="-mt-1" onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id: item.id })}>
                                          <EditIcon />
                                        </button>
                                      )}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))
                          : '_'}
                        <span className="">
                          {cancelledFutureAppointments && cancelledFutureAppointments.length > 0
                            ? cancelledFutureAppointments.map((item, i) => (
                                <span key={item.id} className="flex items-start justify-between">
                                  <span className="relative" key={i}>
                                    <span className="font-normal">
                                      {item.date ? formattedDateString(parseUTC(item.date)) : ''}
                                      {' – '}
                                      {item.date ? formattedTimeString(parseUTC(item.date)) : ''}
                                      {' Uhr (abgesagt)'}
                                    </span>

                                    <span className="font-medium ">{i !== cancelledFutureAppointments.length - 1 ? ', ' : ''}</span>
                                  </span>
                                  <span>
                                    {abilities.edit_appointments && (
                                      <span>
                                        {abilities.edit_appointments && (
                                          <button className="-mt-1" onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id: item.id })}>
                                            <EditIcon />
                                          </button>
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </span>
                              ))
                            : null}
                        </span>
                      </Section>
                      {pharmacy.notes && !!pharmacy.notes.length && (
                        <p className="truncate cursor-pointer" onClick={() => openRelatedTab(notizenRef, 'notizen')}>
                          <span className="font-medium pr-2">{'Notizen:'}</span>
                          {`${pharmacy.notes ? pharmacy.notes[pharmacy.notes.length - 1].content : '_'}`}
                        </p>
                      )}
                      <Section>
                        <span className="font-medium pr-2">{'Registrierungscode:'}</span>
                        {`${pharmacy.campus_registration_code || '_'}`}
                      </Section>
                      <Section>
                        <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(filialenRef, 'filialen')}>
                          {mainBranch ? 'Hauptfiliale:' : 'Filialen:'}
                        </span>

                        <ul className="divide-y divide-gray-200">
                          {pharmacyBranchesLength ? (
                            <>
                              {pharmacy.branches?.map((pharm) => (
                                <li key={pharm.id} className="w-full border-b border-gray-400 last:border-0">
                                  <div
                                    onClick={() => {
                                      dispatchFilterState({ type: IMapFilterType.CLEAR })
                                      setSelectedPharmacy(pharm.id)
                                    }}
                                    className="flex w-full py-1 cursor-pointer"
                                  >
                                    <span>{`${pharm.name}(${pharm.address?.city || ''})`}</span>
                                  </div>
                                </li>
                              ))}
                            </>
                          ) : null}
                        </ul>
                        <div onClick={() => setSelectedPharmacy(mainBranch?.id)} className="flex w-full py-1 cursor-pointer">
                          {mainBranch ? `${mainBranch.name} (${mainBranch.address.city})` : null}
                        </div>
                        {mainBranch && siblingBranches && (
                          <>
                            <span>
                              <span className="font-medium">Filialen:</span>
                              {siblingBranches.map((each) => (
                                <span
                                  key={each.id}
                                  onClick={() => {
                                    dispatchFilterState({ type: IMapFilterType.CLEAR })
                                    setSelectedPharmacy(each?.id)
                                  }}
                                  className="flex w-full py-1 cursor-pointer"
                                >
                                  {`${each.name} (${each.address.city})`}
                                </span>
                              ))}
                            </span>
                          </>
                        )}
                      </Section>
                    </>
                  )}
                  {pharmacyQuarterVisits && pharmacyQuarterVisits.length ? (
                    <div className="w-full border-t">
                      <h3 className="font-medium pr-2">{'Besuche:'}</h3>

                      {pharmacyQuarterVisits.map((item) => {
                        const date = item.date ? formattedDateString(parseUTC(item.date)) : ''

                        return (
                          <div key={item.id} className="flex hover:bg-gray-50 border-b justify-between text-base leading-6 font-medium">
                            <div className="w-5/6 flex items-center">
                              <span className="font-medium">{date}</span>
                            </div>
                            {abilities.edit_appointments && (
                              <div className="flex w-1/6 justify-end items-center">
                                {abilities.edit_appointments && (
                                  <IconButton
                                    kind={IconButtonKind.default}
                                    icon={<EditIcon />}
                                    onClick={() => openModal({ kind: ModalKind.AppointmentEdit, id: item.id })}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                  {gskData && (
                    <div className="flex items-center">
                      <p className={`${gskData?.isApoTrainer ? 'text-red-700' : 'text-black-300'} font-medium pr-2`}>GSK Trainer Apotheke</p>
                      {!gskData?.isApoTrainer ? (
                        <span className="text-green-500">
                          <CheckIcon dimension={'24'} />
                        </span>
                      ) : (
                        <span className="text-red-700">{<CloseIcon dimension={'24'} />}</span>
                      )}
                    </div>
                  )}
                  {gskData && (
                    <div>
                      <p>
                        <span className="font-medium pr-2">GSK Klasse:</span>
                        {gskData?.class || ''}
                      </p>
                      <p>
                        <span className="font-medium pr-2">BM:</span>
                        {gskData?.BM || ''}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              <div className={`mb-4 h-1px ${!pharmacyQuarterVisits?.length ? 'border-t border-gray-400 border-solid' : ''}`} />
              <QuickActions pharmacy={pharmacy} onOpenPharmacyDetails={onPharmacyDetailClick} onCreateAppointmentClick={onCreateAppointmentClick} />
            </div>
          </div>
        </InfoWindow>
      ) : (
        <span>{'Loading'}</span>
      )}
    </React.Fragment>
  )
}

export default InfoWindowContainer
