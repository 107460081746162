import { debounce, Pharmacy, IPAD_BREAK_POINT } from '@aposphaere/core-kit'
import {
  FilterIcon,
  IconButton,
  IconButtonKind,
  InputSearchPanel,
  InputSearchPanelKind,
  ListViewIcon,
  LocationMarkerInfoIcon,
  MapIcon,
  MaximizeIcon,
  NaviActive,
  NaviInactive,
  SearchIcon,
} from '@aposphaere/ui-components'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { IMapFilterType } from '../../contexts/reducers/mapFilterReducer'
import { useViewport } from '../../hooks/useViewPort'
import { ACTION_PANEL_TABS } from '../../utils/actionPanelTabs'
import ExpandedContent from './ExpandedContent'

type Props = {
  viewMode: 'list' | 'map'
  activateListView: () => void
  activateMapView: () => void
  setSelectedPharmacy: (id: number | undefined) => void
}

const ActionPanel = ({ viewMode, activateMapView, setSelectedPharmacy, activateListView }: Props) => {
  const { filterState, dispatchFilterState } = usePharmacyFilterContext()
  const { selectedDay, setSelectedDay, setRouteResponse, toggleShowSelectedDayOverview } = useRoutePlanner()
  const [showMax, setShowMax] = useState(true)
  const [isTyping, setIsTyping] = useState(false)
  const [activeTab, setActiveTab] = useState<string>()

  const { width } = useViewport()
  const dimension = width && width <= IPAD_BREAK_POINT ? '30' : '36'
  const iconButtonCss =
    'focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150'

  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false)

  const onSearchInput = debounce<string[]>((searchTerm: string) => {
    dispatchFilterState({ type: IMapFilterType.SET_SEARCH_TERM, payload: { searchTerm } })
    if (searchTerm.length >= 1) {
      setShowSearchSuggestions(true)
    } else {
      setShowSearchSuggestions(false)
    }
  }, 1000)

  const onSearchSuggestionClicked = useCallback(
    (pharmacy: Pharmacy) => {
      dispatchFilterState({ type: IMapFilterType.SET_SEARCH_TERM, payload: { searchTerm: pharmacy.okid, pharmacyName: pharmacy.name } })
      setSelectedPharmacy(pharmacy.id)
      setShowSearchSuggestions(false)
      setSelectedDay(undefined)
      setRouteResponse(null)
    },
    [dispatchFilterState, setSelectedPharmacy, setShowSearchSuggestions, setSelectedDay, setRouteResponse],
  )

  const toggleExpandFilter = useCallback(() => {
    setShowMax(!showMax)
  }, [showMax])

  useEffect(() => {
    selectedDay && setShowMax(false)
  }, [selectedDay, setShowMax])

  const inputRef = useRef<null | HTMLInputElement>(null)
  const { width: windowWidth } = useViewport()
  const isIpad = windowWidth ? windowWidth <= 1366 : false

  const onMaximizeClick = useCallback(() => {
    toggleExpandFilter()
  }, [toggleExpandFilter])

  const isList = viewMode === 'list'

  return showMax ? (
    <div className={`relative  ${isTyping ? 'w-3/4' : '2lg:w-3/4'}`}>
      <InputSearchPanel
        selectedPharmacy={filterState.pharmacyName || filterState.searchTerm}
        kind={viewMode === 'list' ? InputSearchPanelKind.flat : InputSearchPanelKind.overlay}
        placeholderText="Standort per ID oder Name suchen."
        onType={onSearchInput}
        expandedContent={
          <ExpandedContent
            onSearchSuggestionClicked={onSearchSuggestionClicked}
            activeTab={activeTab || ''}
            showSearchSuggestions={viewMode === 'map' && showSearchSuggestions}
          />
        }
        isListViewedActivePharmaciesTable
        handleOnBlur={() => setIsTyping(false)}
        handleOnClick={() => setIsTyping(true)}
        ref={inputRef}
        wrapperClass={!isTyping && isIpad && !isList ? 'hidden' : 'flex'}
        inputClass={`${!isTyping && isIpad && !isList ? 'w-0 xl:w-full' : 'w-full 2lg:w-50vw'} rounded px-4 py-2 outline-none`}
      >
        {!isTyping ? (
          <>
            {isIpad && !isList && (
              <>
                <IconButton
                  kind={IconButtonKind.custom}
                  icon={<SearchIcon dimension={'34'} />}
                  additionalCss={iconButtonCss}
                  onClick={() => {
                    setIsTyping(true)
                    inputRef?.current && inputRef?.current?.focus()
                  }}
                />
                <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
              </>
            )}
            <IconButton
              kind={IconButtonKind.custom}
              icon={selectedDay ? <NaviActive dimension={dimension} /> : <NaviInactive dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={toggleShowSelectedDayOverview}
            />
            <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
            <IconButton
              kind={IconButtonKind.custom}
              selected={viewMode === 'list'}
              icon={<ListViewIcon dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={() => {
                if (viewMode !== 'list') {
                  activateListView()
                }
              }}
            />
            <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
            <IconButton
              kind={IconButtonKind.custom}
              selected={viewMode === 'map'}
              icon={<MapIcon dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={() => {
                if (viewMode !== 'map') {
                  activateMapView()
                }
              }}
            />
            <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
            <IconButton
              kind={IconButtonKind.custom}
              selected={activeTab === ACTION_PANEL_TABS.filter}
              isExpandable
              icon={<FilterIcon dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={() => {
                setActiveTab((prev) => (prev === ACTION_PANEL_TABS.filter ? '' : ACTION_PANEL_TABS.filter))
              }}
            />
            <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
            <IconButton
              kind={IconButtonKind.custom}
              selected={activeTab === ACTION_PANEL_TABS.legend}
              isExpandable
              icon={<LocationMarkerInfoIcon dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={() => {
                setActiveTab((prev) => (prev === ACTION_PANEL_TABS.legend ? '' : ACTION_PANEL_TABS.legend))
              }}
            />
            <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
            <IconButton
              kind={IconButtonKind.custom}
              selected={false}
              icon={<MaximizeIcon dimension={dimension} />}
              additionalCss={iconButtonCss}
              onClick={toggleExpandFilter}
            />
          </>
        ) : null}
      </InputSearchPanel>
      <div className="absolute left-5"></div>
    </div>
  ) : (
    <div title="maximize action panel" className="bg-white w-12 absolute  right-0 flex justify-center item center">
      <IconButton
        kind={IconButtonKind.custom}
        selected={false}
        icon={<MaximizeIcon dimension={dimension} />}
        additionalCss={iconButtonCss}
        onClick={onMaximizeClick}
      />
    </div>
  )
}

export default ActionPanel
