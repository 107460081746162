import { gql } from 'graphql-request'

export const allQuarters = gql`
  {
    quarters {
      id
      label
      from
      to
      weeks
      days
      training_goal
      default
    }
  }
`

export const getQuarter = gql`
  query getQuarter($id: ID) {
    quarter(id: $id) {
      id
      label
      from
      to
      weeks
      days
      training_goal
      default
    }
  }
`
