import { Pharmacy, PharmacyStatus, useAbilities, parseUTC } from '@aposphaere/core-kit'
import {
  DeactivateIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LocationProfileIcon,
  LockedForCallsIcon,
  PlusSignIcon,
  ProjectstatusIcon,
  ReminderIcon,
} from '@aposphaere/ui-components'
import { isFuture } from 'date-fns'
import { isToday } from 'date-fns/esm'
import React from 'react'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { useAvailableForCallcenterMutation } from '../../hooks/graphql'
type QuickActionProps = {
  pharmacy: Pharmacy
  onCreateAppointmentClick: () => void
  onOpenPharmacyDetails: () => void
}

const QuickActions: React.FunctionComponent<QuickActionProps> = ({ pharmacy, onCreateAppointmentClick, onOpenPharmacyDetails }) => {
  const { openModal } = useModal()

  const { mutate: setAvailableForCallcenter } = useAvailableForCallcenterMutation()

  const abilities = useAbilities()

  const onCreateReminder = () => {
    openModal({ kind: ModalKind.ReminderCreate, pharmacyId: pharmacy.id })
  }

  const onAddNote = () => {
    openModal({ kind: ModalKind.NoteCreate, pharmacyId: pharmacy.id })
  }

  const onDeactivatePharmacy = () => {
    openModal({ kind: ModalKind.PharmacyDeactivate, id: pharmacy.id, status: pharmacy.pharmacy_status })
  }

  const additionalCss = pharmacy?.tasks?.filter((task) => task?.until && (isToday(parseUTC(task.until)) || isFuture(parseUTC(task.until)))).length
    ? 'text-yellow-600'
    : ''
  return (
    <div className="flex items-center justify-center">
      <IconButton kind={IconButtonKind.default} icon={<LocationProfileIcon />} onClick={onOpenPharmacyDetails} />
      <IconButton
        kind={IconButtonKind.default}
        icon={<ProjectstatusIcon />}
        onClick={() => openModal({ kind: ModalKind.ProjectsEdit, pharmacyId: pharmacy.id })}
      />
      {abilities.create_appointments && <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={onCreateAppointmentClick} />}
      {abilities.create_tasks && (
        <IconButton additionalCss={additionalCss} kind={IconButtonKind.default} icon={<ReminderIcon />} onClick={onCreateReminder} />
      )}
      {abilities?.update_pharmacies_callcenter_access && (
        <IconButton
          kind={IconButtonKind.default}
          additionalCss={pharmacy.available_for_callcenter === false ? 'text-red-700' : ''}
          icon={<LockedForCallsIcon />}
          onClick={() => setAvailableForCallcenter({ id: pharmacy.id, available_for_callcenter: !pharmacy.available_for_callcenter })}
        />
      )}

      {abilities.create_notes && <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={onAddNote} />}
      {abilities.disable_pharmacies && (
        <IconButton
          kind={IconButtonKind.default}
          additionalCss={pharmacy.pharmacy_status === PharmacyStatus.Inactive ? 'text-red-700' : ''}
          icon={<DeactivateIcon />}
          onClick={onDeactivatePharmacy}
        />
      )}
    </div>
  )
}

export default QuickActions
