import React, { FunctionComponent, useRef, useCallback } from 'react'
import DeleteIcon from '../Icons/DeleteIcon'
import { TimeSlotProps } from '../TimeSlot/TimeSlot'

export type DayItemProps = {
  selected?: boolean
  onClick?: () => void
  disabled?: boolean
  visits: number
  date: Date
  countOfReminders: number
  children?: React.ReactNode
  id?: string
  deleteIcon: {
    show: boolean
    onClick?: () => void
  }
  visitIsProcessed?: boolean
  appointmentIsProcessed?: boolean
  showIndicator?: boolean
}

const DayItem: FunctionComponent<DayItemProps> = ({
  countOfReminders,
  date,
  visits,
  selected,
  onClick,
  children,
  id,
  deleteIcon,
  visitIsProcessed,
  appointmentIsProcessed,
  showIndicator,
}) => {
  const ref = useRef<HTMLDivElement>()
  const getWeekdayName = (dateForWeekDayName: Date) => {
    const weekday = dateForWeekDayName.getDay()
    switch (weekday) {
      case 0:
        return 'So'
      case 1:
        return 'Mo'
      case 2:
        return 'Di'
      case 3:
        return 'Mi'
      case 4:
        return 'Do'
      case 5:
        return 'Fr'
      case 6:
        return 'Sa'
    }
  }

  const weekdayName = getWeekdayName(date)

  const formattedDateString = (dateForFormat: Date | number) => {
    const d = dateForFormat
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da}.${mo}`
  }

  const dateString = formattedDateString(date)
  const NoVisits = visits < 1

  const handleDelete = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      deleteIcon.onClick?.()
    },
    [deleteIcon.onClick],
  )
  const processedClass = 'bg-green-450-hi'
  const unprocessedClass = 'bg-red-700'
  const arrayChildren = React.Children.toArray(children)
  const timeSlots = ((arrayChildren[0] as React.ReactElement)?.props?.timeSlots as unknown) as TimeSlotProps[]

  const dayClassName = timeSlots.length > 11 ? 'day-center-3' : 'day-center'

  return (
    <div
      ref={ref}
      id={id}
      className={`ml-1 flex rounded justify-between day row mt-2  ${selected ? 'border-blue-600 border-2' : ''}`}
      onClick={onClick}
    >
      <div className={` ${!selected ? 'rounded-l' : ''} flex relative items-center  text-xxs font-body`}>
        <div className="flex justify-around items-center min-w-8">
          {showIndicator && <div className={`w-1 ml-1 h-11 rounded-md  ${appointmentIsProcessed ? processedClass : unprocessedClass}`}></div>}
          <div
            className={`relative font-normal text-center text-black-200
             flex w-5 py-1 flex-col h-full justify-center col-gap-1 items-center`}
          >
            <div className="mb-3 text-xxxs -mt-1">{weekdayName}</div>
            <div className="relative transform -rotate-90 -translate-y-1 ">{dateString}.</div>
          </div>
        </div>
      </div>
      <div className={`w-full grid grid-cols-5 wrap border-gray-350 ${dayClassName}`}>{children}</div>
      <div className="text-base text-center w-6 rounded-r border-l-1px">
        <div className={`rounded-r flex flex-col  h-full  justify-center item-center  ${NoVisits ? 'text-white' : 'text-white cursor-pointer'}`}>
          <div
            className={`w-3.5 h-3.5 mx-auto rounded-full text-white ${
              NoVisits ? 'bg-gray-400' : 'bg-orange-900'
            } relative text-xs flex items-center justify-center`}
          >
            {visits}
            {showIndicator && !NoVisits && (
              <div
                className={`absolute ${
                  visitIsProcessed ? processedClass : unprocessedClass
                } border-1 border-white w-2 h-2 rounded-full top-0 right-1 -mr-1 -mt-1`}
              ></div>
            )}
          </div>
          {deleteIcon.show && (
            <button
              onClick={handleDelete}
              className=" mx-auto w-6 mt-3 flex items-center justify-center h-3 rounded-md text-gray-600 hover:text-red-500 focus:border-gray-350 focus:outline-none transition ease-in-out duration-150"
            >
              <DeleteIcon dimension="36" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default DayItem
