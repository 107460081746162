import React from 'react'

const ListViewIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Gruppe_171" data-name="Gruppe 171">
      <line
        id="Linie_277"
        data-name="Linie 277"
        x1="13"
        y1="26"
        x2="27"
        y2="26"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_279"
        data-name="Linie 279"
        x1="9"
        y1="26"
        x2="9"
        y2="26"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <line
        id="Linie_104"
        data-name="Linie 104"
        x1="13"
        y1="21"
        x2="27"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_278"
        data-name="Linie 278"
        x1="9"
        y1="21"
        x2="9"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <line
        id="Linie_104-2"
        data-name="Linie 104"
        x1="13"
        y1="16"
        x2="27"
        y2="16"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_278-2"
        data-name="Linie 278"
        x1="9"
        y1="16"
        x2="9"
        y2="16"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
      <line
        id="Linie_103"
        data-name="Linie 103"
        x1="13"
        y1="11"
        x2="27"
        y2="11"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_281"
        data-name="Linie 281"
        x1="9"
        y1="11"
        x2="9"
        y2="11"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
    </g>
  </svg>
)

export default ListViewIcon
