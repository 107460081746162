import {
  ApoClient,
  Quarter,
  useAuth,
  SummaryAppointmentsInQuarter,
  overviewSummaryPerProjectQuery,
  Project,
  getAppointmentsPerProject,
} from '@aposphaere/core-kit'
import { useState, useEffect, useCallback } from 'react'
import { useCrmContext } from '../contexts/crmContext'
import { IOverviewContext } from '../contexts/overviewContext'
import { AppointmentsPerProjectResponse, IAppointmentsPerProjectType, OverviewSummaryPerProjectResponse } from '../pages/OverviewPageTypes'
import { useActiveProjectsQuery } from './graphql'

export type SummaryWithProjectType = {
  appointments_per_project?: IAppointmentsPerProjectType
  name?: string
} & OverviewSummaryPerProjectResponse

const client = new ApoClient()

export const useOverviewProvider = (): IOverviewContext => {
  const auth = useAuth()
  const { activeQuarter } = useCrmContext()
  const [summaryInfo] = useState<{
    totalAppointmentsInQuarter?: number
    pastAppointmentsInQuarter?: number
    futureAppointmentsInQuarter?: number
    summaryOfAppointmentsInQuarter?: SummaryAppointmentsInQuarter
  }>({
    totalAppointmentsInQuarter: undefined,
    pastAppointmentsInQuarter: undefined,
    futureAppointmentsInQuarter: undefined,
    summaryOfAppointmentsInQuarter: undefined,
  })

  const [summaryOfProjectList, setSummaryOfProjectList] = useState<SummaryWithProjectType[]>([])

  type GetSummaryWithProjectType = (project: Project, quater: Quarter) => Promise<SummaryWithProjectType>

  const getSummaryWithProject = useCallback<GetSummaryWithProjectType>(
    async (project, quarter) => {
      const summaryPromise = client.requestWithAuthentication<OverviewSummaryPerProjectResponse>(overviewSummaryPerProjectQuery, auth.token, {
        quarter_id: `${quarter.id}`,
        project_id: project.id,
      })

      const projectAppointmentsPromise = client.requestWithAuthentication<AppointmentsPerProjectResponse>(getAppointmentsPerProject, auth.token, {
        id: project.id,
      })
      return new Promise((resolve, reject) => {
        Promise.all([summaryPromise, projectAppointmentsPromise])
          .then(([summary, projectAppointments]) =>
            resolve({
              ...summary.data,
              appointments_per_project: projectAppointments?.data?.appointments_per_project,
              name: project.name,
            }),
          )
          .catch((error) => {
            reject(error)
          })
      })
    },
    [auth.token],
  )

  const { data: activeProjects } = useActiveProjectsQuery()

  const fetchOverviewInformation = useCallback(
    async (quarter: Quarter) => {
      try {
        if (activeProjects?.length) {
          const summary = await Promise.all<SummaryWithProjectType>(activeProjects.map((eachProject) => getSummaryWithProject(eachProject, quarter)))
          setSummaryOfProjectList(summary)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    },
    [activeProjects, getSummaryWithProject],
  )

  useEffect(() => {
    if (activeQuarter) {
      fetchOverviewInformation(activeQuarter).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
    }
  }, [fetchOverviewInformation, activeQuarter])

  return {
    summaryInfo,
    summaryOfProjectList,
  }
}
