import React from 'react'
import { InputSearchNoResults, InputSearchSuggestions, SearchInputMarkerInfo } from '@aposphaere/ui-components'
import { ACTION_PANEL_TABS } from '../../utils/actionPanelTabs'
import FilterDropdown from './FilterDropdown'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { Pharmacy } from '@aposphaere/core-kit'

type Props = {
  activeTab: string
  showSearchSuggestions: boolean
  onSearchSuggestionClicked: (pharmacy: Pharmacy) => void
}

const ExpandedContent = ({ activeTab, showSearchSuggestions, onSearchSuggestionClicked }: Props) => {
  const resultStyle = 'w-full h-fit my-0 rounded text-base p-2 cursor-pointer hover:bg-blue-50'
  const { filteredPharmacies } = usePharmacyFilterContext()

  if (activeTab === ACTION_PANEL_TABS.legend) {
    return <SearchInputMarkerInfo />
  }
  if (activeTab === ACTION_PANEL_TABS.filter) {
    return <FilterDropdown />
  }

  if (showSearchSuggestions) {
    return !filteredPharmacies.length ? (
      <InputSearchNoResults />
    ) : (
      <InputSearchSuggestions>
        {filteredPharmacies.map((pharmacy) => (
          <div key={pharmacy.id} className={resultStyle} onClick={() => onSearchSuggestionClicked(pharmacy)}>
            {`${pharmacy.name || ''}, ${pharmacy.address?.address_name || ''}, ${pharmacy.address?.zipcode || ''} ${pharmacy.address?.city || ''}`}
          </div>
        ))}
      </InputSearchSuggestions>
    )
  }

  return null
}

export default ExpandedContent
