import { Note, Reminder, useAbilities } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState, useMemo } from 'react'
import { ModalKind, useModal } from '../../contexts/modalContext'
import NoteCardItem from './NotesCardItem'
import ReminderCardItem from './ReminderCardItem'
export interface NotesCardProps {
  notes?: Note[]
  reminders?: Reminder[]
  showAddButton?: boolean
  pharmacyId?: number
}

const NotesCard: React.FunctionComponent<NotesCardProps> = ({ notes, pharmacyId, reminders, showAddButton }) => {
  const sortedNote = useMemo<Note[] | undefined>(() => notes?.slice().sort((a, b) => Date.parse(b.created_at) + Date.parse(a.created_at)), [notes])
  const { openModal } = useModal()

  const [expanded, setExpanded] = useState<boolean>(false)
  const abilities = useAbilities()

  return (
    <>
      {sortedNote !== undefined ? (
        <SectionCard
          isExpanded={expanded}
          setExpanded={setExpanded}
          title="Notizen"
          badgeValue={sortedNote.length}
          onAdd={pharmacyId ? () => openModal({ kind: ModalKind.NoteCreate, pharmacyId }) : undefined}
          showAddButton={showAddButton}
          showAllEntries={sortedNote.length <= 6}
        >
          {sortedNote.length === 0 ? (
            <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
          ) : (
            sortedNote.map((note) => (
              <NoteCardItem
                onOpenModal={[
                  () => openModal({ kind: ModalKind.NoteEdit, id: note.id }),
                  () => openModal({ kind: ModalKind.NoteDelete, id: note.id }),
                ]}
                canEditNotes={abilities.edit_notes}
                canDeleteNotes={abilities.delete_notes}
                note={note}
                key={note.id}
              />
            ))
          )}
        </SectionCard>
      ) : null}

      {reminders !== undefined ? (
        <SectionCard
          isExpanded={expanded}
          setExpanded={setExpanded}
          title="Wiedervorlagen"
          badgeValue={reminders.length || undefined}
          onAdd={pharmacyId ? () => openModal({ kind: ModalKind.ReminderCreate, pharmacyId }) : undefined}
          showAddButton={showAddButton}
          showAllEntries={reminders.length <= 6}
        >
          {reminders.length === 0 ? (
            <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
          ) : (
            reminders.map((reminder) => (
              <ReminderCardItem
                onOpenModal={[
                  () => openModal({ kind: ModalKind.ReminderEdit, id: reminder.id }),
                  () => openModal({ kind: ModalKind.ReminderDelete, id: reminder.id }),
                ]}
                canEditReminders={abilities.edit_notes}
                canDeleteReminders={abilities.delete_notes}
                reminder={reminder}
                key={reminder.id}
              />
            ))
          )}
        </SectionCard>
      ) : null}
    </>
  )
}

export default NotesCard
