import { gql } from 'graphql-request'

export const createReminder = gql`
  mutation($pharmacy_id: String!, $user_id: String!, $note: String!, $contact_person: String!, $until: DateTimeUtc!, $state: String!) {
    createTask(pharmacy_id: $pharmacy_id, user_id: $user_id, note: $note, contact_person: $contact_person, until: $until, state: $state) {
      id
    }
  }
`

export const updateReminder = gql`
  mutation($id: ID!, $pharmacy_id: String!, $user_id: String!, $note: String!, $contact_person: String!, $until: DateTimeUtc!, $state: String!) {
    updateTask(id: $id, pharmacy_id: $pharmacy_id, user_id: $user_id, note: $note, contact_person: $contact_person, until: $until, state: $state) {
      id
    }
  }
`

export const deleteReminder = gql`
  mutation($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`
