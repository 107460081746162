import React from 'react'

const CloseIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <g id="Gruppe_19" data-name="Gruppe 19">
          <line
            id="Linie_27"
            data-name="Linie 27"
            x1="26"
            y1="10"
            x2="10.0469"
            y2="26"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Linie_28"
            data-name="Linie 28"
            x1="25.9531"
            y1="26"
            x2="10"
            y2="10"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CloseIcon
