import React from 'react'

const NaviInactive: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g
      id="Pfad_180"
      data-name="Pfad 180"
      transform="translate(21, 3) rotate(45)"
      fill="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity="0.491"
    >
      <path
        d="M 14.86529731750488 17.59748840332031 L 8.737865447998047 15.16662311553955 L 8.369105339050293 15.02032279968262 L 8.000345230102539 15.16662311553955 L 1.872913002967834 17.59748840332031 L 8.369105339050293 2.526334047317505 L 14.86529731750488 17.59748840332031 Z"
        stroke="none"
      />
      <path
        d="M 8.369105339050293 5.052687644958496 L 3.745847702026367 15.77863693237305 L 7.631585121154785 14.23709297180176 C 8.105305671691895 14.04916381835938 8.632905006408691 14.04916381835938 9.106625556945801 14.23709297180176 L 12.99236297607422 15.77863693237305 L 8.369105339050293 5.052687644958496 M 8.369105339050293 3.814697265625e-06 L 16.73821449279785 19.41632270812988 L 8.369105339050293 16.09614372253418 L -3.814697265625e-06 19.41632270812988 L 8.369105339050293 3.814697265625e-06 Z"
        stroke="none"
        fill="#b6bccb"
      />
    </g>
  </svg>
)

export default NaviInactive
