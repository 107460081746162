import { gql } from 'graphql-request'

export const totalAppointmentsInQuarterQuery = gql`
  query totalAppointmentsInQuarter($quarter_id: Int) {
    totalAppointmentsInQuarter(quarter_id: $quarter_id)
  }
`
export const pastAppointmentsInQuarterQuery = gql`
  query pastAppointmentsInQuarter($quarter_id: Int) {
    pastAppointmentsInQuarter(quarter_id: $quarter_id)
  }
`
export const futureAppointmentsInQuarterQuery = gql`
  query futurAppointmentsInQuarter($quarter_id: Int) {
    futurAppointmentsInQuarter(quarter_id: $quarter_id)
  }
`
export const summaryOfAppointmentsInQuarterQuery = gql`
  query summaryOfAppointmentsInQuarter($quarter_id: Int) {
    summaryOfAppointmentsInQuarter(quarter_id: $quarter_id) {
      summary {
        quarter_id
        past
        future
      }
      projects {
        id
        name
        past
        future
        contact_goal
        amount_summary
      }
    }
  }
`

export const overviewSummaryPerProjectQuery = gql`
  query overviewSummaryPerProject($quarter_id: ID!, $project_id: ID!) {
    pastSummaryByProjects(quarter_id: $quarter_id, project_id: $project_id) {
      total_past_appointments_by_project
      total_past_slots
      total_past_participants
      average_past_time_in_minutes
    }

    futureSummaryByProjects(quarter_id: $quarter_id, project_id: $project_id) {
      total_future_appointments_by_project
      total_future_slots
      total_future_pharmacies
    }

    progressSummaryByProjects(quarter_id: $quarter_id, project_id: $project_id) {
      progress
    }
  }
`
