import { Button, ButtonKind } from '@aposphaere/ui-components'
import React from 'react'
import { StepStates, useCalendarPanel } from './CalendarPanelContext'

export const Navigation = ({ step, onClose, disabled = false }: { step: StepStates; onClose: () => void; disabled: boolean }) => {
  const { goTo } = useCalendarPanel()

  if (step === StepStates.DateSelection) {
    return (
      <div className="z-9999 px-2.5 py-3.5 absolute flex justify-between items-center bg-white bottom-0 w-full">
        <Button kind={ButtonKind.outlinedPrimary} onClick={() => (onClose ? onClose() : null)}>
          Abbrechen
        </Button>
        <Button disabled={disabled} kind={ButtonKind.primary} onClick={() => goTo(StepStates.DetailsSection)}>
          Weiter
        </Button>
      </div>
    )
  }
  if (step === StepStates.DetailsSection) {
    return (
      <div className="z-9999 px-2.5 py-3.5 absolute flex justify-between items-center bg-white bottom-0 w-full">
        <Button
          kind={ButtonKind.outlinedPrimary}
          onClick={() => {
            goTo(StepStates.DateSelection)
          }}
        >
          Zurück
        </Button>
        <Button disabled={disabled} kind={ButtonKind.secondaryGreen} type="submit">
          Anlegen
        </Button>
      </div>
    )
  }
  return null
}
