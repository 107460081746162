import React from 'react'

const SwitchOnIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <rect
      x="8"
      y="13"
      width="20"
      height="11"
      rx="5.5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle cx="22.5" cy="18.5" r="2.5" fill="currentColor" />
    <circle cx="22.5" cy="18.5" r="2.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M22.5,16A2.5,2.5,0,1,0,25,18.5,2.5,2.5,0,0,0,22.5,16Z" fill="currentColor" />
  </svg>
)

export default SwitchOnIcon
