import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  TextAreaInput,
  IconButton,
  IconButtonKind,
  DeleteIcon,
  toast,
} from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useCrmContext } from '../../contexts/crmContext'
import { useNoteWeekCreateMutation, useNoteWeekDeleteMutation, useNoteWeeksQuery, useNoteWeekUpdateMutation } from '../../hooks/graphql'

type NoteWeekFormValues = {
  note: string
}
type NoteWeekCreate = {
  year: string
  week: string
  pharmacy_cluster_id: string
  note: string
}
type NoteWeekUpdate = {
  id: number | undefined
  note: string
}

type NoteWeekDelete = {
  id: number
}

type INoteWeekPharmacyClusterModalProps = {
  mode: 'create' | 'edit' | 'delete'
  year: number
  week: number
  onClose: () => void
}

const NoteWeekPharmacyClusterModal: React.FC<INoteWeekPharmacyClusterModalProps> = ({ mode, week, year, onClose }) => {
  const { mutate: updateNoteWeek } = useNoteWeekUpdateMutation()
  const { mutate: createNoteWeek } = useNoteWeekCreateMutation()
  const { mutate: deleteNoteWeek, status } = useNoteWeekDeleteMutation()

  const [title, setTitle] = useState<string>(`KW ${week}`)
  const { activeCluster } = useCrmContext()
  const noteWeeksQuery = useNoteWeeksQuery()

  const noteWeek = noteWeeksQuery?.data?.find((note) => note.week === String(week) && note.year === String(year))
  const existedNote = noteWeek?.note
  const intialFormValues: NoteWeekFormValues = {
    note: existedNote || '',
  }

  const handleDelete = () => {
    if (!noteWeek?.id) {
      return
    }
    const NoteWeekDelete: NoteWeekDelete = {
      id: noteWeek.id,
    }

    deleteNoteWeek(NoteWeekDelete)
    if (status === 'error') {
      return
    }
    onClose()

    toast.show({
      headline: 'Notiz gelöscht',
      type: 'success',
    })
  }

  const DeleteNoteButton = (
    <IconButton
      additionalCss="text-red-700 hover:!text-red-700 hover:!bg-red-100"
      kind={IconButtonKind.default}
      onClick={handleDelete}
      icon={<DeleteIcon />}
    />
  )

  const onFormSubmit = (values: NoteWeekFormValues) => {
    if (mode === 'create') {
      const noteWeekCreate: NoteWeekCreate = {
        note: values.note,
        pharmacy_cluster_id: String(activeCluster.id),
        week: week.toString(),
        year: year.toString(),
      }
      createNoteWeek(noteWeekCreate)
    }

    if (mode === 'edit') {
      const NoteWeekUpdate: NoteWeekUpdate = {
        id: noteWeek?.id,
        note: values.note ?? '',
      }
      updateNoteWeek(NoteWeekUpdate)
    }

    onClose()
  }

  const onCloseCalendar = () => {
    setTitle('Neuer Termin')
  }
  const NoteWeekSchema = Yup.object().shape({
    note: Yup.string().required('Pflichtfeld'),
  })

  return (
    <Modal
      kind={ModalKind.sm}
      title={title}
      onClose={onClose}
      onBack={onCloseCalendar}
      noPadding={true}
      headerAdditionalButtons={mode !== 'create' ? DeleteNoteButton : null}
    >
      <Formik key="note-week-pharmacy-creation-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={NoteWeekSchema}>
        {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
          <>
            <div className={`flex flex-wrap w-full px-4 xl:px-6 pt-2`}>
              <div className="flex flex-wrap w-full">
                <div className="w-full p-4">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notiz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        disabled={mode === 'delete'}
                        initialValue={values.note}
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                        placeholder=""
                      />
                    </div>
                    {errors.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                  </div>
                  <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                    <Button kind={ButtonKind.secondary} onClick={onClose}>
                      {'Abbrechen'}
                    </Button>
                    <div className="space-x-4">
                      <Button kind={mode === 'delete' ? ButtonKind.danger : ButtonKind.secondaryGreen} disabled={isSubmitting} onClick={handleSubmit}>
                        {mode === 'create' && 'speichern'}
                        {mode === 'edit' && 'speichern'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default NoteWeekPharmacyClusterModal
