import { debounce } from '@aposphaere/core-kit'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

interface IInputWithValidation {
  onChangeCheck: (value: string) => boolean
  schema: Yup.StringSchema<string | undefined>
  placeholder: string
  getErrorOrSetValue: (value: string, bool: boolean) => string | void
  debounceTime: number
  disabled: boolean
  initialValue?: string
  onFocus?: () => void
}

const InputWithValidation: React.FC<IInputWithValidation> = ({
  initialValue = '',
  disabled,
  onChangeCheck,
  schema,
  placeholder,
  getErrorOrSetValue,
  debounceTime,
  onFocus,
}) => {
  const [value, setValue] = useState<string>(initialValue)
  const [inputError, setInputError] = useState<string>('')

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      if (onChangeCheck(value)) {
        const errorMessage = getErrorOrSetValue(value, schema.isValidSync(value))
        if (errorMessage) {
          setInputError(errorMessage)
        } else {
          setInputError('')
        }
      }
    }, debounceTime)

    debouncedUpdate()
  }, [debounceTime, getErrorOrSetValue, onChangeCheck, schema, value])
  return (
    <>
      <input
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onFocus={onFocus}
        className={`form-input font-body block w-full h-10
        bg-gray-100 rounded-md px-4 py-2 text-base
        leading-6 border outline-none focus:shadow-focus
        focus:border-4 border-solid border-gray-400 focus:border-blue-400
        sm:text-base sm:leading-5`}
      />
      {inputError && <p className={'mt-1 font-body text-xs text-red-600 md:text-sm'}>{inputError}</p>}
    </>
  )
}

export default InputWithValidation
