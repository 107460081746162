import { gql } from 'graphql-request'

export const deactivatePharmacy = gql`
  mutation($pharmacy_id: ID!) {
    deletePharmacy(id: $pharmacy_id) {
      id
      name
    }
  }
`

export const updatePharmacy = gql`
  mutation(
    $id: ID!
    $name: String
    $okid: String
    $phone: String
    $email: String
    $contact_person: String
    $url: String
    $facebook: String
    $classification: String
    $pharmacy_cluster_id: String
    $address: AddressInputs
    $pharmacy_status: String
    $reason_for_deactivation: String
  ) {
    updatePharmacy(
      id: $id
      name: $name
      okid: $okid
      phone: $phone
      email: $email
      contact_person: $contact_person
      url: $url
      facebook: $facebook
      classification: $classification
      pharmacy_cluster_id: $pharmacy_cluster_id
      address: $address
      pharmacy_status: $pharmacy_status
      reason_for_deactivation: $reason_for_deactivation
    ) {
      id
      name
    }
  }
`

export const updatePharmacyStatus = gql`
  mutation setPharmacyStatus($pharmacyId: ID!, $pharmacyStatus: PharmacyStatus!, $reason: String) {
    setPharmacyStatus(id: $pharmacyId, pharmacy_status: $pharmacyStatus, reason_for_deactivation: $reason) {
      id
      pharmacy_status
      reason_for_deactivation
    }
  }
`

export const updatePharmacyForCallCenter = gql`
  mutation setCallcenterAccess($id: ID!, $available_for_callcenter: Boolean!) {
    setPharmacyCallcenterAccess(id: $id, available_for_callcenter: $available_for_callcenter) {
      id
      available_for_callcenter
    }
  }
`
