import { Pharmacy, useAbilities, parseUTC, PharmacyStatus } from '@aposphaere/core-kit'
import {
  DeactivateIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LockedForCallsIcon,
  PlusSignIcon,
  ReminderIcon,
  ProjectstatusIcon,
  LocationProfileIcon,
} from '@aposphaere/ui-components'
import { isFuture, isToday } from 'date-fns'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ModalKind, useModal } from '../../contexts/modalContext'
import { useAvailableForCallcenterMutation } from '../../hooks/graphql'

interface IQuickActionsInTable {
  pharmacy: Pharmacy
  onCreateAppointment: (id: number | undefined) => void
  onCreateNote?: () => void
  onDeletePharmacy?: () => void
  onCreateReminder?: () => void
}

const QuickActionsInTable: React.FC<IQuickActionsInTable> = ({ pharmacy, onCreateReminder, onCreateAppointment, onCreateNote, onDeletePharmacy }) => {
  const additionalCssForReminder = pharmacy.tasks?.filter((task) => task?.until && (isToday(parseUTC(task.until)) || isFuture(parseUTC(task.until))))
    .length
    ? 'text-yellow-600'
    : ''

  const abilities = useAbilities()

  const handleAvailableForCallcenter = (id: number) => {
    setAvailableForCallcenter({ id: id, available_for_callcenter: !pharmacy?.available_for_callcenter })
  }

  const history = useHistory()

  const handleDetailsClick = (id: number) => {
    history.push('/', {
      pharmacyId: id,
      action: 'pharmacy-details',
    })
  }
  const { openModal } = useModal()
  const { mutate: setAvailableForCallcenter } = useAvailableForCallcenterMutation()
  return (
    <div className="flex justify-center border-l border-solid border-gray-400 pl-1 2x:pl-4">
      <IconButton kind={IconButtonKind.default} icon={<LocationProfileIcon />} onClick={() => handleDetailsClick(pharmacy.id)} />
      <IconButton
        kind={IconButtonKind.default}
        icon={<ProjectstatusIcon />}
        onClick={() => openModal({ kind: ModalKind.ProjectsEdit, pharmacyId: pharmacy.id })}
      />
      {abilities.create_appointments && (
        <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={() => onCreateAppointment(pharmacy.id)} />
      )}
      {abilities.create_tasks && (
        <IconButton kind={IconButtonKind.default} icon={<ReminderIcon />} additionalCss={additionalCssForReminder} onClick={onCreateReminder} />
      )}

      {abilities?.update_pharmacies_callcenter_access && (
        <IconButton
          kind={IconButtonKind.default}
          icon={<LockedForCallsIcon />}
          onClick={() => handleAvailableForCallcenter(pharmacy.id)}
          additionalCss={pharmacy?.available_for_callcenter === false ? 'text-red-700' : ''}
        />
      )}

      {abilities.create_notes && <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={onCreateNote} />}
      {abilities.disable_pharmacies && (
        <IconButton
          kind={IconButtonKind.default}
          icon={<DeactivateIcon />}
          onClick={onDeletePharmacy}
          additionalCss={pharmacy.pharmacy_status === PharmacyStatus.Inactive ? 'text-red-700' : ''}
        />
      )}
    </div>
  )
}

export default React.memo(QuickActionsInTable)
