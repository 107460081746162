import { BaseError, ErrorKind } from './base_error'

/**
 * Is raised when the client makes an authenticated request
 */
export class UnauthenticatedError extends BaseError {
  constructor(message: string) {
    super(ErrorKind.auth, message)
  }
}
