import React from 'react'

const EditIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <path
      d="M15.4332,25.5985a.9051.9051,0,0,1-.44.2427l-2.4575.5571-1.9583.444a.9053.9053,0,0,1-1.0831-1.0831l1.0011-4.4159a.9048.9048,0,0,1,.2427-.44L21.8648,9.7765a2.4717,2.4717,0,0,1,3.4954,0l1.2,1.2a2.4717,2.4717,0,0,1,0,3.4954Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="18.8053"
      y1="12.836"
      x2="23.5005"
      y2="17.5312"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default EditIcon
