import React, { useCallback } from 'react'
import { InputSearchFilter, TagToggleButton, toast } from '@aposphaere/ui-components'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { IMapFilterType } from '../../contexts/reducers/mapFilterReducer'
import { Project } from '@aposphaere/core-kit'
import { useActiveProjectsQuery } from '../../hooks/graphql'

const FilterDropdown = () => {
  const { filterState, dispatchFilterState, areProjectsSelected } = usePharmacyFilterContext()

  const { data: activeProjects } = useActiveProjectsQuery()
  const isProjectSelected = useCallback((project: Project) => !!filterState.selectedProjects?.some((id) => id === project.id.toString()), [
    filterState.selectedProjects,
  ])
  const areAllSelected = filterState.selectedProjects?.length === activeProjects?.length
  const toggleButtonCss = 'mr-2 mb-2 h-fit'
  const isAnyProjectSelected = filterState.selectedProjects?.length > 0

  const onAllProjectClick = useCallback(() => {
    const selectedProjects = areAllSelected ? [] : activeProjects?.map(({ id }) => id.toString())
    dispatchFilterState({ type: IMapFilterType.SET_SELECTED_PROJECTS, payload: { selectedProjects } })
  }, [areAllSelected, dispatchFilterState, activeProjects])

  const handleToggleButtonClick = useCallback(
    (type: IMapFilterType) => () => {
      if (isAnyProjectSelected) {
        dispatchFilterState({ type })
      } else {
        toast.show({ headline: 'Bitte wählen Sie zuerst ein Projekt aus.' })
      }
    },
    [dispatchFilterState, isAnyProjectSelected],
  )

  const orderableProjects = activeProjects?.filter((project) => project.is_orderable) ?? []

  return (
    <div className="flex">
      <InputSearchFilter
        filterByProjectOption={filterState.filterByOption}
        setFilterByProjectOption={(payload) => dispatchFilterState({ type: IMapFilterType.SET_FILTER_OPTION, payload: { filterByOption: payload } })}
      >
        <div className={'flex-1'}>
          <TagToggleButton key="projectAll" onClick={onAllProjectClick} customCSS="mr-2 mb-2" selected={areAllSelected}>
            {'Alle'}
          </TagToggleButton>
          {orderableProjects.map((project) => (
            <TagToggleButton
              key={`project-${project.id}`}
              onClick={() => {
                if (filterState.selectedProjects.includes(project.id.toString())) {
                  const selectedProjects = filterState.selectedProjects.filter((proj) => proj !== project.id.toString())
                  dispatchFilterState({ type: IMapFilterType.SET_SELECTED_PROJECTS, payload: { selectedProjects } })
                } else {
                  const selectedProjects = [...filterState.selectedProjects, project.id.toString()]
                  dispatchFilterState({ type: IMapFilterType.SET_SELECTED_PROJECTS, payload: { selectedProjects } })
                }
              }}
              customCSS="mr-2 mb-2"
              selected={isProjectSelected(project)}
            >
              {project.name}
            </TagToggleButton>
          ))}
        </div>
      </InputSearchFilter>

      <div className="flex p-4 flex-wrap content-start w-2/5">
        <TagToggleButton
          key="projectAllFutureProject"
          onClick={handleToggleButtonClick(IMapFilterType.SET_ONLY_WITH_SCHEDULED_APPOINTMENTS)}
          customCSS={toggleButtonCss}
          selected={filterState.onlyWithScheduledAppointments}
          disabled={!areProjectsSelected}
        >
          {'Termin vereinbart'}
        </TagToggleButton>
        <TagToggleButton
          key="Nur Prio"
          onClick={handleToggleButtonClick(IMapFilterType.SET_ONLY_PRIORITY)}
          customCSS={toggleButtonCss}
          selected={filterState.isOnlyPriority}
          disabled={!areProjectsSelected}
        >
          {'Nur Prio'}
        </TagToggleButton>
        <TagToggleButton
          key="inkl. Absager"
          onClick={handleToggleButtonClick(IMapFilterType.SET_INCLUDES_TERMINATED_PROJECTS)}
          customCSS={toggleButtonCss}
          selected={filterState.includesTerminatedProjects}
          disabled={!areProjectsSelected}
        >
          {'inkl. Absager'}
        </TagToggleButton>
        <TagToggleButton
          key="Call Center Available"
          onClick={handleToggleButtonClick(IMapFilterType.SET_IS_AVAILABLE_FOR_CALLCENTER)}
          customCSS={toggleButtonCss}
          selected={filterState.isAvailableForCallcenter}
          disabled={!areProjectsSelected}
        >
          {'Call Center Available'}
        </TagToggleButton>
      </div>
    </div>
  )
}

export default FilterDropdown
