/*eslint-disable no-console */

import { Appointment, Offtime, Reminder, parseUTC } from '@aposphaere/core-kit'
import { TimeSlotKind } from '@aposphaere/ui-components'
import { useEffect, useState } from 'react'
import { StatusWithCancelledProp, useAppointmentsQuery, useOfftimesQuery, useStatusesQuery, useTasksQuery } from '../../hooks/graphql'
import { determineDayItemHoverText, getAppointmentKind, getOfftimeKind, isAppointmentAVisit } from './utils'
import { compareDesc, eachDayOfInterval, getWeek, getYear, isFuture, isPast, isSameDay, isSameHour, isWeekend, startOfWeek } from 'date-fns'
import { useCrmContext } from '../../contexts/crmContext'
import { FederalStateHoliday } from '@aposphaere/core-kit/build/models/federal_state'

const AVAILABLE_HOURS_IN_A_DAY = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

/*
 * TYPES
 */
type CalendarWeek = {
  date: Date
  weekNumber: number
  days: CalendarDay[]
}

export interface CalendarDay {
  visits: Appointment[]
  timeslots: CalendarTimeSlot[]
  tasks: Reminder[]
  holiday?: FederalStateHoliday
  date: Date
}

export type CalendarTimeSlot = { kind: TimeSlotKind; label: string; date: Date } & (
  | {
      type: 'appointment'
      data: Appointment
    }
  | {
      type: 'offtime'
      data: Offtime
    }
  | {
      type: 'free'
    }
  | {
      type: 'state-holiday'
    }
)

/*
 * HOOK
 */
interface CalendarWeekOptions {
  selectedTime?: Date
  disablePastSlots?: boolean
}

export const useCalendarData = (options: CalendarWeekOptions): { calendarWeeks: CalendarWeek[] } => {
  const { activeQuarter, activeCluster } = useCrmContext()

  const offtimesQuery = useOfftimesQuery()
  const appointmentsQuery = useAppointmentsQuery()
  const tasksQuery = useTasksQuery()
  const { data: statuses } = useStatusesQuery()

  const [calendarWeeks, setCalendarWeeks] = useState<CalendarWeek[]>([])

  const appointmentsQueryData = appointmentsQuery?.data
  const offtimesQueryData = offtimesQuery?.data
  const statusesforAppointments = statuses?.forAppointments
  const tasksQueryData = tasksQuery?.data

  // Mapping data into calendar weeks
  useEffect(() => {
    if (!activeQuarter.from || !activeQuarter.to) {
      return
    }
    const start = parseUTC(activeQuarter.from)
    const end = parseUTC(activeQuarter.to)
    const year = getYear(start)

    const calendar = generateEmptyCalendarSkeleton(start, end)

    mapAppointmentsToTimeslots({
      weeksToEnhance: calendar,
      statuses: statusesforAppointments || [],
      appointments: appointmentsQueryData,
      year,
    })

    mapOfftimesToTimeslots({ weeksToEnhance: calendar, offtimes: offtimesQueryData, year })
    mapTasksToTimeslots({ weeksToEnhance: calendar, tasks: tasksQueryData, year })
    populateTimeslots(calendar, { selectedTime: options.selectedTime })
    addStateHolidays({ weeksToEnhance: calendar, holidays: activeCluster?.agent?.address?.federal_state?.holidays ?? [], year })

    if (options.disablePastSlots) {
      disablePastSlots(calendar)
    }

    setCalendarWeeks(calendar)
  }, [
    activeQuarter,
    statusesforAppointments,
    appointmentsQueryData,
    offtimesQueryData,
    options.disablePastSlots,
    options.selectedTime,
    tasksQueryData,
    activeCluster?.agent?.address?.federal_state?.holidays,
  ])

  return { calendarWeeks }
}

/*
 * UTILS
 */
function generateEmptyCalendarSkeleton(from: Date, to: Date): CalendarWeek[] {
  const days = eachDayOfInterval({ start: from, end: to })

  const calendar: CalendarWeek[] = []

  days.forEach((day) => {
    const dayOfWeek = day.getDay()

    if (isWeekend(day)) {
      return
    }

    const weekNumber = getWeek(day)

    if (!calendar[weekNumber]) {
      calendar[weekNumber] = { weekNumber, days: [], date: startOfWeek(day) }
    }
    calendar[weekNumber].days[dayOfWeek] = { timeslots: [], visits: [], tasks: [], date: day }
  })
  return calendar
}

interface MapAppointmentsParams {
  weeksToEnhance: CalendarWeek[]
  appointments: Appointment[] | undefined
  year: number
  statuses: StatusWithCancelledProp[]
}
function mapAppointmentsToTimeslots({ weeksToEnhance, statuses, appointments, year }: MapAppointmentsParams) {
  appointments?.forEach((appointment) => {
    if (!appointment.date) {
      return
    }
    const date = parseUTC(appointment.date)
    if (date.getFullYear() !== year) {
      return
    }
    const week = getWeek(date)
    const day = date.getDay()

    const kind = getAppointmentKind(appointment, statuses)
    const label = determineDayItemHoverText(kind)

    try {
      if (isAppointmentAVisit(appointment)) {
        weeksToEnhance[week].days[day].visits.push(appointment)
      } else {
        weeksToEnhance[week].days[day].timeslots.push({
          type: 'appointment',
          data: appointment,
          date: date,
          kind,
          label,
        })
      }
    } catch {
      // Appointment is outside of current quarter
    }
  })
}

interface MapOfftimesParams {
  weeksToEnhance: CalendarWeek[]
  offtimes: Offtime[] | undefined
  year: number
}
function mapOfftimesToTimeslots({ weeksToEnhance, offtimes, year }: MapOfftimesParams) {
  offtimes?.forEach((offtime) => {
    if (!offtime.date) {
      return
    }
    const date = parseUTC(offtime.date)
    if (date.getFullYear() !== year) {
      return
    }

    const week = getWeek(date)
    const day = date.getDay()

    const kind = getOfftimeKind(offtime.offtime_type)

    const label = determineDayItemHoverText(kind)

    try {
      weeksToEnhance[week].days[day].timeslots.push({ type: 'offtime', date, data: offtime, kind, label } as const)
    } catch {
      // Offtime is outside of current quarter
    }
  })
}

interface MapTasksParams {
  weeksToEnhance: CalendarWeek[]
  tasks: Reminder[] | undefined
  year: number
}

function mapTasksToTimeslots({ weeksToEnhance, tasks, year }: MapTasksParams) {
  tasks?.forEach((task) => {
    if (!task.until) {
      return
    }
    const date = parseUTC(task.until)
    if (date.getFullYear() !== year) {
      return
    }
    const week = getWeek(date)
    const day = date.getDay()
    try {
      weeksToEnhance[week].days[day].tasks.push(task)
    } catch {
      // Task is outside of current quarter
    }
  })
}

interface PopulateOptions {
  selectedTime?: Date
  disablePastSlots?: boolean
}
function populateTimeslots(calendarWeeks: CalendarWeek[], options: PopulateOptions = {}) {
  calendarWeeks.forEach((week) => {
    week.days.forEach((day) => {
      day.timeslots = addEmptyTimeslots(day, options)
    })
  })
}

/**
 * Fills up the days, with 'free' timeslots, where a time is unoccupied
 */
function addEmptyTimeslots(day: CalendarDay, options: PopulateOptions = {}) {
  const occupiedSlots = day.timeslots
  const fullDayOfftime = occupiedSlots.find((slot) => slot.type === 'offtime' && slot.data.whole_day)

  const timeslotsOnGivenDay = AVAILABLE_HOURS_IN_A_DAY.reduce((accumulatedSlots: CalendarTimeSlot[][], currentHour) => {
    const occupiedSlotsInHour = occupiedSlots.filter((slot) => slot.date.getHours() === currentHour)
    occupiedSlotsInHour?.sort((a, b) => compareDesc(a.date, b.date))

    const hasSlotsInHour = occupiedSlotsInHour.length

    if (hasSlotsInHour) {
      accumulatedSlots.push(occupiedSlotsInHour)
    }

    if (!hasSlotsInHour) {
      const date = new Date(day.date)
      date.setHours(currentHour)

      if (fullDayOfftime) {
        accumulatedSlots.push([{ ...fullDayOfftime, date }])
      } else {
        const isSelected = options?.selectedTime ? isSameHour(date, options.selectedTime) : false
        const freeTimeslot: CalendarTimeSlot = {
          type: 'free',
          date: date,
          kind: isSelected ? TimeSlotKind.selected : TimeSlotKind.available,
          label: '',
        }
        accumulatedSlots.push([freeTimeslot])
      }
    }

    return accumulatedSlots
  }, []).flat()
  return timeslotsOnGivenDay
}

interface AddStateHolidaysParams {
  weeksToEnhance: CalendarWeek[]
  holidays: FederalStateHoliday[] | undefined
  year: number
}
function addStateHolidays({ weeksToEnhance, holidays }: AddStateHolidaysParams) {
  weeksToEnhance.forEach((week) => {
    week.days.forEach((day) => {
      const holidayInGivenDay = holidays?.find((holiday) => holiday?.date && isSameDay(new Date(holiday.date), day.date))
      if (holidayInGivenDay) {
        day.holiday = holidayInGivenDay || undefined
        day.timeslots = day.timeslots.map(
          (slot): CalendarTimeSlot => ({
            kind: TimeSlotKind.publicHoliday,
            type: 'state-holiday',
            date: slot.date,
            label: 'public-holiday',
          }),
        )
      }
    })
  })
}

function disablePastSlots(calendarWeeks: CalendarWeek[]) {
  calendarWeeks.forEach((week) => {
    if (isFuture(week.date)) {
      return
    }
    week.days.forEach((day) => {
      day.timeslots.forEach((slot) => {
        if (isFuture(day.date)) {
          return
        }
        if (isPast(slot.date)) {
          slot.kind = TimeSlotKind.disabled
          slot.label = determineDayItemHoverText(TimeSlotKind.disabled)
        }
      })
    })
  })
}
