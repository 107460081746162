import { svgToDataUri } from '@aposphaere/core-kit'

const CrmLogoSmall = svgToDataUri(`
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="358.625" height="125.4208"
  viewBox="0 0 358.625 125.4208">
  <g id="C">
    <path
      d="M211.09,80.3877q-4.6274-4.8252-4.9589-13.2139-.0675-1.7974-.0674-5.8584,0-4.1265.0674-5.9912.3309-8.3877,4.9589-13.2149,4.626-4.8252,13.4805-4.8261A22.0109,22.0109,0,0,1,234.623,39.38a14.8775,14.8775,0,0,1,8.5206,12.7491,1.2016,1.2016,0,0,1-.4327.9648,1.5448,1.5448,0,0,1-1.0322.3662h-3.3281a1.6039,1.6039,0,0,1-1.0645-.333,2.0962,2.0962,0,0,1-.6-1.1318q-1.1324-5.1255-4.0937-7.19a13.8221,13.8221,0,0,0-8.0225-2.0635q-11.5825,0-12.0488,12.9151-.0675,1.7973-.0664,5.5254t.0664,5.6582q.4643,12.8481,12.0488,12.8486a13.8371,13.8371,0,0,0,7.9893-2.0644q2.9955-2.0625,4.1269-7.1231a2.1029,2.1029,0,0,1,.6-1.1318,1.6148,1.6148,0,0,1,1.0645-.3321h3.3281a1.5452,1.5452,0,0,1,1.0322.3663,1.2026,1.2026,0,0,1,.4327.9648,14.8771,14.8771,0,0,1-8.5206,12.748A21.9963,21.9963,0,0,1,224.57,85.2145Q215.7169,85.2148,211.09,80.3877Z"
      fill="#fff" />
    <path
      d="M211.09,80.3877q-4.6274-4.8252-4.9589-13.2139-.0675-1.7974-.0674-5.8584,0-4.1265.0674-5.9912.3309-8.3877,4.9589-13.2149,4.626-4.8252,13.4805-4.8261A22.0109,22.0109,0,0,1,234.623,39.38a14.8775,14.8775,0,0,1,8.5206,12.7491,1.2016,1.2016,0,0,1-.4327.9648,1.5448,1.5448,0,0,1-1.0322.3662h-3.3281a1.6039,1.6039,0,0,1-1.0645-.333,2.0962,2.0962,0,0,1-.6-1.1318q-1.1324-5.1255-4.0937-7.19a13.8221,13.8221,0,0,0-8.0225-2.0635q-11.5825,0-12.0488,12.9151-.0675,1.7973-.0664,5.5254t.0664,5.6582q.4643,12.8481,12.0488,12.8486a13.8371,13.8371,0,0,0,7.9893-2.0644q2.9955-2.0625,4.1269-7.1231a2.1029,2.1029,0,0,1,.6-1.1318,1.6148,1.6148,0,0,1,1.0645-.3321h3.3281a1.5452,1.5452,0,0,1,1.0322.3663,1.2026,1.2026,0,0,1,.4327.9648,14.8771,14.8771,0,0,1-8.5206,12.748A21.9963,21.9963,0,0,1,224.57,85.2145Q215.7169,85.2148,211.09,80.3877Z"
      fill="none" stroke="#fff" stroke-width="3.3286" />
  </g>
  <line id="Linie_1" data-name="Linie 1" x1="177.106" y1="3" x2="127.1774" y2="119.5" fill="none" stroke="#fff"
    stroke-width="3.3286" />
  <g id="RM">
    <path
      d="M261.583,84.1162a1.554,1.554,0,0,1-.3994-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.3813,1.3813,0,0,1,1.0654-.4326h16.2433q7.5892,0,11.8828,3.5615t4.294,10.3516a12.3644,12.3644,0,0,1-9.586,12.8486l10.252,17.7744a1.6606,1.6606,0,0,1,.1992.7324,1.365,1.365,0,0,1-1.3311,1.3311h-2.93a2.3932,2.3932,0,0,1-1.5645-.4658,4.3724,4.3724,0,0,1-1.0313-1.3321l-9.7861-17.042h-11.85V83.0175a1.4361,1.4361,0,0,1-.4658,1.0987,1.6041,1.6041,0,0,1-1.1318.4326h-3.1953A1.3782,1.3782,0,0,1,261.583,84.1162ZM278.625,60.25q10.0518,0,10.0527-8.4541T278.625,43.3408H267.4414V60.25Z"
      fill="#fff" />
    <path
      d="M313.075,84.1162a1.4851,1.4851,0,0,1-.4326-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.4639,1.4639,0,0,1,1.1318-.4326h3.1289a1.68,1.68,0,0,1,1.6641.998l14.18,27.2276L347.459,38.9472a1.7119,1.7119,0,0,1,1.5976-.998h3.1289a1.4639,1.4639,0,0,1,1.1319.4326,1.65,1.65,0,0,1,.3994,1.165V83.0175a1.48,1.48,0,0,1-1.4269,1.5313q-.0522.0018-.1044,0H349.124a1.3783,1.3783,0,0,1-1.0654-.4326,1.5536,1.5536,0,0,1-.3994-1.0987V49.9316L336.275,71.9668a2.2218,2.2218,0,0,1-2.13,1.3984h-1.998a2.3059,2.3059,0,0,1-2.13-1.3984L318.7,49.9316V83.0175a1.48,1.48,0,0,1-1.4269,1.5313q-.0522.0018-.1044,0h-2.9951A1.4849,1.4849,0,0,1,313.075,84.1162Z"
      fill="#fff" />
    <path
      d="M261.583,84.1162a1.554,1.554,0,0,1-.3994-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.3813,1.3813,0,0,1,1.0654-.4326h16.2433q7.5892,0,11.8828,3.5615t4.294,10.3516a12.3644,12.3644,0,0,1-9.586,12.8486l10.252,17.7744a1.6606,1.6606,0,0,1,.1992.7324,1.365,1.365,0,0,1-1.3311,1.3311h-2.93a2.3932,2.3932,0,0,1-1.5645-.4658,4.3724,4.3724,0,0,1-1.0313-1.3321l-9.7861-17.042h-11.85V83.0175a1.4361,1.4361,0,0,1-.4658,1.0987,1.6041,1.6041,0,0,1-1.1318.4326h-3.1953A1.3782,1.3782,0,0,1,261.583,84.1162ZM278.625,60.25q10.0518,0,10.0527-8.4541T278.625,43.3408H267.4414V60.25Z"
      fill="none" stroke="#fff" stroke-width="3.3286" />
    <path
      d="M313.075,84.1162a1.4851,1.4851,0,0,1-.4326-1.0987V39.5468a1.65,1.65,0,0,1,.3994-1.165,1.4639,1.4639,0,0,1,1.1318-.4326h3.1289a1.68,1.68,0,0,1,1.6641.998l14.18,27.2276L347.459,38.9472a1.7119,1.7119,0,0,1,1.5976-.998h3.1289a1.4639,1.4639,0,0,1,1.1319.4326,1.65,1.65,0,0,1,.3994,1.165V83.0175a1.48,1.48,0,0,1-1.4269,1.5313q-.0522.0018-.1044,0H349.124a1.3783,1.3783,0,0,1-1.0654-.4326,1.5536,1.5536,0,0,1-.3994-1.0987V49.9316L336.275,71.9668a2.2218,2.2218,0,0,1-2.13,1.3984h-1.998a2.3059,2.3059,0,0,1-2.13-1.3984L318.7,49.9316V83.0175a1.48,1.48,0,0,1-1.4269,1.5313q-.0522.0018-.1044,0h-2.9951A1.4849,1.4849,0,0,1,313.075,84.1162Z"
      fill="none" stroke="#fff" stroke-width="3.3286" />
  </g>
  <path
    d="M77.5441,93.374V54.2106A24.8524,24.8524,0,0,0,52.6917,29.3582H50.78A24.8524,24.8524,0,0,0,25.928,54.2106v11.022A24.8524,24.8524,0,0,0,50.78,90.085h11.237v15.7812H50.6967a40.034,40.034,0,0,1-40.034-40.034V54.6678a40.034,40.034,0,0,1,40.034-40.034h2.2415a40.034,40.034,0,0,1,40.034,40.034V76.5122"
    fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" />
  <path
    d="M62.0174,74.53V55.7112a10.494,10.494,0,0,0-10.494-10.494h-.1157a10.494,10.494,0,0,0-10.494,10.494l.08,8.3838A10.4983,10.4983,0,0,0,48.94,74.2777"
    fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" />
</svg>
`)

export default CrmLogoSmall
