import { Calendar } from '@aposphaere/ui-components'
import React, { useEffect, useRef } from 'react'
import Spinner from '../loader.gif'
import { CalendarDay, CalendarTimeSlot, useCalendarData } from './useCalendarData'
import CalendarWeek from './CalendarWeek'
import { getDay, getWeek, isSameWeek } from 'date-fns'
import { useRoutePlanner } from '../../contexts/routePlannerContext'

type CrmCalendarProps = {
  onTimeSlotClick: (timeslot: CalendarTimeSlot) => void
  onDayClick?: (day: CalendarDay) => void
  selectedTime?: Date
  disablePastSlots?: boolean
  isEditingOfftimes?: boolean
}

const CrmCalendar: React.FunctionComponent<CrmCalendarProps> = ({
  isEditingOfftimes,
  selectedTime,
  disablePastSlots,
  onTimeSlotClick,
  onDayClick,
}) => {
  const { selectedDay } = useRoutePlanner()

  const calenderContainerRef = useRef<HTMLDivElement>(null)
  const { calendarWeeks } = useCalendarData({ disablePastSlots, selectedTime })

  const haveScrolledToDateRef = useRef(false)

  useEffect(() => {
    if (haveScrolledToDateRef.current || !calendarWeeks?.length) {
      return
    }

    function getSectionToScrollInto() {
      const today = new Date()
      const day = getDay(today)
      const week = getWeek(today)

      const daySection = document.getElementById(`week-${week}-day-${day}`)
      const weekSection = document.getElementById(`week-${week}`)

      return daySection || weekSection || null
    }

    if (getSectionToScrollInto()) {
      setTimeout(() => {
        getSectionToScrollInto()?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }, 1000)

      haveScrolledToDateRef.current = true
    }
  }, [calendarWeeks])

  return !calendarWeeks?.length ? (
    <div className="flex self-center rounded-md p-4 bg-white w-fit mx-auto h-750px">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <div className="w-full">
          <img className="mx-auto w-16" src={Spinner} alt="" />
        </div>
        <div className="flex flex-wrap w-fit text-center mx-auto justify-center font-body mt-6 text-base text-blue-400 leading-5">
          {'Wird geladen...'}
        </div>
      </div>
    </div>
  ) : (
    <div ref={calenderContainerRef} className="w-full">
      <Calendar key={`calendar`}>
        {calendarWeeks.map((week, weekNumber) => (
          <React.Fragment key={weekNumber}>
            <div id={`week-${weekNumber}`}></div>
            <CalendarWeek
              days={week.days}
              selectedDayInWeek={selectedDay && isSameWeek(selectedDay, week.date) ? selectedDay : null}
              weekNumber={weekNumber}
              isEditingOfftimes={isEditingOfftimes || false}
              onDayClick={onDayClick}
              onTimeSlotClick={onTimeSlotClick}
            />
          </React.Fragment>
        ))}
      </Calendar>
    </div>
  )
}

export default React.memo(CrmCalendar)
