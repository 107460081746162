import { gql } from 'graphql-request'

export const createAppointment = gql`
  mutation(
    $pharmacy_id: String!
    $quarter_id: String!
    $status_id: String!
    $appointment_type_id: String!
    $creator_id: String!
    $date: DateTimeUtc!
    $note: String
    $order_items: [CreateOrderItemInputs]
    $send_confirmation: Boolean!
    $confirmation_email_address: String
  ) {
    createAppointment(
      pharmacy_id: $pharmacy_id
      quarter_id: $quarter_id
      status_id: $status_id
      appointment_type_id: $appointment_type_id
      creator_id: $creator_id
      date: $date
      note: $note
      last_editor_id: "1"
      order_items: $order_items
      send_confirmation: $send_confirmation
      confirmation_email_address: $confirmation_email_address
    ) {
      id
    }
  }
`
export const updateAppointment = gql`
  mutation(
    $id: ID!
    $pharmacy_id: String!
    $quarter_id: String!
    $status_id: String!
    $appointment_type_id: String!
    $creator_id: String!
    $date: DateTimeUtc!
    $note: String
    $order_items: [CreateOrderItemInputs]
    $cancellation_reason: String
  ) {
    updateAppointment(
      id: $id
      pharmacy_id: $pharmacy_id
      quarter_id: $quarter_id
      status_id: $status_id
      appointment_type_id: $appointment_type_id
      creator_id: $creator_id
      date: $date
      note: $note
      last_editor_id: "1"
      order_items: $order_items
      cancellation_reason: $cancellation_reason
    ) {
      id
    }
  }
`

export const deleteAppointment = gql`
  mutation($id: ID!, $terminationReason: String!) {
    deleteAppointment(id: $id, termination_reason: $terminationReason) {
      id
    }
  }
`
