import React from 'react'

const MapIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Rechteck_189" data-name="Rechteck 189">
      <rect x="68.4299" y="91.3369" width="430.757" height="430.757" rx="50" transform="translate(-115.3346 182.9962) rotate(-30)" fill="none" />
      <rect
        x="70.9299"
        y="93.8369"
        width="425.757"
        height="425.757"
        rx="47.5"
        transform="translate(-115.3346 182.9962) rotate(-30)"
        fill="none"
        stroke="#f8d2bd"
        strokeWidth="5"
      />
    </g>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <g id="_104-Map" data-name=" 104-Map">
          <path id="Pfad_42" data-name="Pfad 42" d="M15,28,8,25V8l7,3" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" />
          <path
            id="Pfad_43"
            data-name="Pfad 43"
            d="M22,8l7,3V28l-7-3"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Linie_85"
            data-name="Linie 85"
            x1="15"
            y1="15"
            x2="15"
            y2="28"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Pfad_44"
            data-name="Pfad 44"
            d="M19,26.5,22,25V8l-7,3"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default MapIcon
