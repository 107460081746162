import React from 'react'

const AddIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.7429" height="22.1377" viewBox="0 0 23.7429 22.1377">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <rect
          x="1"
          y="3.8901"
          width="21.7429"
          height="17.2476"
          rx="2.76"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x1="6.0595"
          y1="5.8849"
          x2="6.0595"
          y2="1.0237"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x1="17.7079"
          y1="5.8612"
          x2="17.7079"
          y2="1"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x1="1"
          y1="10.1145"
          x2="17.6834"
          y2="10.1145"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default AddIcon
