import { gql } from 'graphql-request'

export const createEmployee = gql`
  mutation($pharmacy_id: String!, $first_name: String!, $last_name: String!, $email: String!, $gender: String!, $employee_position_id: String!) {
    createEmployee(
      pharmacy_id: $pharmacy_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      gender: $gender
      employee_position_id: $employee_position_id
    ) {
      id
    }
  }
`
