import React from 'react'

const MaximizeIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <g id="iconfinder_maximize-2_2561250" data-name="iconfinder maximize-2 2561250">
          <path
            id="Pfad_40"
            data-name="Pfad 40"
            d="M21,9h6v6"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Pfad_41"
            data-name="Pfad 41"
            d="M15,27H9V21"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Linie_82"
            data-name="Linie 82"
            x1="27"
            y1="9"
            x2="20"
            y2="16"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Linie_83"
            data-name="Linie 83"
            x1="9"
            y1="27"
            x2="16"
            y2="20"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default MaximizeIcon
