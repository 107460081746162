import { TimeSlot, TimeSlotKind } from '@aposphaere/ui-components'
import React from 'react'
import { CalendarTimeSlot } from './useCalendarData'

const formatAppointmentDate = (date: Date) => `${padDatePart(date.getHours())}:${padDatePart(date.getMinutes())}`

const padDatePart = (h: number) => (h < 10 ? `0${h}` : h)

interface TimeSlotsProps {
  day: Date
  timeSlots: CalendarTimeSlot[]
  onClick: (slot: CalendarTimeSlot) => void
}

const TimeSlots = ({ timeSlots, onClick }: TimeSlotsProps) => (
  <React.Fragment>
    {timeSlots.map((slot) => (
      <TimeSlot
        onClick={(e) => {
          e.stopPropagation() // To avoid triggering the clcik handler on the whole day
          onClick(slot)
        }}
        key={getTimeslotKey(slot)}
        disabled={slot.kind === TimeSlotKind.disabled}
        kind={slot.kind}
        date={slot.date}
      >
        {slot.type === 'appointment' ? formatAppointmentDate(slot.date) : undefined}
      </TimeSlot>
    ))}
  </React.Fragment>
)

function getTimeslotKey(slot: CalendarTimeSlot) {
  switch (slot.type) {
    case 'appointment':
    case 'offtime': {
      return `${slot.type}-${slot.data.id}-${slot.date?.toString() || ''}`
    }
    default: {
      return `${slot.type}-${slot.date?.toString() || ''}`
    }
  }
}

export default React.memo(TimeSlots)
