import { RequestDocument, Variables } from 'graphql-request/dist/types'
import { useEffect, useMemo, useState } from 'react'
import { ErrorKind } from '../graphql'
import { ApoClient } from '../graphql/client'
import { Response } from '../graphql/response'

/**
 * A custom Hook that fetches data from the GraphQL API
 * @param query The provided GraphQL Query
 * @param variables Optional variables corresponding to the Qurey
 * @returns response A Response object provided by CoreKit
 */
export const useQuery = <ModelType>(query: RequestDocument, variables?: Variables, headers?: Headers): Response<ModelType> => {
  const loadingResponse = Response.emptyLoadingResponse<ModelType>()
  const [response, setResponse] = useState<Response<ModelType>>(loadingResponse)

  const client = useMemo(() => {
    const newClient = new ApoClient()
    if (headers) {
      newClient.setHeaders(headers)
    }
    return newClient
  }, [headers])

  useEffect(() => {
    const queryData = async () => {
      try {
        const queryResponse = await client.request<ModelType>(query, variables)
        setResponse(queryResponse)
      } catch ({ errorKind }) {
        console.log('useQuery – Request went wrong')
        if (errorKind === ErrorKind.auth) {
          console.log('useQuery – User is not Authenticated')
        }
      }
    }
    void queryData()
  }, [client, query, variables])
  return response
}
