import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { googleMapsApiKey } from '../../constants'
import { toast, ExpandIcon, IconButton, IconButtonKind, useNavProvider, NavOpened, NavClosed, PlusSignIcon } from '@aposphaere/ui-components'
import CalenderPanelAppointment from '../../components/CalendarPanelAppointment'
import CalenderPanel from '../../components/CalenderPanel'
import PharmacyDataSheetPanel from '../../components/PharmacyDataSheetPanel'
import CustomRouteModal from '../../components/CustomRouteModal'
import Spinner from '../../components/loader.gif'
import MapView from './MapView'
import PharmacyFilterProvider from '../../contexts/filterContext'
import { useNextPossibleDateQuery, usePharmacyQuery } from '../../hooks/graphql'
import { useLocation, useHistory } from 'react-router-dom'
import { useMap } from '../../contexts/mapContext'
import CalendarPanelWrapper from '../../components/CalendarPanelAppointment/CalendarPanelContext'

interface LeftPaneProps {
  onExpand: () => void
  isExpanded: boolean
  isAppointmentDisabled: boolean
  onAppointmentClick: (id?: number) => void
  children?: React.ReactNode
}

const LeftPane = ({ children, isExpanded, onExpand, isAppointmentDisabled, onAppointmentClick }: LeftPaneProps) => {
  const { toggleNav, navExpanded } = useNavProvider()
  return (
    <>
      {isExpanded && (
        <div
          title={isExpanded ? '' : 'maximize calender view'}
          className={isExpanded ? 'relative w-full z-50 min-w-0 max-w-md 2xl:max-w-xl' : 'z-50 absolute top-24 left-1 bg-white '}
        >
          <div className={isExpanded ? 'w-full overflow-auto' : 'hidden'}>{children}</div>
        </div>
      )}
      {!isExpanded && (
        <div className={`z-50 absolute ${!navExpanded ? 'top-30 lg:top-23' : 'top-14 lg:top-7'} left-0 flex bg-white`} title="maximize calender view">
          <div onClick={toggleNav} className="w-12 h-12 flex items-center justify-center">
            <button
              className={`${
                !navExpanded ? 'bg-turquoise-50' : 'bg-blue-600'
              } w-12 h-12 flex rounded-none outline-none active:outline-none items-center justify-center`}
            >
              {navExpanded ? <NavClosed /> : <NavOpened />}
            </button>
          </div>
          <div className="w-12 h-12 flex items-center justify-center">
            <IconButton disabled={isAppointmentDisabled} kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={() => onAppointmentClick()} />
          </div>
          <div className="w-12 h-12 flex items-center justify-center">
            <button className="w-12 h-12 flex items-center justify-center hover:bg-turquoise-50 transform rotate-180" onClick={onExpand}>
              <ExpandIcon />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export type StartPageLocationState =
  | {
      action: 'createAppointment'
      pharmacyId: string
    }
  | {
      action: 'pharmacy-details'
      pharmacyId: string
    }

type SidebarStatus = { isVisible: boolean } & (
  | {
      type: 'calendar'
    }
  | {
      type: 'appointment-creation'
      pharmacyId: number
    }
  | {
      type: 'pharmacy-details'
    }
)

export const StartPage: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
  })

  const history = useHistory()

  const { mapInstance, zoomToLocation } = useMap()
  const [sidebarStatus, setSidebarStatus] = useState<SidebarStatus>({ isVisible: true, type: 'calendar' })
  const hideSidebar = useCallback(() => {
    setSidebarStatus((prev) => ({ ...prev, isVisible: false }))
  }, [])
  const showSidebar = useCallback(() => {
    setSidebarStatus((prev) => ({ ...prev, isVisible: true }))
  }, [])

  const [selectedPharmacyId, setSelectedPharmacyId] = useState<number | undefined>()
  const { data: nextPossibleDate } = useNextPossibleDateQuery(selectedPharmacyId)
  const { state: locationState } = useLocation<StartPageLocationState>()
  const { data: pharmacyOfAppointmentCreation } = usePharmacyQuery(
    sidebarStatus.type === 'appointment-creation' ? sidebarStatus.pharmacyId : undefined,
  )

  const isAppointmentDisabled = useMemo(() => (selectedPharmacyId ? false : true), [selectedPharmacyId])
  const longitude = pharmacyOfAppointmentCreation?.address?.longitude || 0
  const latitude = pharmacyOfAppointmentCreation?.address?.latitude || 0

  const onNewAppointmentClick = useCallback(
    (pharmacyId: number) => {
      if (!pharmacyId) {
        return
      }
      setSidebarStatus({ type: 'appointment-creation', pharmacyId: pharmacyId, isVisible: true })

      if (nextPossibleDate) {
        toast.show({
          headline: 'Terminvorschläge:',
          subline: nextPossibleDate,
          duration: 10000,
        })
      }
    },
    [nextPossibleDate],
  )

  useEffect(() => {
    if (mapInstance && sidebarStatus.type === 'appointment-creation') {
      if (longitude && latitude) {
        const locationObject = {
          longitude,
          latitude,
        }
        zoomToLocation(locationObject)
      }
    }
  }, [sidebarStatus, mapInstance, longitude, latitude, zoomToLocation])

  useEffect(() => {
    if (sidebarStatus.type === 'appointment-creation' && locationState?.pharmacyId === selectedPharmacyId?.toString()) {
      return
    }

    if (locationState?.action === 'createAppointment') {
      setSelectedPharmacyId(Number(locationState.pharmacyId))
      onNewAppointmentClick(Number(locationState.pharmacyId))
      const currentPath = history.location.pathname
      history.replace(currentPath, { action: '', pharmacyId: '' })
    }
  }, [locationState, sidebarStatus.type, onNewAppointmentClick, selectedPharmacyId, history])

  useEffect(() => {
    if (locationState?.action === 'pharmacy-details') {
      if (locationState.pharmacyId) {
        setSelectedPharmacyId(Number(locationState.pharmacyId))
      }

      setSidebarStatus({ isVisible: true, type: 'pharmacy-details' })
      const currentPath = history.location.pathname
      history.replace(currentPath, { action: '', pharmacyId: '' })
    }
  }, [locationState, history])

  const onCloseInfoWindow = useCallback(() => {
    if (sidebarStatus.type === 'pharmacy-details') {
      setSidebarStatus({ isVisible: true, type: 'calendar' })
    }
    setSelectedPharmacyId(undefined)
  }, [sidebarStatus.type])

  // trigger the pharmacy details view
  const onOpenPharmacyDetails = useCallback(() => {
    const currentPath = history.location.pathname
    history.replace(currentPath, { action: 'pharmacy-details' })
    setSidebarStatus({ isVisible: true, type: 'pharmacy-details' })
  }, [history])

  const handleCloseClendarPanel = useCallback(() => {
    const currentPath = history.location.pathname
    history.replace(currentPath, { action: 'calendar' })
    setSidebarStatus({ type: 'calendar', isVisible: true })
  }, [history])

  const handleNewAppointmentClick = useCallback(
    (id?: number) => {
      if (id) {
        onNewAppointmentClick(id)
      } else if (selectedPharmacyId) {
        onNewAppointmentClick(selectedPharmacyId)
      }
    },
    [selectedPharmacyId, onNewAppointmentClick],
  )

  if (!isLoaded) {
    return <SpinnerComponent />
  }

  return (
    <div className="flex w-full bg-transparent z-40  h-full-sidebar">
      <React.Fragment>
        {loadError}
        <LeftPane
          isAppointmentDisabled={isAppointmentDisabled}
          onAppointmentClick={handleNewAppointmentClick}
          isExpanded={sidebarStatus.isVisible}
          onExpand={showSidebar}
        >
          <>
            {sidebarStatus.type === 'calendar' && (
              <CalenderPanel
                onNewAppointmentClick={() => handleNewAppointmentClick()}
                onHide={hideSidebar}
                canCreateAppointment={!!selectedPharmacyId}
                setSelectedPharmacy={setSelectedPharmacyId}
              />
            )}
            {sidebarStatus.type === 'appointment-creation' && (
              <CalendarPanelWrapper>
                <CalenderPanelAppointment pharmacyId={sidebarStatus.pharmacyId} onClose={handleCloseClendarPanel} />
              </CalendarPanelWrapper>
            )}
            {sidebarStatus.type === 'pharmacy-details' && selectedPharmacyId && (
              <PharmacyDataSheetPanel
                setPharmacyId={setSelectedPharmacyId}
                pharmacyId={selectedPharmacyId}
                onClose={() => setSidebarStatus({ isVisible: true, type: 'calendar' })}
                onCreateAppointment={handleNewAppointmentClick}
              />
            )}
          </>
        </LeftPane>
        <PharmacyFilterProvider>
          <MapView
            onCloseInfoWindow={onCloseInfoWindow}
            selectedPharmacyId={selectedPharmacyId}
            onCreateAppointmentClick={handleNewAppointmentClick}
            onOpenPharmacyDetails={onOpenPharmacyDetails}
            setSelectedPharmacy={setSelectedPharmacyId}
          />
        </PharmacyFilterProvider>

        <CustomRouteModal />
      </React.Fragment>
    </div>
  )
}

const SpinnerComponent = () => (
  <div className="h-screen w-screen bg-white z-20 absolute flex items-center justify-center">
    <div className="flex items-center justify-center flex-wrap max-w-48">
      <img className="flex w-24" src={Spinner} alt="" />
      <br />
      <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">{'Daten werden geladen.'}</div>
    </div>
  </div>
)
