import { Pharmacy } from '@aposphaere/core-kit'
import React, { useEffect, useState } from 'react'
import ActionPanel from '../../components/ActionsPanel'
import ActivePharmaciesViewTable from '../../components/ActivePharmaciesViewTable'
import Map, { CustomDirectionsRenderer } from '../../components/Map'
import InfoWindowContainer from '../../components/Map/InfoWindowContainer'
import SimpleGoogleMap from '../../components/Map/SimpleGoogleMap'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { usePharmacyFilterContext } from '../../contexts/filterContext'
import { IMapFilterType } from '../../contexts/reducers/mapFilterReducer'
/*
 * PROPS
 */
interface Props {
  setSelectedPharmacy: (id: number | undefined) => void
  selectedPharmacyId: number | undefined
  onCreateAppointmentClick: (id?: number | undefined) => void
  onOpenPharmacyDetails: () => void
  onCloseInfoWindow: () => void
}
/*
 * COMPONENT
 */
const MapView: React.FC<Props> = ({
  setSelectedPharmacy,
  onCloseInfoWindow,
  onOpenPharmacyDetails,
  onCreateAppointmentClick,
  selectedPharmacyId,
}) => {
  const { isShowRouteWithAllPharmacy, sortedAppointmentsToday, pharmaciesHasAppointmentsToday } = useRoutePlanner()

  const [mode, setMode] = useState<'map' | 'pharmacy-list'>('map')
  const { filteredPharmacies, dispatchFilterState } = usePharmacyFilterContext()
  const [pharmacyArray, setPharmacyArray] = useState<Pharmacy[]>([])

  useEffect(() => {
    if (sortedAppointmentsToday?.length && pharmaciesHasAppointmentsToday?.length && !isShowRouteWithAllPharmacy) {
      dispatchFilterState({ type: IMapFilterType.CLEAR })
      setPharmacyArray(pharmaciesHasAppointmentsToday)
    } else {
      setPharmacyArray(filteredPharmacies)
    }
  }, [
    setSelectedPharmacy,
    filteredPharmacies,
    sortedAppointmentsToday,
    pharmaciesHasAppointmentsToday,
    isShowRouteWithAllPharmacy,
    dispatchFilterState,
  ])

  if (mode === 'map') {
    return (
      <SimpleGoogleMap>
        <Map pharmacies={pharmacyArray} onClick={setSelectedPharmacy}>
          <div className="fixed mt-4 mx-2 right-0 w-1/2 2lg:w-3/4 flex justify-end ">
            <ActionPanel
              setSelectedPharmacy={setSelectedPharmacy}
              viewMode={'map'}
              activateListView={() => setMode('pharmacy-list')}
              activateMapView={() => setMode('map')}
            />
          </div>
          {sortedAppointmentsToday && <CustomDirectionsRenderer sortedAppointmentsToday={sortedAppointmentsToday} />}
          {
            <InfoWindowContainer
              onClose={onCloseInfoWindow}
              selectedPharmacyId={selectedPharmacyId}
              setSelectedPharmacy={setSelectedPharmacy}
              onCreateAppointmentClick={() => onCreateAppointmentClick()}
              onPharmacyDetailClick={onOpenPharmacyDetails}
            />
          }
        </Map>
      </SimpleGoogleMap>
    )
  }

  if (mode === 'pharmacy-list') {
    return (
      <div className={'sticky px-4 top-0 w-full overflow-auto max-h-screen'}>
        <div className="sticky top-0 z-50 mt-4 mb-5 w-full flex justify-end">
          <ActionPanel
            setSelectedPharmacy={setSelectedPharmacy}
            viewMode={'list'}
            activateListView={() => setMode('pharmacy-list')}
            activateMapView={() => setMode('map')}
          />
        </div>
        <ActivePharmaciesViewTable pharmacies={pharmacyArray} onCreateAppointment={onCreateAppointmentClick} />
      </div>
    )
  }
  return null
}

export default MapView
