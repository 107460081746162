import { Project, parseUTC } from '@aposphaere/core-kit'
import { isWithinInterval } from 'date-fns'
export const getCurrentProjects = (activeProjects: Project[] | undefined, selectedDay: Date | undefined) =>
  activeProjects?.filter(
    (each) =>
      selectedDay &&
      isWithinInterval(selectedDay, {
        start: parseUTC(each.start_date),
        end: parseUTC(each.end_date),
      }),
  ) ?? []
