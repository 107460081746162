import React from 'react'

const PhoneSmallIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.089" height="20.1201" viewBox="0 0 20.089 20.1201">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path
          id="Pfad_145"
          data-name="Pfad 145"
          d="M19.0885,14.5661v2.728a1.8181,1.8181,0,0,1-1.81,1.826h-.001a1.3972,1.3972,0,0,1-.171-.007,17.9911,17.9911,0,0,1-7.847-2.791,17.7271,17.7271,0,0,1-5.456-5.456,17.9907,17.9907,0,0,1-2.796-7.884,1.8189,1.8189,0,0,1,1.648-1.975A1.2468,1.2468,0,0,1,2.8175,1h2.727a1.818,1.818,0,0,1,1.819,1.564A11.6623,11.6623,0,0,0,8,5.1191a1.8182,1.8182,0,0,1-.411,1.918l-1.152,1.155a14.547,14.547,0,0,0,5.452,5.456l1.155-1.155a1.818,1.818,0,0,1,1.919-.409,11.6665,11.6665,0,0,0,2.555.636A1.819,1.819,0,0,1,19.0885,14.5661Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default PhoneSmallIcon
