import { gql } from 'graphql-request'

export const getNoteWeekPharmacyClusterAll = gql`
  query getNoteWeekPharmacyClusterAll($pharmacy_cluster_id: String) {
    noteWeekPharmacyClusterAll(pharmacy_cluster_id: $pharmacy_cluster_id) {
      id
      year
      week
      pharmacy_cluster_id
      note
    }
  }
`
