import { createNote, deleteNote, updateNote, useAuth } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, toast, TextAreaInput } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import * as Yup from 'yup'

import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery } from '../../hooks/graphql'
import { useNotesQuery } from '../../hooks/graphql/notes'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type NoteFormValues = {
  pharmacyId?: number
  typeOfNote: string
  note: string
}

export enum NoteOptions {
  PhoneNote = 'Telefonnotiz',
  VisitInfo = 'Besuchsinfo',
  GeneralNote = 'allgemeine Notiz',
  Miscellaneous = 'Sonstiges',
}

type Props = {
  mode: 'create' | 'delete' | 'update'
  noteId?: number
  pharmacyId?: number
}

const NoteModal: React.FC<Props> = ({ mode, noteId, pharmacyId }) => {
  const auth = useAuth()

  const { data: notes, refetch: refetchNotes } = useNotesQuery()
  const { closeModal } = useModal()

  const note = useMemo(() => {
    if (!notes?.length || !noteId) {
      return null
    }

    return notes.find((_note) => String(_note.id) === String(noteId))
  }, [notes, noteId])

  const { data: pharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId || note?.pharmacy?.id)

  const deleteNoteMutation = useAuthenticatedMutation(deleteNote)
  const updateNoteMutation = useAuthenticatedMutation(updateNote)
  const createNoteMutation = useAuthenticatedMutation(createNote)

  const getMutation = (values: NoteFormValues) => {
    const defaultVariables = {
      pharmacy_id: values.pharmacyId,
      user_id: auth.user?.id,
      note_type: values.typeOfNote,
      content: values.note,
    }

    if (mode === 'create') {
      return () => createNoteMutation(defaultVariables)
    }
    if (mode === 'delete' && noteId) {
      return () => deleteNoteMutation({ id: noteId })
    }
    if (mode === 'update' && noteId) {
      return () =>
        updateNoteMutation({
          ...defaultVariables,
          id: noteId,
        })
    }
  }

  const initialFormValues: NoteFormValues = {
    pharmacyId: pharmacy ? pharmacy.id : undefined,
    typeOfNote: note?.note_type || NoteOptions.PhoneNote,
    note: note?.content || '',
  }

  const NoteValidationScheme = Yup.object().shape({
    typeOfNote: Yup.string().required('Pflichtfeld'),
    note: mode === 'delete' ? Yup.string().notRequired() : Yup.string().min(3, 'Zu kurz').required('Pflichtfeld'),
  })

  const onFormSubmit = async (values: NoteFormValues) => {
    const mutation = getMutation(values)

    if (!mutation) {
      return
    }

    const noteResponse = await mutation()
    if (noteResponse.errors !== undefined) {
      alert(noteResponse.errors)
      return
    }
    const { notificationText } = getTextObject()
    try {
      await refetchPharmacy()
      await refetchNotes()
      toast.show({
        headline: notificationText,
        type: 'success',
      })
    } catch {
      toast.show({
        headline: 'Daten konnten nicht aktualisiert werden',
        type: 'error',
      })
    }

    closeModal()
  }

  const getTextObject = () => {
    if (mode === 'delete') {
      return {
        titleModalText: 'Notiz löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Notiz erfolgreich gelöscht',
      }
    }
    if (mode === 'update') {
      return {
        titleModalText: 'Notiz speichern',
        submitButtonText: 'Notiz speichern',
        notificationText: 'Notiz erfolgreich gespeichert',
      }
    }
    return {
      titleModalText: 'Notiz anlegen',
      submitButtonText: 'Notiz anlegen',
      notificationText: 'Notiz erfolgreich erstellt',
    }
  }

  const isLoadingData = (mode === 'delete' || mode === 'update') && !note

  return (
    <Modal kind={ModalKind.sm} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      {isLoadingData ? (
        <div className="min-h-150px">
          <h1>Laden...</h1>
        </div>
      ) : (
        <Formik key="note-creation-form" initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={NoteValidationScheme}>
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <div className="flex flex-wrap w-full">
              <div className="flex flex-wrap w-full">
                <div className="w-full p-4">
                  <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <InputLabel>{'Notizart:'}</InputLabel>
                      <div className="w-full mt-1 rounded-md">
                        <select
                          disabled={mode === 'delete'}
                          defaultValue={values.typeOfNote}
                          className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                          onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('typeOfNote', event.currentTarget.value)}
                        >
                          <option>{NoteOptions.VisitInfo}</option>
                          <option>{NoteOptions.PhoneNote}</option>
                          <option>{NoteOptions.GeneralNote}</option>
                          <option>{NoteOptions.Miscellaneous}</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <InputLabel>{'Notiz:'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <TextAreaInput
                          disabled={mode === 'delete'}
                          initialValue={values.note}
                          placeholder=""
                          onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                        />
                        {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                  {'Abbrechen'}
                </Button>
                <Button kind={mode === 'delete' ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                  {getTextObject().submitButtonText}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default NoteModal
