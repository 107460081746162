import { Pharmacy } from '@aposphaere/core-kit'
import Table from 'rc-table'
import React from 'react'
import { determineMapIcon } from '../Map'
import QuickActionsInTable from '../AddressesTable/QuickActionsInTable'
import { useModal, ModalKind } from '../../contexts/modalContext'
import Spinner from '../loader.gif'
import { determineIsVisit } from '../Map/utils'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import { useAppointmentTypesQuery, useStatusesQuery } from '../../hooks/graphql'

interface Props {
  onCreateAppointment: (id?: number) => void
  pharmacies: Pharmacy[]
}

const ActivePharmaciesViewTable = ({ onCreateAppointment, pharmacies }: Props) => {
  const { selectedDay, sortedAppointmentsToday } = useRoutePlanner()
  const { data: statuses } = useStatusesQuery()
  const appointmentTypesQuery = useAppointmentTypesQuery()

  const titleClassName = 'h-11 pb-2 pr-6 py-3 text-gray-600 text-left text-xs font-medium uppercase tracking-wider font-body'
  const { openModal } = useModal()
  const columns = [
    {
      title: <div className={`${titleClassName} pl-2`}>{'Apotheke'}</div>,
      width: '65%',
      dataIndex: 'id',
      key: 'data',
      render: (value: string, pharmacy: Pharmacy) => {
        const isVisit = determineIsVisit(sortedAppointmentsToday, {
          statuses: statuses?.forAppointments ?? [],
          appointmentTypes: appointmentTypesQuery.data?.appointmentTypes || [],
        })
        return (
          <div className=" pr-1 py-4 whitespace-nowrap pl-2 w-18 flex items-center">
            <div className="w-18 pr-2">
              <img src={determineMapIcon({ pharmacy, sortedAppointmentsToday, selectedDay, isVisit })} alt="mapMarker" />
            </div>
            <div className="w-full">{`${pharmacy.name || ''} - ${pharmacy.address.address_name || ''}, ${pharmacy.address.zipcode || ''} ${
              pharmacy.address.city || ''
            }`}</div>
          </div>
        )
      },
    },
    {
      title: <div className={`${titleClassName} hidden xl:flex`}>{'Tel.'}</div>,
      width: '10%',
      dataIndex: 'phone',
      key: 'pharmacyName',
      render: (value: string) => <div className={'xl:pr-4 py-2 xl:py-4 whitespace-nowrap hidden xl:flex'}>{value}</div>,
    },
    {
      title: <div className={titleClassName}>{'Quickactions'}</div>,
      width: '25%',
      key: 'quickactions',
      render: (value: undefined, record: Pharmacy) => (
        <QuickActionsInTable
          pharmacy={record}
          onCreateAppointment={(id) => onCreateAppointment(id)}
          onCreateNote={() => openModal({ kind: ModalKind.NoteCreate, pharmacyId: record.id })}
          onCreateReminder={() => openModal({ kind: ModalKind.ReminderCreate, pharmacyId: record.id })}
          onDeletePharmacy={() => openModal({ kind: ModalKind.PharmacyDeactivate, id: record.id, status: record.pharmacy_status })}
        />
      ),
    },
  ]

  return pharmacies?.length ? (
    <div className="w-full border border-gray-400 sm:rounded-lg relative">
      <div className="absolute h-11 top-0 border-b border-gray-400 w-full" />
      <Table
        className="w-full"
        columns={columns}
        data={pharmacies}
        rowClassName={(record, index: number) =>
          `${
            index !== 0 ? 'border-t border-gray-400' : ''
          } text-base font-body hover:text-blue-700 hover:border-white hover:border-b hover:shadow-insettable hover:bg-gray-100`
        }
        tableLayout={'auto'}
        rowKey={(record: Pharmacy) => `${record.id}`}
      />
    </div>
  ) : (
    <div className="w-full h-4/5 bg-white relative flex items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )
}

export default ActivePharmaciesViewTable
