import { gql } from 'graphql-request'
import { PharmacyCluster } from '../../models'

export type UserPharmacyClustersResponse = {
  me: {
    pharmacy_clusters: PharmacyCluster[]
  }
}

export const getUserPharmacyClusters = gql`
  query getPharmacyClusters {
    me {
      pharmacy_clusters {
        name
        id
        pharmacy_clusterscode
        agent {
          id
          name
          address {
            address_name
            city
            zipcode
            longitude
            latitude
            federal_state {
              name
              holidays {
                date
                label
              }
            }
          }
        }
      }
    }
  }
`
