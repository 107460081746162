import React from 'react'

const DownloadIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Gruppe_170" data-name="Gruppe 170">
      <line
        id="Linie_100"
        data-name="Linie 100"
        x1="18.0182"
        y1="9"
        x2="18.0182"
        y2="19.2059"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_101"
        data-name="Linie 101"
        x1="13"
        y1="17"
        x2="17.9599"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_104"
        data-name="Linie 104"
        x1="9.2792"
        y1="26"
        x2="26.7908"
        y2="26"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_276"
        data-name="Linie 276"
        x1="23"
        y1="17"
        x2="18.0401"
        y2="21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default DownloadIcon
