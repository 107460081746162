import { gql } from 'graphql-request'

export const allTasks = gql`
  query getTasks($userId: ID!) {
    tasksUser(user_id: $userId) {
      id
      pharmacy {
        contact_person
        okid
        id
        name
        email
        phone
        pharmacy_status
        address {
          city
          zipcode
          address_name
          longitude
          latitude
        }
      }
      user {
        id
        name
      }
      note
      until
      state
      contact_person
      created_at
    }
  }
`

export const getTasks = gql`
  query getTasks {
    tasks {
      id
      pharmacy {
        id
        contact_person
        okid
        pharmacy_cluster {
          id
        }
        name
        email
        phone
        pharmacy_status
        address {
          city
          zipcode
          address_name
          longitude
          latitude
        }
      }
      user {
        id
        name
      }
      note
      until
      state
      contact_person
      created_at
    }
  }
`
