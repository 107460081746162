import React, { FunctionComponent } from 'react'
import IconButton, { IconButtonKind } from '../IconButton'
import { BackIcon, CloseIcon } from '../Icons'

export enum ModalKind {
  sm = 'w-full max-w-570px h-fit max-h-650px',
  ms = 'w-full max-w-990px h-fit max-h-690px',
  md = 'w-full max-w-1150px h-5/6 max-h-750px',
  xl = 'w-full max-w-1700px h-80vh',
}

export type ModalProps = {
  kind: ModalKind
  children?: React.ReactNode
  title: string
  headerBackButton?: boolean
  headerAdditionalButtons?: React.ReactNode
  noPadding?: boolean
  onClose?: () => void
  onBack?: () => void
}

const Modal: FunctionComponent<ModalProps> = ({
  kind = ModalKind.md,
  children,
  title,
  headerAdditionalButtons,
  headerBackButton,
  noPadding,
  onClose,
  onBack,
}) => {
  const showBackButton = headerBackButton ? 'flex' : 'hidden'
  const textColor = headerBackButton ? 'text-gray-700' : 'text-blue-700'
  const paddingStyle = noPadding ? 'px-0 xl:px-0 pt-0' : 'px-4 xl:px-6 pt-1'

  return (
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-999">
      <div className={`bg-white mx-auto rounded-lg shadow-xl-blur z-50 overflow-y-auto ${kind}`}>
        <div className="text-left h-fit">
          <div className="flex justify-between items-center pb-2 pt-3 xl:pb-3 xl:pt-4 pl-6 pr-4 xl:pl-8 xl:pr-6 sticky top-0 z-50 bg-white border-solid border-b border-gray-400">
            <div className="flex items-center">
              {onBack ? (
                <div className={`${showBackButton} -ml-1 mr-2`}>
                  <IconButton kind={IconButtonKind.default} icon={<BackIcon />} onClick={onBack} />
                </div>
              ) : null}
              <p className={`text-xl font-medium font-body ${textColor} pl-2`}>{title}</p>
            </div>
            {onClose ? (
              <div className="flex cursor-pointer z-50 text-gray-700 -mr-2" data-testid="close-button">
                {headerAdditionalButtons}
                <IconButton kind={IconButtonKind.default} onClick={onClose} icon={<CloseIcon />} />
              </div>
            ) : null}
          </div>
          <div className={`flex w-full flex-wrap ${paddingStyle} h-fit`}>{children}</div>
        </div>
      </div>
      <div className="modal-overlay absolute w-full h-full bg-gray-700 opacity-75 z-0" />
    </div>
  )
}

export default Modal
