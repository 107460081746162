export interface Statuses {
  statuses: Status[]
}

export interface StatusResponse {
  status: Status
}

export enum StatusFor {
  Appointments = 'APPOINTMENTS',
  Projects = 'PROJECTS',
}

export interface Status {
  id: number
  label?: string
  label_short: string
  is_active: boolean
  for: StatusFor
}
