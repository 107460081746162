import React from 'react'

const NoteAttentionIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <line x1="18" y1="13" x2="18" y2="19" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="18" y1="23" x2="18" y2="23" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.6" />
    <path d="M18,28a10,10,0,1,1,7.6175-3.521" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export default NoteAttentionIcon
