import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  toast,
  TextAreaInput,
  TextInput,
} from '@aposphaere/ui-components'

import { createNote, useAuth, PharmacyStatus, updateReminder, ReminderState, convertToUTCForBackend } from '@aposphaere/core-kit'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import { GENERIC_ERROR_MESSAGE } from '../../constants'
import { usePharmacyQuery, useTasksQuery, useUpdatePharmacyStatusMutation } from '../../hooks/graphql'
import { useModal } from '../../contexts/modalContext'

export type DeactivatePharmacyVariables = {
  pharmacyId?: number
  reason?: string
  pharmacyStatus?: PharmacyStatus
}
type DeactivatePharmacyFormValues = DeactivatePharmacyVariables & {
  note: string
  other_reason?: string
}

export enum Reason {
  Closed = 'Apotheke dauerhaft geschlossen',
  NoTraining = 'Apotheke will keine Inhouse-Schulungen',
  others = 'Andere',
}

interface Props {
  id: number
  status: PharmacyStatus | undefined
}

const DeactivatePharmacy: React.FC<Props> = ({ id, status }) => {
  const auth = useAuth()
  const { closeModal } = useModal()
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false)

  const { data: reminders } = useTasksQuery()

  const { data: pharmacy, refetch } = usePharmacyQuery(id)

  const createNoteMutation = useAuthenticatedMutation(createNote)
  const { mutate: mutatePharmacyStatus } = useUpdatePharmacyStatusMutation()
  const updateReminderMutation = useAuthenticatedMutation(updateReminder)

  const intialFormValues: DeactivatePharmacyFormValues = {
    pharmacyId: id,
    note: '',
    pharmacyStatus: status === PharmacyStatus.Active ? PharmacyStatus.Inactive : PharmacyStatus.Active,
    reason: pharmacy?.reason_for_deactivation || Reason.Closed,
  }

  const PharmacyValidationScheme = Yup.object().shape({
    other_reason: isOtherReason ? Yup.string().required() : Yup.string().nullable(),
    note: Yup.string().min(3, 'Zu kurz'),
  })

  const deactivateReminders = async () => {
    if (!reminders?.length) {
      return
    }
    const remindersForActivePharmacy = reminders.filter(({ pharmacy: { id: pharmacyId } }) => pharmacyId === pharmacy?.id)

    if (remindersForActivePharmacy.length) {
      await Promise.allSettled(
        remindersForActivePharmacy.map(async (reminder) => {
          const variables = {
            id: reminder.id,
            pharmacy_id: reminder?.pharmacy.id,
            user_id: auth ? auth.user?.id : undefined,
            state: ReminderState.Inactive,
            contact_person: reminder?.contact_person || '',
            until: convertToUTCForBackend(reminder?.until?.toString().replace(/\s/, 'T')),
            note: reminder?.note || '',
          }
          return updateReminderMutation(variables).catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e)
          })
        }),
      )
    }
  }

  const onFormSubmit = async (values: DeactivatePharmacyFormValues) => {
    const noteVariables = {
      pharmacy_id: values.pharmacyId,
      user_id: auth.user?.id,
      note_type: 'deactivation/activation',
      title: values.pharmacyStatus,
      content: values.note,
    }

    const pharmacyVariables: DeactivatePharmacyVariables = {
      pharmacyId: pharmacy?.id,
      pharmacyStatus: values.pharmacyStatus,
      reason: values.reason === Reason.others ? values.other_reason : values.reason,
    }

    if (noteVariables.content.length) {
      const responseNote = await createNoteMutation(noteVariables)
      if (responseNote.errors !== undefined) {
        // eslint-disable-next-line no-alert
        alert(responseNote.errors)
        return
      }
    }

    mutatePharmacyStatus(pharmacyVariables)
    if (values.pharmacyStatus === PharmacyStatus.Inactive) {
      await deactivateReminders()
    }
    await refetch()
    try {
      toast.show({
        headline:
          values.pharmacyStatus === PharmacyStatus.Active ? 'Die Apotheke wurde erfolgreich aktiviert' : 'Die Apotheke wurde erfolgreich deaktiviert',
        type: 'success',
      })
    } catch {
      toast.show({
        headline: GENERIC_ERROR_MESSAGE,
        type: 'error',
      })
    }

    closeModal()
  }

  return (
    <Modal
      kind={ModalKind.sm}
      title={pharmacy?.pharmacy_status === PharmacyStatus.Inactive ? 'Standort aktivieren' : 'Standort deaktivieren'}
      onClose={closeModal}
      onBack={() => null}
    >
      <Formik key="pharmacy-deactivate-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={PharmacyValidationScheme}>
        {({ errors, touched, values, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputLabel>{'Apothekenstatus:'}</InputLabel>
                  <div className="mt-1 rounded-md">
                    <select
                      defaultValue={values.pharmacyStatus}
                      onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                        if (isOtherReason && event.currentTarget.value === PharmacyStatus.Active) {
                          setIsOtherReason(false)
                        }
                        setFieldValue('pharmacyStatus', event.currentTarget.value)
                      }}
                      className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                    >
                      <option value={PharmacyStatus.Active}>Aktiv</option>
                      <option value={PharmacyStatus.Inactive}>Inaktiv</option>
                    </select>
                  </div>
                </div>
                {values.pharmacyStatus === PharmacyStatus.Inactive ? (
                  <div className="sm:col-span-6">
                    <InputLabel>{'Grund für die Deaktivierung:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        defaultValue={values.reason}
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                          if (event.currentTarget.value === Reason.others) {
                            setIsOtherReason(true)
                          } else {
                            setIsOtherReason(false)
                          }
                          setFieldValue('reason', event.currentTarget.value)
                        }}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                      >
                        <>
                          <option>{Reason.Closed}</option>
                          <option>{Reason.NoTraining}</option>
                          <option>{Reason.others}</option>
                        </>
                      </select>
                    </div>
                  </div>
                ) : null}
                {values.reason === Reason.others ? (
                  <div className="sm:col-span-6">
                    <InputLabel>{'anderer Grund:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('other_reason', event.currentTarget.value)}
                        type="text"
                        value={values.other_reason || ''}
                      />
                      {values.reason === 'Andere' && errors.other_reason && touched.other_reason ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.other_reason}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="sm:col-span-6">
                  <InputLabel>{'Kommentar zur Deaktivierung:'}</InputLabel>
                  <div className="mt-1 rounded-md">
                    <TextAreaInput
                      onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                      placeholder=""
                    />
                    {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting || (isOtherReason && !values.other_reason)}
                kind={values.pharmacyStatus === PharmacyStatus.Inactive ? ButtonKind.danger : ButtonKind.primary}
              >
                {values.pharmacyStatus === PharmacyStatus.Inactive ? 'Deaktivierung bestätigen' : 'Aktivierung bestätigen'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default DeactivatePharmacy
