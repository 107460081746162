import { CSSTransition } from 'react-transition-group'

import React, { useRef } from 'react'

const duration = 200

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
}

function Fade({ children, inProp }: { children: React.ReactNode; inProp: boolean | undefined }) {
  const nodeRef = useRef<HTMLDivElement | null>(null)
  return (
    <CSSTransition nodeRef={nodeRef} in={inProp} unmountOnExit timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </CSSTransition>
  )
}

export default Fade
