import { gql } from 'graphql-request'

export const createNoteForWeekPharmacyCluster = gql`
  mutation($year: String!, $week: String!, $pharmacy_cluster_id: String!, $note: String!) {
    createNoteWeekPharmacyCluster(year: $year, week: $week, pharmacy_cluster_id: $pharmacy_cluster_id, note: $note) {
      id
      note
    }
  }
`

export const updateNoteWeekForPharmacyCluster = gql`
  mutation($id: ID!, $note: String) {
    updateNoteWeekPharmacyCluster(id: $id, note: $note) {
      id
    }
  }
`

export const deleteNoteWeekForPharmacyCluster = gql`
  mutation($id: ID!) {
    deleteNoteWeekPharmacyCluster(id: $id) {
      id
    }
  }
`
