import React, { FunctionComponent } from 'react'
import { DropdownArrowIcon } from '../Icons'

export enum IconButtonKind {
  default = 'h-fit items-center px-0.5 py-0.5 rounded-md text-gray-700 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150',
  custom = '',
}

export type IconButtonProps = {
  kind: IconButtonKind
  icon: React.ReactNode
  additionalCss?: string
  selected?: boolean
  selectedStyle?: string
  isExpandable?: boolean
  disabled?: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const IconButton: FunctionComponent<IconButtonProps> = ({
  icon,
  kind = IconButtonKind.default,
  additionalCss,
  selected,
  selectedStyle,
  isExpandable,
  disabled,
  onClick,
}) => {
  const disabledClass = disabled ? 'opacity-50' : ''
  const defaultSelectedStyle = 'text-blue-600'
  const handleOnclick = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!onClick) {
      return
    }
    onClick(event)
  }

  if (isExpandable) {
    return (
      <div
        className={`flex items-center ${
          selected === true
            ? 'z-50 -mb-1 -mt-1 box-content bg-white border-l border-r order-solid border-gray-400 h-full shadow-whitebborder shadow-whitelborder shadow-whiterborder'
            : ''
        } `}
      >
        <span className="inline-flex rounded-md">
          <button
            type="button"
            onClick={handleOnclick}
            disabled={disabled}
            className={` flex items-center ${kind} ${selected === true && !selectedStyle ? defaultSelectedStyle : ''} ${
              selected === true && selectedStyle ? selectedStyle : ''
            } ${disabledClass} ${additionalCss}`}
          >
            {icon}
            <div className="-ml-1 mr-2">
              <DropdownArrowIcon />
            </div>
          </button>
        </span>
      </div>
    )
  } else {
    return (
      <span className="inline-flex rounded-md">
        <button
          type="button"
          disabled={disabled}
          onClick={onClick}
          className={` ${kind} ${
            selected === true && !selectedStyle ? defaultSelectedStyle : selected ? selectedStyle : ''
          } ${disabledClass} ${additionalCss}`}
        >
          {icon}
        </button>
      </span>
    )
  }
}

export default IconButton
