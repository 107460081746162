import React, { FunctionComponent } from 'react'

export enum BadgeKind {
  primary = 'ml-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-600 text-white',
  active = 'ml-1 inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-green-600 text-white',
  secondary = 'ml-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-700 text-white',
  disabled = 'ml-1 inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-red-600 text-white',
}

export type BadgeProps = {
  kind: BadgeKind
  children?: React.ReactNode
}

const Badge: FunctionComponent<BadgeProps> = ({ kind = BadgeKind.primary, children }) => <span className={`${kind}`}>{children}</span>
export default Badge
