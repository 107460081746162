import { gql } from 'graphql-request'

export const allAppointmentTypes = gql`
  {
    appointmentTypes {
      id
      label
      label_short
    }
  }
`
