import React from 'react'
import { PTA } from '@aposphaere/core-kit'
import { ApoCampusIcon, EditIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'

interface IPersonenProps {
  employee: PTA
  onOpenModal: () => void
  canEditEmployee: boolean
}

const PersonenCardItem: React.FunctionComponent<IPersonenProps> = ({ employee, onOpenModal, canEditEmployee }) => {
  const name = [employee.first_name, employee.last_name].join(' ')
  const editModalOpen = () => {
    onOpenModal()
  }
  return (
    <li>
      <div className="flex items-center 2xl:px-4 px-2 2xl:py-4 py-2">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div className="flex items-center">
                <p className="font-medium truncate">{name}</p>
                {employee.is_campus_user && (
                  <div className="text-blue-700">
                    <ApoCampusIcon />
                  </div>
                )}
              </div>
              <p className="mt-2 flex items-center text-sm text-gray-800">
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="truncate">{employee.email}</span>
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="mt-2 flex items-center text-gray-500">
                  <svg
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Completed trainings
                </p>
                <div className="flex flex-wrap ">
                  {employee.completed_trainings?.map((training, index) => {
                    const trainingText = employee.completed_trainings && index !== employee.completed_trainings.length - 1 ? `${training},` : training
                    return (
                      <span key={`${index}-${training}`} className="test-sm py-1 mr-1">
                        {trainingText}
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{canEditEmployee && <IconButton icon={<EditIcon />} onClick={editModalOpen} kind={IconButtonKind.default} />}</div>
      </div>
    </li>
  )
}

export default PersonenCardItem
