import React from 'react'
import { useCrmContext } from '../../contexts/crmContext'
import { useQuartersQuery } from '../../hooks/graphql'

const QuarterSwitcher: React.FC = () => {
  const { activeQuarter, setActiveQuarterId } = useCrmContext()
  const quarterQuery = useQuartersQuery()
  return (
    <select
      value={activeQuarter?.id}
      disabled={quarterQuery.isLoading}
      onChange={(event) => setActiveQuarterId(event.currentTarget.value)}
      className="w-full form-select font-body h-10 text-blue-800 bg-blue-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-blue-700 focus:border-blue-400"
    >
      {quarterQuery.isLoading ? <option>...</option> : null}
      {quarterQuery.data?.map((quarter) => (
        <option key={quarter.id} value={quarter.id}>
          {quarter.label}
        </option>
      ))}
    </select>
  )
}

export default QuarterSwitcher
