import { Badge, BadgeKind, NavBar, TabBar, TabBarItem, TabBarItemKind, useNavProvider } from '@aposphaere/ui-components'
import React from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import PharmacyClusterSwitcher from '../components/PharmacyClusterSwitcher'
import QuarterSwitcher from '../components/QuarterSwitcher'
import SignOutButton from '../components/SignOutButton'
import { ModalKind, useModal } from '../contexts/modalContext'
import { useTasksQuery } from '../hooks/graphql'
import { CrmLogo, CrmLogoSmall } from '../Icons'
import { ADDRESSES_PAGE_ROUTE, REMINDERS_PAGE_ROUTE, START_PAGE_ROUTE, OVERVIEW_PAGE_ROUTE } from '../routes'

const MainLayout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const history = useHistory()
  const { openModal } = useModal()
  const { data } = useTasksQuery()
  const { navExpanded } = useNavProvider()
  const reminders = data || []

  return (
    <div className="flex z-50 flex-wrap font-body">
      <>
        <div className="w-screen relative z-50 h-14 lg:h-8 py-2 px-2 text-center bg-gray-200 flex justify-center align-middle">
          <div className="text-gray-700 font-medium text-base">
            Sie arbeiten auf einer <strong>Testumgebung</strong>. Wenn Ihnen Fehler auffallen oder Verbesserungswünsche bestehen, dann tragen Sie
            diese
            <a className="text-bold underline" href="https://forms.gle/jeyy7xEu5iSnpUAg9" target="_blank" rel="noopener noreferrer">
              hier
            </a>
            ein.
          </div>
        </div>
        <div className={navExpanded ? 'hidden' : 'w-full'}>
          <NavBar logoUrl={CrmLogo} logoUrlSmall={CrmLogoSmall} logoAlt="CRM Logo">
            <TabBar>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === OVERVIEW_PAGE_ROUTE}
                onClick={() => history.push(OVERVIEW_PAGE_ROUTE)}
              >
                Region
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === START_PAGE_ROUTE}
                onClick={() => history.push(START_PAGE_ROUTE)}
              >
                Planung
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === ADDRESSES_PAGE_ROUTE}
                onClick={() => history.push(ADDRESSES_PAGE_ROUTE)}
              >
                Adressen
              </TabBarItem>

              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === REMINDERS_PAGE_ROUTE}
                onClick={() => history.push(REMINDERS_PAGE_ROUTE)}
              >
                <span>
                  {'Wiedervorlagen'}
                  {reminders.length ? <Badge kind={BadgeKind.primary}>{reminders.length}</Badge> : null}
                </span>
              </TabBarItem>
              <TabBarItem kind={TabBarItemKind.primary} selected={false} onClick={() => openModal({ kind: ModalKind.Export })}>
                {'Export'}
              </TabBarItem>
            </TabBar>

            <div className="flex items-center">
              <div className="flex space-x-2 xl:space-x-4">
                <div className="w-32 lg:w-48 2lg:w-64">
                  <div className="w-full" />
                  <PharmacyClusterSwitcher />
                </div>
                <div>
                  <QuarterSwitcher />
                </div>
              </div>
              <div className="border-l border-white border-solid w-px h-10 ml-2 mr-2 xl:ml-8 xl:mr-8" />
              <div className="flex items-center text-white">
                <SignOutButton />
              </div>
            </div>
          </NavBar>
        </div>
      </>
      {children}
    </div>
  )
}

export default withRouter(MainLayout)
