import React from 'react'

const FilterIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Rechteck_189" data-name="Rechteck 189">
      <rect x="23.4299" y="91.3369" width="430.757" height="430.757" rx="50" transform="translate(-121.3634 160.4962) rotate(-30)" fill="none" />
      <rect
        x="25.9299"
        y="93.8369"
        width="425.757"
        height="425.757"
        rx="47.5"
        transform="translate(-121.3634 160.4962) rotate(-30)"
        fill="none"
        stroke="currentColor"
        strokeWidth="5"
      />
    </g>
    <polyline
      points="20.664 17.955 27.9 13.224 27.9 8.909 8 8.909 8 13.224 15.236 17.955 15.236 27 20.664 24.952"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default FilterIcon
