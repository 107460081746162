import { useQuery } from 'react-query'
import { activeProjects, allProjects, Project, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'

export const useProjectsQuery = (options?: QueryOptions<Project[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery('projects', async () => (await gqlClient.request<{ projects: Project[] }>(allProjects))?.projects, {
    enabled: !!auth.user,
    staleTime: Infinity,
    ...options,
  })
}

export const useActiveProjectsQuery = (options?: QueryOptions<Project[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery('active-projects', async () => (await gqlClient.request<{ activeProjects: Project[] }>(activeProjects))?.activeProjects, {
    enabled: !!auth.user,
    staleTime: Infinity,
    ...options,
  })
}
