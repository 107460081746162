import { Button, ButtonKind, InputLabel, Modal, ModalKind, TextAreaInput } from '@aposphaere/ui-components'
import { useFormik } from 'formik'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirmReason: (reason: string) => void
}

const TerminationReasonModal = ({ onClose, isOpen, onConfirmReason }: Props) => {
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      deletion_reason: '',
    },
    onSubmit: () => {
      onConfirmReason(values.deletion_reason)
    },
  })

  const handleCloseModal = () => {
    onClose()
  }

  return isOpen ? (
    <Modal kind={ModalKind.sm} title={'Wählen Sie einen Beendigungsgrund'} onClose={handleCloseModal} onBack={handleCloseModal}>
      <div className="flex flex-col flex-grow">
        <div className="my-2">
          <InputLabel htmlFor="terminationReason">{'Beendigungsgrund:'}</InputLabel>
          <TextAreaInput name="deletion_reason" initialValue={values.deletion_reason} onChange={handleChange} placeholder="" />
        </div>
        <div className="flex justify-end mt-3 mb-5">
          <Button kind={ButtonKind.danger} disabled={!values.deletion_reason} onClick={handleSubmit}>
            {'Termin löschen'}
          </Button>
        </div>
      </div>
    </Modal>
  ) : null
}

export default TerminationReasonModal
