import React, { createContext } from 'react'

interface IViewPort {
  width?: number
  height?: number
}
export const ViewportContext = createContext<IViewPort | null>(null)

const ViewportProvider: React.FC = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>
}

export default ViewportProvider
