import React, { useCallback } from 'react'

export enum IFilterByOption {
  AND = 'and',
  OR = 'or',
}
interface ISearchInputFilter {
  filterByProjectOption: IFilterByOption
  setFilterByProjectOption: (value: IFilterByOption) => void
}

const InputSearchFilter: React.FC<ISearchInputFilter> = ({ children, setFilterByProjectOption, filterByProjectOption }) => {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterByProjectOption(e.target.value as IFilterByOption)
    },
    [setFilterByProjectOption],
  )

  const radioStyle =
    'flex self-center appearance-none h-4 w-4 mr-1.5 border border-gray-400 rounded-full cursor-pointer checked:bg-blue-600 checked:border-transparent focus:outline-none'

  return (
    <div className="flex-1 p-4 text-base border-r border-gray-400 border-solid">
      <div className="flex items-center flex-wrap">
        <span className="flex font-bold text-blue-600 mr-4">Ansicht Filtern:</span>
        <fieldset className="flex">
          <label className="flex self-center text-gray-700 text-base cursor-pointer mr-2">
            <input
              className={radioStyle}
              onChange={handleInputChange}
              type="radio"
              id="und"
              name="filter"
              value={IFilterByOption.AND}
              checked={filterByProjectOption === IFilterByOption.AND}
            />
            UND
          </label>
          <label className="flex self-center text-gray-700 text-base cursor-pointer">
            <input
              className={radioStyle}
              onChange={handleInputChange}
              type="radio"
              id="oder"
              name="filter"
              value={IFilterByOption.OR}
              checked={filterByProjectOption === IFilterByOption.OR}
            />
            ODER
          </label>
        </fieldset>
      </div>
      <div className="mt-4 flex flex-1">{children}</div>
    </div>
  )
}

export default InputSearchFilter
