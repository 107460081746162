import React from 'react'

const DeleteIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <path
      d="M21.2359,28H14.7421a2.4143,2.4143,0,0,1-2.4061-2.1784L11,12H24.978L23.642,25.8216A2.4143,2.4143,0,0,1,21.2359,28Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="20" y1="16" x2="20" y2="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="16" y1="16" x2="16" y2="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="9" y1="12" x2="27" y2="12" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path
      d="M16.9244,8h2.1512A3.9244,3.9244,0,0,1,23,11.9244V12a0,0,0,0,1,0,0H13a0,0,0,0,1,0,0v-.0756A3.9244,3.9244,0,0,1,16.9244,8Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default DeleteIcon
