import React from 'react'

const NavOpened = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22.322" height="20.161" viewBox="0 0 22.322 20.161">
    <g id="Gruppe_397" data-name="Gruppe 397" transform="translate(1 1)">
      <g id="Gruppe_376" data-name="Gruppe 376">
        <line id="Linie_259" data-name="Linie 259" x2="17.408" fill="none" stroke="#228bb8" strokeLinecap="round" strokeWidth="2" />
        <line
          id="Linie_260"
          data-name="Linie 260"
          x2="17.73"
          transform="translate(0 6.669)"
          fill="none"
          stroke="#228bb8"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Linie_261"
          data-name="Linie 261"
          x2="8.408"
          transform="translate(0 13.337)"
          fill="none"
          stroke="#228bb8"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g id="Gruppe_393" data-name="Gruppe 393" transform="translate(14.281 12.341)">
        <path
          id="Pfad_229"
          data-name="Pfad 229"
          d="M0,5.406,5.628,0"
          fill="none"
          stroke="#228bb8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Pfad_230"
          data-name="Pfad 230"
          d="M5.628,5.406,0,0"
          fill="none"
          stroke="#228bb8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default NavOpened
