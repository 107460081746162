import React from 'react'

const PhoneIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <path
          id="Pfad_145"
          data-name="Pfad 145"
          d="M27.9994,22.9734v3.0109A2.0084,2.0084,0,0,1,25.9982,28h-.0011a1.5508,1.5508,0,0,1-.1891-.0077,19.9153,19.9153,0,0,1-8.676-3.0805A19.5833,19.5833,0,0,1,11.1,18.89,19.8339,19.8339,0,0,1,8.0083,10.188,2.0086,2.0086,0,0,1,9.83,8.0081,1.3909,1.3909,0,0,1,10.01,8h3.0151a2.009,2.009,0,0,1,2.0111,1.7262,12.8527,12.8527,0,0,0,.7038,2.82,2.0043,2.0043,0,0,1-.4544,2.1169l-1.2737,1.2748A16.07,16.07,0,0,0,20.0393,21.96l1.277-1.2748a2.0125,2.0125,0,0,1,2.1218-.4514,12.9181,12.9181,0,0,0,2.8249.7019A2.0087,2.0087,0,0,1,27.9994,22.9734Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default PhoneIcon
