import { Pharmacy, PharmacyStatus, updatePharmacy } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, Modal, ModalKind, TextInput, toast } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useModal } from '../../contexts/modalContext'
import { usePharmacyQuery } from '../../hooks/graphql'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

interface PharmacyFormValues extends Partial<Pharmacy> {
  address_name?: string
  zipcode?: string
  city?: string
  reason?: string
  pharmacy_status?: PharmacyStatus
  other_reason?: string
}

interface Props {
  id: number
}

const PharmacyModal: React.FC<Props> = ({ id }) => {
  const { data: currentlyActivePharmacy, refetch: refetchPhamracy } = usePharmacyQuery(id)

  const { closeModal } = useModal()

  const updatePharmacyMutation = useAuthenticatedMutation(updatePharmacy)
  const getMutationWithVariables = (values: PharmacyFormValues) => {
    const variables = {
      id: currentlyActivePharmacy?.id,
      name: values.name,
      address: {
        address_name: values.address_name,
        zipcode: values.zipcode,
        city: values.city,
        longitude: currentlyActivePharmacy?.address.longitude,
        latitude: currentlyActivePharmacy?.address.latitude,
      },
      contact_person: values.contact_person,
      phone: values.phone,
      email: values.email,
      url: values.url,
      facebook: values.facebook,
      pharmacy_cluster_id: currentlyActivePharmacy?.pharmacy_cluster?.id,
      okid: currentlyActivePharmacy?.okid,
      classification: currentlyActivePharmacy?.classification,
    }
    const mutation = updatePharmacyMutation
    return { mutation, variables }
  }

  const initialFormValues: PharmacyFormValues = {
    name: currentlyActivePharmacy?.name,
    address_name: currentlyActivePharmacy?.address?.address_name,
    zipcode: currentlyActivePharmacy?.address?.zipcode,
    city: currentlyActivePharmacy?.address?.city,
    contact_person: currentlyActivePharmacy?.contact_person,
    phone: currentlyActivePharmacy?.phone?.replace(' ', ''),
    email: currentlyActivePharmacy?.email,
    url: currentlyActivePharmacy?.url,
    facebook: currentlyActivePharmacy?.facebook,
  }

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const PharmacyValidationScheme = Yup.object().shape({
    name: Yup.string().min(3, 'Must be more then 3 symbols').max(35, 'Must be less then 35 symbols').required(),
    address_name: Yup.string().min(3, 'Must be more then 3 symbols').max(35, 'Must be less then 35 symbols').required(),
    zipcode: Yup.string().matches(/^\d+$/, 'Must be only digits').required().min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
    city: Yup.string().min(3, 'Must be more then 3 symbols').max(25, 'Must be less then 25 symbols').required(),
    contact_person: Yup.string().min(3, 'Must be more then 3 symbols').max(25, 'Must be less then 25 symbols').required(),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
    email: Yup.string().email().required(),
    url: Yup.string().url().nullable(),
    facebook: Yup.string().url().nullable(),
  })

  const onFormSubmit = async (values: PharmacyFormValues) => {
    const { mutation, variables } = getMutationWithVariables(values)

    const PharmacyResponse = await mutation(variables)
    if (PharmacyResponse.errors !== undefined) {
      alert(PharmacyResponse.errors)
      return
    }
    try {
      toast.show({
        headline: 'Daten wurden erfolgreich aktualisert',
        type: 'success',
      })
    } catch {
      toast.show({
        headline: 'Daten konnten nicht aktualisert werden',
        type: 'error',
      })
    }
    await refetchPhamracy()
    closeModal()
  }

  return (
    <Modal kind={ModalKind.ms} title={'Standort bearbeiten'} onClose={closeModal} onBack={() => null}>
      <Formik key="pharmacy-updated-form" initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={PharmacyValidationScheme}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2 p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Standortname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('name', event.currentTarget.value)}
                        type="text"
                        value={values.name || ''}
                      />
                      {errors.name && touched.name ? <InputMessage kind={InputMessageKind.error}>{errors.name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Strasse:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('address_name', event.currentTarget.value)}
                        type="text"
                        value={values.address_name || ''}
                      />
                      {errors.address_name && touched.address_name ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.address_name}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'PLZ:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('zipcode', event.currentTarget.value)}
                        type="text"
                        value={values.zipcode || ''}
                      />
                      {errors.zipcode && touched.zipcode ? <InputMessage kind={InputMessageKind.error}>{errors.zipcode}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Ort:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('city', event.currentTarget.value)}
                        type="text"
                        value={values.city || ''}
                      />
                      {errors.city && touched.city ? <InputMessage kind={InputMessageKind.error}>{errors.city}</InputMessage> : null}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="w-1/2 p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Ansprechperson:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contact_person', event.currentTarget.value)}
                        type="text"
                        value={values.contact_person || ''}
                      />
                      {errors.contact_person && touched.contact_person ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.contact_person}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Telefon:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('phone', event.currentTarget.value.replace(' ', ''))}
                        type="text"
                        value={values.phone || ''}
                      />
                      {errors.phone && touched.phone ? <InputMessage kind={InputMessageKind.error}>{errors.phone}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'E - Mail - Adresse:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('email', event.currentTarget.value)}
                        type="text"
                        value={values.email || ''}
                      />
                      {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Relevanz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        id="q"
                        disabled={true}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                      >
                        <option>{'1'}</option>
                        <option selected>{'im Pool'}</option>
                        <option>{'3'}</option>
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Adressquelle:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('', event.currentTarget.value)}
                        type="text"
                        value={''}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Website:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('url', event.currentTarget.value)}
                        type="text"
                        value={values.url || ''}
                      />
                      {errors.url && touched.url ? <InputMessage kind={InputMessageKind.error}>{errors.url}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Facebook:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('facebook', event.currentTarget.value)}
                        type="text"
                        value={values.facebook || ''}
                      />
                      {errors.facebook && touched.facebook ? <InputMessage kind={InputMessageKind.error}>{errors.facebook}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {'Änderungen speichern'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default PharmacyModal
