import {
  getPharmacyByPharmacyCluster,
  getPharmacyDetails,
  Pharmacy,
  PharmacyResponse,
  ReminderState,
  updatePharmacyForCallCenter,
  updatePharmacyStatus,
  useGqlClient,
} from '@aposphaere/core-kit'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import { DeactivatePharmacyVariables } from '../../components/DeactivatePharmacyModal'
import { useCrmContext } from '../../contexts/crmContext'
import { QueryOptions } from './utils'

const getPharmaciesCacheKey = (quarterId: number | string, clusterId: number | string) => [
  'pharmacies',
  `quarter-${String(quarterId)}`,
  `cluster-${String(clusterId)}`,
]

export const getPharmacyCacheKey = (id: string | number) => ['pharmacy', String(id)]

/*
 * QUERIES
 */

export const usePharmaciesQuery = (options?: QueryOptions<Pharmacy[]>) => {
  const gqlClient = useGqlClient()
  const { activeQuarter, activeCluster } = useCrmContext()

  return useQuery(
    getPharmaciesCacheKey(activeQuarter.id, activeCluster.id),
    async () => {
      const data = await gqlClient.request<{ pharmacies: Pharmacy[] }>(getPharmacyByPharmacyCluster, {
        quarterId: activeQuarter.id,
        pharmacyClusterId: activeCluster.id,
      })
      if (!data?.pharmacies) {
        return []
      }
      return data.pharmacies
        .filter((pharmacy) => pharmacy.address)
        .map((pharmacy) => ({
          ...pharmacy,
          tasks: pharmacy?.tasks?.filter((task) => task.state === ReminderState.Active),
        }))
    },
    { staleTime: Infinity, ...options },
  )
}

export const usePharmacyQuery = (id: number | undefined | string, options?: QueryOptions<Pharmacy>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()

  return useQuery<Pharmacy>(
    ['pharmacy', String(id)],
    async () => {
      const response = await gqlClient.request<PharmacyResponse>(getPharmacyDetails, { id, quarterId: activeQuarter.id })
      const data = response?.pharmacy
      const modifiedResults: Pharmacy = {
        ...data,
        tasks: data?.tasks?.filter((task) => task.state === ReminderState.Active),
      }

      return modifiedResults
    },
    {
      enabled: !!id,
      onSettled: (pharmacy) => {
        if (!pharmacy) {
          return
        }
        updatePharmacyListInCache({
          queryClient,
          pharmacy,
          quarterId: activeQuarter.id,
          clusterId: activeCluster.id,
        })
      },
      ...options,
    },
  )
}

/*
 * MUTATIONS
 */

interface AvailableForCallcenterMutationVariables {
  id: number
  available_for_callcenter: boolean
}

export const useAvailableForCallcenterMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const cacheId = getPharmaciesCacheKey(activeQuarter.id, activeCluster.id)

  return useMutation(async (variables: AvailableForCallcenterMutationVariables) => gqlClient.request(updatePharmacyForCallCenter, variables), {
    onMutate: async (variables) => {
      const pharmacyCacheId = getPharmacyCacheKey(variables.id)
      await Promise.all([queryClient.cancelQueries(cacheId), queryClient.cancelQueries(pharmacyCacheId)])

      queryClient.setQueryData<Pharmacy | undefined>(pharmacyCacheId, (oldPharmacy) => {
        if (!oldPharmacy) {
          return undefined
        }
        if (oldPharmacy.id === variables.id) {
          return { ...oldPharmacy, available_for_callcenter: variables.available_for_callcenter }
        }
        return oldPharmacy
      })

      queryClient.setQueryData<Pharmacy[] | undefined>(cacheId, (oldPharmacies) => {
        if (!oldPharmacies) {
          return undefined
        }
        return oldPharmacies.map((pharmacy) => {
          if (pharmacy.id === variables.id) {
            return { ...pharmacy, available_for_callcenter: variables.available_for_callcenter }
          }
          return pharmacy
        })
      })
    },
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}

interface UpdatePharmacyListInCacheParams {
  pharmacy: Pharmacy
  quarterId: number
  clusterId: number
  queryClient: QueryClient
}

const updatePharmacyListInCache = ({ pharmacy: fetchedPharmacy, quarterId, clusterId, queryClient }: UpdatePharmacyListInCacheParams) => {
  const pharmacyListCacheKey = getPharmaciesCacheKey(quarterId, clusterId)
  const cachedPharmacies = queryClient.getQueryData<Pharmacy[]>(pharmacyListCacheKey)
  if (cachedPharmacies && fetchedPharmacy) {
    const updatedPharmacies = cachedPharmacies.map((_pharmacy) =>
      _pharmacy.id === fetchedPharmacy.id ? { ..._pharmacy, ...fetchedPharmacy } : _pharmacy,
    )
    queryClient.setQueryData(pharmacyListCacheKey, updatedPharmacies)
  }
}

export const useUpdatePharmacyStatusMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeQuarter, activeCluster } = useCrmContext()
  const cacheId = getPharmaciesCacheKey(activeQuarter.id, activeCluster.id)
  return useMutation(async (variables: DeactivatePharmacyVariables) => gqlClient.request(updatePharmacyStatus, variables), {
    onMutate: async (variables) => {
      if (variables?.pharmacyId) {
        await Promise.all([queryClient.cancelQueries(cacheId), queryClient.cancelQueries(getPharmacyCacheKey(variables.pharmacyId))])

        queryClient.setQueryData<Pharmacy[] | undefined>(cacheId, (oldPharmacies) => {
          if (!oldPharmacies) {
            return undefined
          }
          return oldPharmacies.map((pharmacy) => {
            if (pharmacy.id === variables.pharmacyId) {
              return { ...pharmacy, pharmacy_status: variables.pharmacyStatus }
            }
            return pharmacy
          })
        })

        queryClient.setQueryData<Pharmacy | undefined>(cacheId, (oldPharmacy) => {
          if (!oldPharmacy) {
            return undefined
          }

          if (oldPharmacy.id === variables.pharmacyId) {
            return { ...oldPharmacy, pharmacy_status: variables.pharmacyStatus }
          }
          return oldPharmacy
        })
      }
    },
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}
