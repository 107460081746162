import { allStatuses, Status, StatusFor, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { useQuery } from 'react-query'
import { isStatusCancelled } from '../../utils/statusUtils'
import { QueryOptions } from './utils'

export interface StatusWithCancelledProp extends Status {
  isCancelled: boolean
}

interface ParsedStatuses {
  forAppointments: StatusWithCancelledProp[]
  forProjects: StatusWithCancelledProp[]
}

export const useStatusesQuery = (options?: QueryOptions<ParsedStatuses>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery(
    'statuses',
    async () => {
      const response = await gqlClient.request<{ statuses: Status[] }>(allStatuses)
      const statuses = response?.statuses
      if (!statuses) {
        return { forAppointments: [], forProjects: [] }
      }
      const appointmentStauses = statuses
        ?.filter((status) => status.for === StatusFor.Appointments)
        .map((status) => ({ ...status, isCancelled: isStatusCancelled(status) }))
      const projectStatuses = statuses
        ?.filter((status) => status.for === StatusFor.Projects)
        .map((status) => ({ ...status, isCancelled: isStatusCancelled(status) }))

      return { forAppointments: appointmentStauses, forProjects: projectStatuses }
    },
    { enabled: !!auth.user, staleTime: Infinity, ...options },
  )
}

export const useCancelledStatusesIds = () => {
  const cancelledStatuses = ['Abgesagt', 'Abgesagt < 12h']
  const statusesQuery = useStatusesQuery()
  return (
    statusesQuery?.data?.forAppointments?.filter((status) => cancelledStatuses.includes(status.label_short)).map((status) => status.id.toString()) ??
    []
  )
}

export const useCompletedStatusId = () => {
  const completedStatus = 'Schulung'
  const statusesQuery = useStatusesQuery()
  return statusesQuery?.data?.forAppointments?.filter((status) => completedStatus === status.label_short).map((status) => status.id.toString()) ?? []
}
