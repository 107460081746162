import React from 'react'

const NavClosed = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19.73" height="15.337" viewBox="0 0 19.73 15.337">
    <g id="Gruppe_376" data-name="Gruppe 376" transform="translate(1 1)">
      <line
        id="Linie_259"
        data-name="Linie 259"
        x2="17.73"
        transform="translate(0 0)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_260"
        data-name="Linie 260"
        x2="17.73"
        transform="translate(0 6.669)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linie_261"
        data-name="Linie 261"
        x2="17.73"
        transform="translate(0 13.337)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default NavClosed
