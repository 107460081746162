import React, { FunctionComponent, HTMLProps } from 'react'
import InputMessage, { InputMessageKind } from '../InputMessage'

export type TextAreaInputProps = Omit<HTMLProps<HTMLTextAreaElement>, 'className'> & {
  name?: string
  maxlength?: number
  required?: boolean
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  placeholder?: string
  message?: string
  valid?: boolean
  initialValue?: string
  containerCustomStyle?: string
  textareaCustomStyle?: string
}

const TextAreaInput: FunctionComponent<TextAreaInputProps> = ({
  initialValue,
  message,
  valid,
  placeholder,
  onChange,
  containerCustomStyle,
  textareaCustomStyle,
  ...textAreaProps
}) => {
  const disabledClass = textAreaProps.disabled ? 'opacity-50 hover:shadow-none' : 'hover:shadow-focus'
  const handleOnchange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (!onChange) {
      return
    }
    onChange(event)
  }
  return (
    <>
      <div className={`${containerCustomStyle ? containerCustomStyle : 'mt-1 relative rounded-md w-full'} ${disabledClass}`}>
        <textarea
          defaultValue={initialValue}
          onChange={handleOnchange}
          className={`${
            textareaCustomStyle
              ? textareaCustomStyle
              : 'form-input font-body block w-full h-24 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5'
          }`}
          {...textAreaProps}
        />
      </div>
      {message ? <InputMessage kind={valid === false ? InputMessageKind.error : InputMessageKind.info}>{message}</InputMessage> : <></>}
    </>
  )
}

export default TextAreaInput
