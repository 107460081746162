import React from 'react'

const LocationMarkerInfoIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Rechteck_189" data-name="Rechteck 189">
      <rect x="-21.5701" y="91.3369" width="430.757" height="430.757" rx="50" transform="translate(-127.3923 137.9962) rotate(-30)" fill="none" />
      <rect
        x="-19.0701"
        y="93.8369"
        width="425.757"
        height="425.757"
        rx="47.5"
        transform="translate(-127.3923 137.9962) rotate(-30)"
        fill="none"
        stroke="#f8d2bd"
        strokeWidth="5"
      />
    </g>
    <line x1="18" y1="16.5" x2="18" y2="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
    <path
      d="M20.5456,26.621C19.802,27.3436,18,28.9949,18,28.9949S9,22,9,16.0336c0-4.5553,4.02-8.2482,8.9792-8.2482s8.9791,3.6929,8.9791,8.2482c0,2.0039-.6834,3.4-2.2083,5.6331"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.9063"
    />
  </svg>
)

export default LocationMarkerInfoIcon
