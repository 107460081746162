import { gql } from 'graphql-request'

export const getUser = gql`
  {
    me {
      id
      email
      name
      roles {
        name
        label
        abilities {
          name
          label
        }
      }
      pharmacy_clusters {
        id
        name
        agent {
          name
          address {
            id
            address_name
            latitude
            longitude
          }
        }
      }
    }
  }
`
