import { Appointment, FinishedPresentationsResponse, parseUTC, Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState, useMemo, useCallback } from 'react'
import { format, compareDesc } from 'date-fns'
import { useCancelledStatusesIds, useCompletedStatusId } from '../../hooks/graphql'

export interface HistoryCardProps {
  showSpecialButton: boolean
  pharmacy: Pharmacy
  appointments: Appointment[]
}

const groupByProject = (presentations: AllowedPresentations[]) => {
  const initialResult: { [key: string]: AllowedPresentations[] } = {}
  return presentations.reduce((acc, curr) => {
    const trainerId = curr?.trainer?.id ?? ''
    if (!acc[trainerId]) {
      acc[trainerId] = []
    }
    acc[trainerId].push(curr)
    acc[trainerId].sort((a, b) => compareDesc(parseUTC(a.starttime), parseUTC(b.starttime)))
    return acc
  }, initialResult)
}

type AllowedPresentations = FinishedPresentationsResponse & {
  appointmentDate: string
}

const VISIT_APPOINTMENT_TYPE_FRAGMENT = 'Besuch'

const HistoryCard: React.FunctionComponent<HistoryCardProps> = ({ pharmacy, appointments }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const cancelledStatusIds = useCancelledStatusesIds()
  const cancelledAppointments = appointments.filter(
    (appointment) => cancelledStatusIds.includes(appointment?.status?.id ?? '') && appointment?.pharmacy?.id === pharmacy.id,
  )
  const visitAppointments = appointments.filter(
    (appointment) =>
      appointment?.appointmentType?.label === VISIT_APPOINTMENT_TYPE_FRAGMENT &&
      appointment?.pharmacy?.id === pharmacy.id &&
      !cancelledStatusIds.includes(appointment?.status?.id ?? ''),
  )
  const completedStatusIds = useCompletedStatusId()
  const trainingAppointments = appointments.filter((appointment) => completedStatusIds.includes(appointment?.status?.id ?? ''))

  const getAllowedPresentations = useCallback(
    (targetAppointments: Appointment[]) =>
      targetAppointments
        .filter((appointment) => appointment.pharmacy?.id === pharmacy?.id)
        .map(
          ({ finished_presentations, date }) =>
            (finished_presentations ?? []).map((presentation) => ({ ...presentation, appointmentDate: date || '' })) ?? [],
        )
        .flat(),
    [pharmacy],
  )

  const allowedTrainingPresentations: AllowedPresentations[] = useMemo(() => getAllowedPresentations(trainingAppointments), [
    trainingAppointments,
    getAllowedPresentations,
  ])

  const listOfGroupedTrainingPresentations: AllowedPresentations[][] = useMemo(
    () => Object.values(groupByProject(allowedTrainingPresentations)) ?? [],
    [allowedTrainingPresentations],
  )

  interface HistoryItem {
    ipadCount: number
    trainer: string
    appointmentDate: string
    projects: string[]
  }

  const getHistoryItems = (presentations: AllowedPresentations[][]) =>
    presentations.map((presentation) => {
      const ipadCount = presentation.reduce((acc, curr) => acc + curr.device_count, 0)
      const trainer = presentation[0].trainer.name
      const appointmentDate = format(parseUTC(presentation[0].appointmentDate), 'dd.MM.yyyy')
      const projects = Array.from(new Set(...presentation.map((targetPresentation) => targetPresentation.project.name)))
      return {
        ipadCount,
        trainer,
        appointmentDate,
        projects,
      }
    })

  const getVisitAndTrainingItems = (apppoinments: Appointment[]) =>
    apppoinments.map((appointment) => {
      const ipadCount = 0
      const trainer = appointment?.creator?.name
      const appointmentDate = format(parseUTC(appointment.date!), 'dd.MM.yyyy')
      return {
        ipadCount,
        trainer,
        appointmentDate,
      }
    })

  const historyVisitItems: Partial<HistoryItem>[] = useMemo(() => getVisitAndTrainingItems(visitAppointments), [visitAppointments])

  const historyCancelledItems: Partial<HistoryItem>[] = useMemo(() => getVisitAndTrainingItems(cancelledAppointments), [cancelledAppointments])

  const historyTrainingItems: HistoryItem[] = useMemo(() => getHistoryItems(listOfGroupedTrainingPresentations), [listOfGroupedTrainingPresentations])

  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      title="Historie"
      showAddButton={false}
      showSpecialButton={false}
      showAllEntries={true}
    >
      <div className="px-2 2xl:px-4 ">
        {historyTrainingItems.length === 0 && historyCancelledItems.length === 0 && historyVisitItems.length === 0 ? (
          <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
        ) : (
          historyTrainingItems?.map(({ appointmentDate, ipadCount, trainer, projects }) => (
            <div className="py-1 2xl:py-2 text-base" key={trainer}>
              {appointmentDate?.toString()} - {ipadCount} Tln - {trainer} - {projects}
            </div>
          ))
        )}

        {historyVisitItems?.map(({ appointmentDate, ipadCount, trainer }) => (
          <div className="py-1 2xl:py-2 text-base" key={trainer}>
            {appointmentDate?.toString()} - {ipadCount} Tln - {trainer} - Besuch ohne Schulung
          </div>
        ))}

        {historyCancelledItems?.map(({ appointmentDate, ipadCount, trainer }) => (
          <div className="py-1 2xl:py-2 text-base" key={trainer}>
            {appointmentDate?.toString()} - {ipadCount} Tln - {trainer} - abgesagt
          </div>
        ))}
      </div>
    </SectionCard>
  )
}

export default HistoryCard
