import React, { memo } from 'react'
import { MapMarker, MapMarkerProps } from './MapMarker'

interface IMapMarkers {
  markers?: MapMarkerProps[]
}

const MapMarkers = ({ markers }: IMapMarkers) => (
  <>
    {markers?.map((marker) => (
      <MapMarker key={marker.id} {...marker} />
    ))}
  </>
)

export default memo(MapMarkers)
