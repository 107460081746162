import { formattedDateString, formattedTimeString } from '@aposphaere/core-kit'
import React, { useCallback, useRef } from 'react'
import { timeSchema } from '../../utils/validationSchemas'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import InputWithValidation from '../TexInputHOC/TexInputWithValidation'
import { useCalendarPanel } from './CalendarPanelContext'
import { CSSTransition } from 'react-transition-group'
import Fade from '../Transitions/Fade'

export const SelectMode = () => {
  type SLOT = 'slot'
  type MANUAL = 'manual'
  const { endDate, selectedTime, setSelectedTime, validateAndSetTime, selectedMode, setSelectedMode } = useCalendarPanel()

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    const value: SLOT | MANUAL = e.target.value as SLOT | MANUAL
    setSelectedMode(value)
  }

  const onTimeSlotSelected = useCallback(
    (date: Date) => {
      setSelectedTime(date)
    },
    [setSelectedTime],
  )

  const onChangeTimeCheck = useCallback((value: string) => !value.match(/(?!:)\D/g) || value === '', [])

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
  const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  const nodeRef = useRef(null)

  return (
    <CSSTransition addEndListener={() => null} in={true} nodeRef={nodeRef}>
      <div ref={nodeRef} className="flex flex-wrap w-full sticky top-20 bg-white z-40">
        <div className={`flex w-full border-b border-gray-400 bg-white z-40`}>
          <div className={`w-full relative `}>
            <div className={`bg-white w-full`}>
              <div className="flex gap-5 w-full">
                <div className={`flex w-1/2 items-center py-3 pl-2`}>
                  <input
                    id="radio-slot"
                    name="slot-time"
                    value="slot"
                    checked={selectedMode === 'slot'}
                    onChange={handleInputChange}
                    type="radio"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label htmlFor="radio-slot" className="ml-2.5 cursor-pointer">
                    <span className="block font-body font-medium text-blue-700 text-smx">Slot wählen</span>
                  </label>
                </div>
                <div className="flex w-1/2 items-center">
                  <input
                    id="radio-manual"
                    name="manual-time"
                    value="manual"
                    type="radio"
                    checked={selectedMode === 'manual'}
                    onChange={handleInputChange}
                    className="focus:ring-blue-600 h-4 w-4  my-1 border-2 text-blue-600 border-blue-600"
                  />
                  <label htmlFor="radio-manual" className="ml-2.5 cursor-pointer">
                    <span className="font-body  font-medium text-blue-700 text-smx">Manuelle Angabe</span>
                  </label>
                </div>
              </div>

              <Fade inProp={selectedMode === 'manual'}>
                <div className={`flex px-2.5 gap-5 mb-3 w-full ${selectedMode === 'manual' ? ' ' : 'opacity-45'}`}>
                  <div className="flex w-1/2 flex-col  rounded-md justify-items-start">
                    <span className="block text-sm font-normal tracking-wide capitalize text-black-200">{`Datum:`}</span>
                    <DayPickerInput
                      formatDate={formattedDateString}
                      placeholder={'dd.mm.yyyy'}
                      dayPickerProps={{
                        onDayClick: (day: Date, { disabled }) => {
                          if (!disabled) {
                            onTimeSlotSelected(day)
                          }
                        },
                        disabledDays: [
                          {
                            before: new Date().getHours() > 18 ? tomorrow : new Date(),
                          },
                          { daysOfWeek: [0, 6] },
                          { after: endDate || new Date() },
                        ],
                        firstDayOfWeek: 1,
                        months: MONTHS,
                        weekdaysLong: WEEKDAYS_LONG,
                        weekdaysShort: WEEKDAYS_SHORT,
                        locale: 'de',
                      }}
                      inputProps={{
                        readOnly: true,
                        disabled: selectedMode === 'slot',
                        className:
                          'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                      }}
                      value={selectedTime}
                    />
                  </div>
                  <div className="flex w-1/2 flex-col rounded-md justify-items-start">
                    <span className="block text-sm font-normal tracking-wide capitalize text-black-200">{`Uhrzeit:`}</span>
                    <div className="rounded-md">
                      <InputWithValidation
                        key={`${selectedTime?.toDateString() || ''}`}
                        placeholder="hh:mm"
                        onChangeCheck={onChangeTimeCheck}
                        schema={timeSchema}
                        getErrorOrSetValue={validateAndSetTime}
                        debounceTime={500}
                        disabled={!selectedTime}
                        initialValue={selectedTime ? formattedTimeString(selectedTime) : undefined}
                      />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <Fade inProp={selectedMode === 'manual'}>
              <div className="w-full h-screen bg-black-300 opacity-45 absolute"></div>
            </Fade>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
