import React from 'react'

const ReminderIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <path
      d="M18,9h0a6.895,6.895,0,0,1,6.9947,6.7908V20.6l1.8295,2.7622A1.0626,1.0626,0,0,1,25.9031,25H10.0969a1.0626,1.0626,0,0,1-.9211-1.6383L11.0053,20.6V15.7908a6.7064,6.7064,0,0,1,2.6135-5.2939"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="18" y1="6.5" x2="18" y2="9" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M21,28a3.2532,3.2532,0,0,1-6,.01" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export default ReminderIcon
