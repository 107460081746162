import React, { createContext, useEffect } from 'react'
import { useAuthProvider } from '../hooks'
import { User, userActions } from '../models'
// Heavily inspired by https://usehooks.com/useAuth/

/**
 * Definition for AuthContext
 */
export interface IAuthContext {
  /**
   * The users abilities
   */
  abilities: Set<typeof userActions[number]>
  /**
   * The user itself. If the person is not authenticated the object is null
   */
  user?: User

  /**
   * The JWT token which is used for authentication
   */
  token: string

  /**
   * Signs in a User with the given credentials
   */
  signIn: (email: string, password: string) => Promise<boolean>

  /**
   * Checks if token is still alive
   */
  getUserData: () => Promise<void>

  /**
   * Signs out a user
   */
  signOut: () => void
}

export const AuthContext = createContext<IAuthContext | null>(null)

/**
 * Populates an implementation of AuthContext to its children.
 *
 */
export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const auth = useAuthProvider()
  const { getUserData } = auth
  useEffect(() => {
    void getUserData()
  }, [getUserData])
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
