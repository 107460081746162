import React from 'react'
import { IconButton, IconButtonKind, LogoutIcon } from '@aposphaere/ui-components'
import { useAuth } from '@aposphaere/core-kit'

const SignOutButton: React.FC = () => {
  const auth = useAuth()

  const handleLogout = () => {
    auth.signOut()
    //reset to all client state to initial state
    window.location.href = '/login'
  }

  return (
    <IconButton
      kind={IconButtonKind.custom}
      icon={<LogoutIcon />}
      additionalCss="-mr-2 ml-4 text-white active:text-white hover:text-white focus:outline-none hover:bg-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150 "
      onClick={handleLogout}
    />
  )
}

export default SignOutButton
