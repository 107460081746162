import { gql } from 'graphql-request'

export const getProjectStatus = gql`
  {
    statuses {
      id
      label
      label_short
    }
  }
`
