import { FinishedPresentationsResponse, parseUTC, Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState, useMemo } from 'react'
import { differenceInSeconds, formatDuration, compareDesc } from 'date-fns'

export interface SlotCardProps {
  showSpecialButton: boolean
  pharmacy: Pharmacy
}

function convertTime(seconds: number): string {
  if (seconds < 60) {
    return `${seconds} seconds`
  } else {
    return formatDuration({ seconds: seconds }, { format: ['hours', 'minutes'] })
  }
}

const groupByProject = (presentations: FinishedPresentationsResponse[]) => {
  const initialResult: { [key: string]: FinishedPresentationsResponse[] } = {}
  return presentations.reduce((acc, curr) => {
    const projectId = curr.project.id
    if (!acc[projectId]) {
      acc[projectId] = []
    }
    acc[projectId].push(curr)
    acc[projectId].sort((a, b) => compareDesc(parseUTC(a.starttime), parseUTC(b.starttime)))
    return acc
  }, initialResult)
}

const SlotCard: React.FunctionComponent<SlotCardProps> = ({ pharmacy }) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const listOfGroupedPresentations = useMemo(() => {
    const finishedPresentations = pharmacy?.finished_presentations ?? []
    const groupedPresentations = groupByProject(finishedPresentations)
    return Object.values(groupedPresentations) ?? []
  }, [pharmacy])

  interface SlotItem {
    numberOfProjectPasses: number
    projectName: string
    ipadCount: number
    lengthOfTraining: string
    dateCreated: Date
  }

  const slotItems: SlotItem[] = useMemo(
    () =>
      listOfGroupedPresentations
        .map((groupedPresentation) => {
          const numberOfProjectPasses = groupedPresentation.length
          const projectName = groupedPresentation[0].project.name ?? ''
          const ipadCount = groupedPresentation.reduce((acc, curr) => acc + curr.device_count, 0)
          const lengthOfTraining = groupedPresentation.reduce((acc, curr) => {
            const currentTotalTime = differenceInSeconds(parseUTC(curr.endtime), parseUTC(curr.starttime))
            return acc + currentTotalTime
          }, 0)
          return {
            numberOfProjectPasses,
            projectName,
            ipadCount,
            lengthOfTraining: convertTime(lengthOfTraining),
            dateCreated: parseUTC(groupedPresentation[0].starttime),
          }
        })
        .sort((a, b) => compareDesc(a.dateCreated, b.dateCreated)),
    [listOfGroupedPresentations],
  )

  return (
    <SectionCard isExpanded={expanded} setExpanded={setExpanded} title="Slot" showAddButton={false} showSpecialButton={false} showAllEntries={true}>
      <div className="px-2 2xl:px-4 ">
        {slotItems.length === 0 ? (
          <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
        ) : (
          slotItems?.map(({ numberOfProjectPasses, projectName, lengthOfTraining, ipadCount }) => (
            <div className="py-1 2xl:py-2 text-base" key={projectName}>
              {numberOfProjectPasses} X {projectName} - {ipadCount} iPad{ipadCount > 1 ? 's' : ''} - {lengthOfTraining}
            </div>
          ))
        )}
      </div>
    </SectionCard>
  )
}

export default SlotCard
