import React from 'react'

const ApoCampusIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Rechteck_189" data-name="Rechteck 189">
      <rect x="23.4299" y="46.3369" width="430.757" height="430.757" rx="50" transform="translate(-98.8634 154.4674) rotate(-30)" fill="none" />
      <rect
        x="25.9299"
        y="48.8369"
        width="425.757"
        height="425.757"
        rx="47.5"
        transform="translate(-98.8634 154.4674) rotate(-30)"
        fill="none"
        stroke="#f8d2bd"
        strokeWidth="5"
      />
    </g>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <g id="Gruppe_365" data-name="Gruppe 365">
          <path
            id="Pfad_221"
            data-name="Pfad 221"
            d="M23.8745,25.5668V16.623a5.6756,5.6756,0,0,0-5.6756-5.6748h-.4357a5.6754,5.6754,0,0,0-5.6756,5.6748v2.5143a5.6754,5.6754,0,0,0,5.6753,5.6757h2.5663v3.604H17.7451a9.1425,9.1425,0,0,1-9.1427-9.1425h0V16.7257A9.1427,9.1427,0,0,1,17.7451,7.583h.5116a9.1427,9.1427,0,0,1,9.1409,9.1427v4.9882"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Pfad_222"
            data-name="Pfad 222"
            d="M20.3292,21.263V16.966A2.3974,2.3974,0,0,0,17.9324,14.57h-.0267A2.3971,2.3971,0,0,0,15.51,16.966l.0226,1.9131a2.397,2.397,0,0,0,1.8148,2.3257"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ApoCampusIcon
