import React, { forwardRef } from 'react'

export type LeftSidebarProps = {
  headerContent?: React.ReactNode
  children?: React.ReactNode
  maximized?: boolean
  onScroll?: (e: React.UIEvent<HTMLElement>) => void
  showMax?: boolean
}

const LeftSidebar = (props: LeftSidebarProps, ref: ((instance: HTMLDivElement) => void) | React.MutableRefObject<HTMLDivElement>) => {
  const { headerContent, children, showMax, onScroll } = props
  const sidebarstyle = showMax ? 'h-full-sidebar sidebar shadow-lg' : 'w-12'
  return (
    <div
      onScroll={onScroll}
      ref={ref}
      className={`${sidebarstyle} ${showMax ? 'min-h-68px overflow-y-scroll' : 'h-12'} z-50 bg-white overflow-x-hidden`}
    >
      <div className={`flex bg-white w-full border-b border-solid border-gray-400 sticky top-0 z-40 items-center `}>{headerContent}</div>
      {children}
    </div>
  )
}
export default forwardRef<HTMLDivElement, LeftSidebarProps>(LeftSidebar)
