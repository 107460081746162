import React from 'react'

const LockedForCallsIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <line
      id="Linie_92"
      data-name="Linie 92"
      x1="21.0004"
      y1="8"
      x2="28.9688"
      y2="15.9539"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      id="Linie_93"
      data-name="Linie 93"
      x1="21"
      y1="15.9539"
      x2="28.9683"
      y2="8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      id="Pfad_37"
      data-name="Pfad 37"
      d="M25.693,23.6648v2.7234a1.8133,1.8133,0,0,1-1.8037,1.8227h-.0044a1.4634,1.4634,0,0,1-.1727-.0069,17.9618,17.9618,0,0,1-7.8334-2.7864,17.7019,17.7019,0,0,1-5.4459-5.4458,17.9609,17.9609,0,0,1-2.7855-7.87,1.8134,1.8134,0,0,1,1.64-1.9713h.0008a1.2081,1.2081,0,0,1,.1658-.0078h2.7235a1.8133,1.8133,0,0,1,1.8132,1.5609,11.6464,11.6464,0,0,0,.6339,2.5507,1.8132,1.8132,0,0,1-.4054,1.915L13.07,17.3019a14.5225,14.5225,0,0,0,5.44,5.4467l1.1527-1.1527a1.8133,1.8133,0,0,1,1.9152-.4084,11.647,11.647,0,0,0,2.5507.6355A1.8133,1.8133,0,0,1,25.693,23.6648Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default LockedForCallsIcon
