import React, { FunctionComponent } from 'react'

export type TagToggleButtonProps = {
  selected: boolean
  onClick: () => void
  children?: React.ReactNode
  customCSS?: string
  disabled?: boolean
}

const TagToggleButton: FunctionComponent<TagToggleButtonProps> = ({ selected, onClick, children, customCSS = '', disabled }) => {
  const buttonCSS = 'text-base rounded-md py-2 px-1 2xl:px-2 focus:outline-none active:bg-blue-600 active:text-white active:border-blue-600'
  const tagStyle = selected
    ? 'bg-blue-50 text-blue-700 border-blue-600 border border-solid'
    : `bg-white ${disabled ? 'text-gray-500' : 'text-gray-700'} border-gray-400 border border-solid ${disabled ? 'cursor-default' : ''} `

  const className = `${buttonCSS} ${tagStyle} ${customCSS}`

  return (
    <button disabled={disabled} type="button" onClick={onClick} className={className}>
      <span className="-mt-px">{children}</span>
    </button>
  )
}

export default TagToggleButton
