/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PharmacyCluster, Quarter, parseUTC } from '@aposphaere/core-kit'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IPointType } from '../components/PharmacyDataSheetPanel/PharmacyDataSheetTypes'
import { usePharmacyClustersQuery, useQuartersQuery } from '../hooks/graphql'

/**
 * The Global context
 *
 * It contains properties and methods
 * that are:
 *    1) Necessary for the whole app.
 *    2) Are rarely changed
 *    3) If changed, would need to rerender the whole page anyways
 */
export interface ICrmContext {
  /**
   * The active Quarter selected for the app
   */
  activeQuarter: Quarter
  setActiveQuarterId: (id: string) => unknown
  /**
   * The active Pharmacy Cluster for the app
   */
  activeCluster: PharmacyCluster
  setActiveClusterId: (id: string) => unknown
  year: number

  currentPointHook: {
    currentPoint: IPointType
    setCurrentPoint: React.Dispatch<React.SetStateAction<IPointType>>
  }

  infoRef: React.RefObject<HTMLHeadingElement>
  notizenRef: React.RefObject<HTMLHeadingElement>
  resubmissionsRef: React.RefObject<HTMLHeadingElement>
  filialenRef: React.RefObject<HTMLHeadingElement>
  termineRef: React.RefObject<HTMLHeadingElement>
  projekteRef: React.RefObject<HTMLHeadingElement>
  personenRef: React.RefObject<HTMLHeadingElement>
  windowRef: React.RefObject<HTMLDivElement>
  slotRef: React.RefObject<HTMLDivElement>
  historyRef: React.RefObject<HTMLDivElement>
}

/**
 * CONTEXT
 */
export const CrmContext = createContext<ICrmContext | null>(null)
CrmContext.displayName = 'CrmContext'
/**
 * PROVIDER
 */
interface CrmProviderProps {
  children?: React.ReactNode
}
const CrmProvider: React.FunctionComponent<CrmProviderProps> = ({ children }) => {
  const [currentPoint, setCurrentPoint] = useState<IPointType>('info')

  const infoRef = useRef<HTMLHeadingElement>(null)
  const notizenRef = useRef<HTMLHeadingElement>(null)
  const resubmissionsRef = useRef<HTMLHeadingElement>(null)
  const filialenRef = useRef<HTMLHeadingElement>(null)
  const termineRef = useRef<HTMLHeadingElement>(null)
  const projekteRef = useRef<HTMLHeadingElement>(null)
  const personenRef = useRef<HTMLHeadingElement>(null)
  const windowRef = useRef<HTMLDivElement>(null)
  const slotRef = useRef<HTMLDivElement>(null)
  const historyRef = useRef<HTMLDivElement>(null)

  const [activeQuarter, setActiveQuarter] = useState<Quarter | null>(null)
  const [activeCluster, setActiveCluster] = useState<PharmacyCluster | null>(null)
  const { data: pharmacyClusters } = usePharmacyClustersQuery()

  const activeQuarterStart = activeQuarter?.from
  useEffect(() => {
    if (activeCluster || !pharmacyClusters?.length) {
      return
    }
    setActiveCluster(pharmacyClusters?.[0])
  }, [activeCluster, pharmacyClusters])

  const quartersQuery = useQuartersQuery()

  useEffect(() => {
    if (activeQuarter || !quartersQuery.data?.length) {
      return
    }
    const defaultQuarter = quartersQuery.data.find((quarter) => quarter.default === true) || quartersQuery.data[0]
    if (defaultQuarter) {
      setActiveQuarter(defaultQuarter)
    }
  }, [quartersQuery.data, activeQuarter])

  const year = useMemo(() => (activeQuarterStart ? parseUTC(activeQuarterStart).getFullYear() : null), [activeQuarterStart])

  const setActiveQuarterId = useCallback(
    (id: string) => {
      const quarter = quartersQuery?.data?.find((_quarter) => String(_quarter.id) === String(id))
      if (quarter) {
        setActiveQuarter(quarter)
      }
    },
    [quartersQuery.data],
  )

  const setActiveClusterId = useCallback(
    (id: string) => {
      const cluster = pharmacyClusters?.find((pharmacyCluster) => String(pharmacyCluster.id) === id)
      if (cluster) {
        setActiveCluster(cluster)
      }
    },
    [pharmacyClusters],
  )

  if (!activeQuarter || !activeCluster || !year) {
    return null
  }

  return (
    <CrmContext.Provider
      value={{
        year,
        activeQuarter,
        activeCluster,
        setActiveClusterId,
        setActiveQuarterId,
        currentPointHook: { currentPoint, setCurrentPoint },
        infoRef,
        notizenRef,
        resubmissionsRef,
        filialenRef,
        termineRef,
        projekteRef,
        personenRef,
        windowRef,
        slotRef,
        historyRef,
      }}
    >
      {children}
    </CrmContext.Provider>
  )
}

/*
 * HOOK
 */
export const useCrmContext = () => {
  const context = useContext(CrmContext)

  if (!context) {
    throw new Error("Provider not found: Attemting to use the crmContext, without it's provider")
  }
  return context
}

export default CrmProvider
