// @ts-expect-error It would need a type declaration, but whatever...
import config from '@aposphaere/tailwind-config'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const mapColorsFromConfig = config.theme.extend.colors.map

export const googleMapColors = Object.keys(mapColorsFromConfig)

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const arrayOfColors = googleMapColors.map((color) => mapColorsFromConfig[color])
