import { gql } from 'graphql-request'

export const signIn = gql`
  mutation($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      token
    }
  }
`
