import { Checkbox, InputLabel, InputMessage, InputMessageKind, TextAreaInput, TextInput } from '@aposphaere/ui-components'
import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'
import { useActiveProjectsQuery, useAppointmentTypesQuery, usePharmacyQuery, useStatusesQuery } from '../../hooks/graphql'
import { getCurrentProjects } from '../../utils/projects'
import { AppointmentFormValues } from './models'
import { useCalendarPanel } from './CalendarPanelContext'

interface Props {
  selectedPharmacyId: number
  selectedDay: Date | undefined
}

const AppointmentDetailsEditor = ({ selectedPharmacyId, selectedDay }: Props) => {
  const { selectedOrderItems, updateOrderItems } = useCalendarPanel()
  const { values, errors, touched, setFieldValue } = useFormikContext<AppointmentFormValues>()
  const [isCustomEmail, setIsCustomEmail] = useState<boolean>(false)

  const { data: pharmacy } = usePharmacyQuery(selectedPharmacyId)
  const pharmacyEmail = pharmacy?.email ?? ''

  const isValidEmail = useMemo(() => {
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(pharmacyEmail)
  }, [pharmacyEmail])

  const { data: activeProjects } = useActiveProjectsQuery()
  const appointmentTypesQuery = useAppointmentTypesQuery()
  const visitAppointmentTpye = appointmentTypesQuery?.data?.appointmentTypes?.find((type) => type.label?.toLowerCase().includes('besuch'))

  const mustHaveOrderItems = values.appointmentTypeId !== String(visitAppointmentTpye?.id)
  const haveOrderItems = Boolean(selectedOrderItems.length)

  const statusesQuery = useStatusesQuery()
  const statuses = statusesQuery.data
  const currentProjects = getCurrentProjects(activeProjects, selectedDay)

  return (
    <div className="flex w-full jp-4 px-6 pt-4 pb-8">
      <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <InputLabel>{'Gesprächsperson:'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextInput
              value={values.contactPerson}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                event.persist()
                setFieldValue('contactPerson', event.currentTarget.value)
              }}
              type="text"
              placeholder=""
            />
            {errors.contactPerson && touched.contactPerson ? <InputMessage kind={InputMessageKind.error}>{errors.contactPerson}</InputMessage> : null}
          </div>
        </div>
        <div className="sm:col-span-3">
          <InputLabel>{'Kontaktdaten:'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextInput value={pharmacy?.email ?? ''} disabled={true} onChange={() => null} type="text" placeholder="E-Mail" />
          </div>
        </div>
        <div className="sm:col-span-3">
          <InputLabel>{'Termintyp:'}</InputLabel>

          <div className="mt-1 rounded-md">
            <select
              onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('appointmentTypeId', event.currentTarget.value)}
              className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
            >
              {appointmentTypesQuery?.data?.appointmentTypes?.map((type) => (
                <option key={`type-${type.id}`} value={type.id}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:col-span-3">
          <InputLabel>{'Terminstatus:'}</InputLabel>
          <div className="mt-1 rounded-md">
            <select
              defaultValue={values.statusId}
              onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('statusId', event.currentTarget.value)}
              className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
            >
              {statuses?.forAppointments?.map((status) => (
                <option key={`status-${status.id}`} value={status.id}>
                  {status.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:col-span-6">
          <InputLabel>{'Notiz:'}</InputLabel>
          <div className="mt-1 rounded-md">
            <TextAreaInput
              initialValue={values.note}
              onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
              placeholder=""
            />
          </div>
        </div>
        <div className="sm:col-span-6">
          <InputLabel>{'Produkte:'}</InputLabel>
          {mustHaveOrderItems && !haveOrderItems ? (
            <InputMessage kind={InputMessageKind.error}>{'Bitte wählen sie ein Produkt aus'}</InputMessage>
          ) : null}
        </div>

        {currentProjects?.map((project) => {
          if (!project?.is_orderable) {
            return null
          }

          const setItem = selectedOrderItems.find((item) => `${item.project_id}` === `${project.id}`)
          return (
            <div key={`project-${project.id}`} className="sm:col-span-6 2lg:col-span-3 flex flex-wrap">
              <div className="w-full flex items-center">
                <span className="text-base font-body text-gray-700 whitespace-no-wrap">{`${project.name} (${project.contact_goal})`}</span>
              </div>
              <div className="w-full">
                <select
                  value={setItem?.amount}
                  onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                    updateOrderItems(event.currentTarget.value, event.currentTarget.id, project)
                  }}
                  id={project.id.toString()}
                  className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                >
                  {['0', '1', '2', '3', '4', '5', '6'].map((amount) => (
                    <option key={`project-goal-${project.id}-${amount}`} id={project.id.toString()} value={amount}>
                      {amount}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )
        })}
        <div className="sm:col-span-6 flex">
          <div className="mr-2">
            <Checkbox
              name="sendConfirmation"
              label="E-Mail Benachrichtigung Senden"
              checked={values.sendConfirmation}
              onChange={(e) => {
                const checked = e.currentTarget.checked
                setFieldValue('sendConfirmation', checked)
                if (!checked) {
                  setIsCustomEmail(false)
                  setFieldValue('customEmail', '')
                }
              }}
            />
            {values.sendConfirmation && !isValidEmail && !values.customEmail ? (
              <InputMessage kind={InputMessageKind.error}>{'Bitte fügen Sie eine E-Mail hinzu, um eine Terminbestätigung zu senden'}</InputMessage>
            ) : null}
          </div>
          <div className="sm:col-span-6 flex">
            <div className="mr-2">
              <Checkbox
                name="customEmail"
                label="Benutzerdefinierte E-Mail-Adresse hinzufügen"
                checked={isCustomEmail}
                onChange={() => {
                  if (isCustomEmail) {
                    setFieldValue('customEmail', '')
                  }
                  setIsCustomEmail(!isCustomEmail)
                }}
              />
            </div>
          </div>
          {isCustomEmail && values.sendConfirmation ? (
            <div className="sm:col-span-6">
              <InputLabel>{'E-Mail:'}</InputLabel>
              <div className="mt-1 rounded-md">
                <TextInput
                  value={values.customEmail || ''}
                  onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('customEmail', event.currentTarget.value)}
                  type="text"
                />
                {errors.customEmail && touched.customEmail ? <InputMessage kind={InputMessageKind.error}>{errors.customEmail}</InputMessage> : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AppointmentDetailsEditor
