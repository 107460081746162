import { gql } from 'graphql-request'

export const updatePTA = gql`
  mutation(
    $id: ID!
    $pharmacy_id: String
    $gender: String
    $first_name: String
    $last_name: String
    $address: String
    $zipcode: String
    $email: String
    $is_anonymized: Boolean
  ) {
    updatePTA(
      id: $id
      pharmacy_id: $pharmacy_id
      gender: $gender
      first_name: $first_name
      last_name: $last_name
      address: $address
      zipcode: $zipcode
      email: $email
      is_anonymized: $is_anonymized
    ) {
      id
      first_name
      last_name
      email
      is_campus_user
      completed_trainings
      zipcode
      is_anonymized
      pharmacy {
        id
        okid
      }
    }
  }
`
