import { QueryClient } from 'react-query'

export interface QueryOptions<T, ParsedT = T> {
  onError?: ((err: unknown) => void) | undefined
  onSuccess?: ((data: ParsedT) => void) | undefined
  select?: (data: T) => ParsedT
}

interface EditParams<T> {
  matchByKeys: (keyof T)[]
  cacheId: string | string[]
  queryClient: QueryClient
}
interface CreateParams {
  cacheId: string | string[]
  queryClient: QueryClient
}

export function handleOptimisticUpdate<T>({ matchByKeys, cacheId, queryClient }: EditParams<T>) {
  return async (variables: Partial<T>): Promise<void> => {
    await queryClient.cancelQueries(cacheId)
    const previousItems = queryClient.getQueryData<T[]>(cacheId)
    queryClient.setQueryData<T[]>(cacheId, (oldItems) => {
      if (!Array.isArray(oldItems)) {
        return []
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const selectedItemIndex = oldItems.findIndex((item) => matchByKeys.every((key) => item[key] === variables[key]))
      if (selectedItemIndex !== -1) {
        const newItems = [...oldItems]
        const updatedItem = { ...oldItems[selectedItemIndex], ...variables, id: -1 } as T
        newItems.splice(selectedItemIndex, 1, updatedItem)
        return newItems
      }
      return previousItems ?? []
    })
  }
}

export function handleOptimisticCreate<T>({ cacheId, queryClient }: CreateParams) {
  return async (variables: Partial<T>): Promise<void> => {
    await queryClient.cancelQueries(cacheId)
    queryClient.setQueryData<T[]>(cacheId, (oldItems) => {
      if (!Array.isArray(oldItems)) {
        return []
      }
      const newItem = { ...variables } as T
      return [...oldItems, newItem]
    })
  }
}

export function handleOptimisticDelete<T>({ matchByKeys, cacheId, queryClient }: EditParams<T>) {
  return async (variables: Partial<T>): Promise<void> => {
    await queryClient.cancelQueries(cacheId)
    const previousItems = queryClient.getQueryData<T[]>(cacheId)
    queryClient.setQueryData<T[]>(cacheId, (oldItems) => {
      if (!Array.isArray(oldItems)) {
        return []
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const selectedItemIndex = oldItems.findIndex((item) => matchByKeys.every((key) => item[key] === variables[key]))
      if (selectedItemIndex !== -1) {
        const newItems = [...oldItems]
        newItems.splice(selectedItemIndex, 1)
        return newItems
      }
      return previousItems ?? []
    })
  }
}
