import { gql } from 'graphql-request'

export const createOfftime = gql`
  mutation($offtime_type: String!, $whole_day: Boolean!, $date: DateTimeUtc!) {
    createOfftime(offtime_type: $offtime_type, whole_day: $whole_day, date: $date) {
      id
      offtime_type
    }
  }
`

export const deleteOfftime = gql`
  mutation($id: ID!) {
    deleteOfftime(id: $id) {
      id
    }
  }
`
