import { gql } from 'graphql-request'

export const getAppointmentTerminationReasons = gql`
  {
    termination_reasons {
      id
      note_de
      note_en
    }
  }
`
