import React, { FunctionComponent } from 'react'

export enum TimeSlotKind {
  available = 'text-gray-700 bg-white border-gray-350 hover:bg-gray-900 hover:text-white active:bg-gray-900',
  homeOffice = 'text-blue-700 bg-striped-blue border-gray-350',
  booked = 'text-blue-700 bg-blue-100 border-gray-350 hover:bg-blue-600 hover:text-white active:bg-blue-700',
  vacation = 'text-green-900 bg-striped-green border-gray-350',
  blocked = 'text-gray-600 bg-striped-gray border-gray-200',

  conference = 'text-yellow-900 bg-striped-yellow border-gray-350',
  privat = 'text-gray-900 bg-striped-darkgray border-gray-350',
  festive = 'text-purple-900 bg-striped-purple border-gray-350',
  disabled = 'text-gray-600 bg-striped-gray border-gray-350',
  disease = 'text-red-700 bg-striped-red border-gray-350',
  today = 'text-red-800 bg-red-300  border-gray-350 hover:bg-red-600 hover:text-white active:bg-red-800',
  deleted = 'text-white bg-red-800  border-gray-350 hover:bg-red-600 hover:text-white active:bg-red-800 line-through',
  missed = 'text-white bg-red-400  border-gray-350',
  finished = 'text-green-700 bg-green-100 border-gray-350 hover:bg-green-600 hover:text-white active:bg-green-800',
  selected = 'text-blue-gray-800 bg-green-300-hi border-gray-350 hover:bg-green-300-hi hover:text-white active:bg-green-300-hi',
  unoccupied = 'text-purple-700 bg-striped-purple border-gray-350',
  publicHoliday = 'text-pink-500 bg-striped-pink border-gray-350',
}
export type OffTimeMutationVariables = {
  offtime_type: string
  whole_day: boolean
  date: string
}

export type TimeSlotProps = {
  kind: TimeSlotKind
  date?: Date
  title?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
}

export type DeleteOfftimeType = {
  offtimeId: string
  date?: Date
}
const formattedTimeString = (dateForFormat: Date) => {
  const t = dateForFormat
  const hh = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(t)
  const nextIntHour = Number(hh) + 1

  const time = hh !== '18' ? `${hh}-${nextIntHour < 10 ? `0${nextIntHour}` : nextIntHour}` : null
  return time
}

const TimeSlot: FunctionComponent<TimeSlotProps> = ({ disabled, kind = TimeSlotKind.available, title, onClick, date, children }) => {
  const timeString = formattedTimeString(date)
  return (
    <>
      {timeString ? (
        <button
          type="button"
          disabled={disabled}
          title={title}
          onClick={onClick}
          className={`col-span-1 flex justify-center items-center text-center border-r-1  font-body text-base cursor-pointer  py-0.5 transition duration-300 ease-in-out hover:shadow-md ${kind}`}
        >
          {children || timeString}
        </button>
      ) : null}
    </>
  )
}

export default TimeSlot
