import { SectionCard } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import { ModalKind, useModal } from '../../contexts/modalContext'
import ProjectList from '../ProjectList'

export interface ProjectsCardProps {
  showAddButton: boolean
  showSpecialButton: boolean
  pharmacyId: number
}

const ProjectsCard: React.FunctionComponent<ProjectsCardProps> = ({ pharmacyId, showAddButton, showSpecialButton }) => {
  const { openModal } = useModal()

  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      title="Status zu Projekten"
      onEdit={() => openModal({ kind: ModalKind.ProjectsEdit, pharmacyId })}
      showAddButton={showAddButton}
      showSpecialButton={showSpecialButton}
      showAllEntries={true}
    >
      <ProjectList pharmacyId={pharmacyId} />
    </SectionCard>
  )
}

export default ProjectsCard
