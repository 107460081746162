import { useQuery } from 'react-query'
import { allQuarters, Quarter, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'

export const useQuartersQuery = (options?: QueryOptions<Quarter[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()

  return useQuery(
    'quarters',
    async () => {
      const data = await gqlClient.request<{ quarters: Quarter[] }>(allQuarters)
      return data?.quarters || []
    },
    { enabled: !!auth.user, staleTime: Infinity, ...options },
  )
}
