const HomeMarker = (): string => `
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="61.9691" viewBox="0 0 52 61.9691">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path d="M51,26A25.01,25.01,0,0,1,31.7908,50.3259L26,60.0192,20.2093,50.326A25.0026,25.0026,0,1,1,51,26Z"
              fill="#00a07b" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        <polyline
          points="30.429 25.594 21.641 25.594 21.641 38.087 13.276 38.087 13.276 19.068 26 10.21 38.724 19.068 38.724 38.087 30.605 38.087"
          fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
  </svg>
`
export default HomeMarker
