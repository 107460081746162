import React, { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent, HTMLProps } from 'react'

export enum ButtonKind {
  primary = 'bg-blue-600 text-white border-blue-600 border border-solid hover:bg-blue-800 hover:border-blue-800',
  outlinedPrimary = 'bg-white text-blue-700 border-blue-600 border border-solid hover:bg-blue-200',

  secondary = 'bg-gray-600 text-white border-gray-600 border border-solid hover:bg-gray-900 hover:border-gray-900',
  outlinedSecondary = 'bg-white text-gray-700 border-gray-600 border border-solid hover:bg-gray-300',

  secondaryGreen = 'bg-green-600 text-white border-green-600 border border-solid hover:bg-green-900 hover:border-green-900',
  outlinedSecondaryGreen = 'bg-white text-green-700 border-green-600 border border-solid hover:bg-green-300-hi',

  danger = 'bg-red-600 text-white hover:bg-red-800',
  outlinedDanger = 'bg-white border-red-600 border border-solid text-red-600 hover:bg-red-600 hover:text-white',
}

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  kind: ButtonKind
  disabled?: boolean
  icon?: React.ReactNode
  onClick?: () => void
  children?: React.ReactNode
  title?: string | null
  customStyle?: string
}

const Button: FunctionComponent<ButtonProps> = ({
  kind = ButtonKind.primary,
  disabled,
  icon,
  onClick,
  children,
  title = null,
  type = 'button',
  customStyle,
  ...buttonProps
}) => {
  const disabledClass = disabled ? 'opacity-50 cursor-not-allowed' : ''
  const handleOnclick = () => {
    if (!onClick) {
      return
    }
    onClick()
  }
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <button
        disabled={disabled}
        onClick={handleOnclick}
        className={`font-body inline-flex items-center px-4 py-1.5 h-10 text-sm xl:text-base leading-6 font-medium rounded-md transition ease-in-out duration-150 hover:shadow-sm ${kind} ${disabledClass} ${customStyle}`}
        type={type}
        {...buttonProps}
      >
        {icon ? <div className="text-current -mt-px -ml-3 mr-1">{icon}</div> : <React.Fragment></React.Fragment>}
        <span className="-mt-px" title={title}>
          {children}
        </span>
      </button>
    </span>
  )
}

export default Button
