import React, { DetailedHTMLProps, FunctionComponent, LabelHTMLAttributes } from 'react'

export interface InputLabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  disabled?: boolean
  children?: React.ReactNode
}

const InputLabel: FunctionComponent<InputLabelProps> = ({ disabled, children, className, ...labelProps }) => {
  const disabledClass = disabled ? 'opacity-50' : ''

  const classNameToUse = `block text-sm font-medium leading-5 text-gray-900 tracking-wide uppercase ${disabledClass} ${className ? className : ''}`

  return (
    <label {...labelProps} className={classNameToUse}>
      {children}
    </label>
  )
}

export default InputLabel
