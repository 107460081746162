import React from 'react'

const SearchIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <g id="Gruppe_72" style={{ transform: 'translate(10px, 10px)' }} data-name="Gruppe 72" transform="translate(-0.023 0.216)">
      <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(0.023 -0.216)" fill="none" stroke="currentColor" strokeWidth="2">
        <ellipse cx="8" cy="8.5" rx="8" ry="8.5" stroke="none" />
        <ellipse cx="8" cy="8.5" rx="7" ry="7.5" fill="none" />
      </g>
      <line
        id="Linie_16"
        data-name="Linie 16"
        x2="5"
        y2="5"
        transform="translate(14.023 13.784)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default SearchIcon
