import { createOfftime, deleteOfftime, Offtime, useAuth, useGqlClient, userOfftimes } from '@aposphaere/core-kit'
import { OffTimeMutationVariables } from '@aposphaere/ui-components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { handleOptimisticCreate, handleOptimisticDelete, QueryOptions } from './utils'

const CACHE_ID = 'offtimes'

/*
 * QUERIES
 */
export const useOfftimesQuery = (options?: QueryOptions<Offtime[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery(
    CACHE_ID,
    async () => {
      const result = await gqlClient.request<{ offtimes: Offtime[] }>(userOfftimes, { userId: auth.user?.id })
      return result?.offtimes
    },
    { enabled: !!auth.user, ...options },
  )
}

/*
 * MUTATIONS
 */
interface DeleteOfftimeMutationVariables {
  id: string
}

export const useDeleteOfftimeMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  return useMutation(async (variables: DeleteOfftimeMutationVariables) => await gqlClient.request<unknown>(deleteOfftime, variables), {
    onMutate: handleOptimisticDelete({ cacheId: CACHE_ID, matchByKeys: ['id'], queryClient }),
    onSettled: () => queryClient.invalidateQueries(CACHE_ID),
    ...options,
  })
}

export const useCreateOfftimeMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  return useMutation(async (variables: OffTimeMutationVariables) => await gqlClient.request<unknown>(createOfftime, variables), {
    onMutate: handleOptimisticCreate({ cacheId: CACHE_ID, queryClient }),
    onSettled: () => queryClient.invalidateQueries(CACHE_ID),
    ...options,
  })
}
