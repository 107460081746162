import React from 'react'

const LocationProfilIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M27,14.4245V26.6456A2.4249,2.4249,0,0,1,24.51,29H11.49A2.4249,2.4249,0,0,1,9,26.6456V10.3544A2.4249,2.4249,0,0,1,11.49,8h8.7168"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="23.205 14 21 14 21 11.79 21.013 8 27 14"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="14" y1="19.0576" x2="22" y2="19.0576" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="14" y1="23" x2="22" y2="23" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="14" y1="15.0288" x2="17" y2="15.0288" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export default LocationProfilIcon
