import { useCallback, useMemo, useState } from 'react'
import { IAuthContext } from '../contexts'
import { AUTH_UNAUTHENTICATED_EVENT } from '../events'
import { ApoClient, getUser, signIn as signInMutation } from '../graphql'
import { MeResponse, SignInResponse, User, userActions } from '../models'
import { useLocalStorage } from './useLocalStorage'

const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'auth-token'

/**
 * The concrete implementation of AuthContext as a custom Hook.
 */

export const useAuthProvider = (): IAuthContext => {
  const client = useMemo(() => new ApoClient(), [])
  const [user, setUser] = useState<User>()
  const [token, setToken] = useLocalStorage(AUTH_TOKEN_LOCAL_STORAGE_KEY, '')

  const abilities = useMemo<Set<typeof userActions[number]>>(() => {
    const setOfAbilities = new Set<typeof userActions[number]>()
    if (user) {
      user.roles.map((role) => role.abilities.map((ability) => setOfAbilities.add(ability.name)))
      return setOfAbilities
    }
    return setOfAbilities
  }, [user])

  const getUserData = useCallback(async () => {
    try {
      if (token !== '') {
        client.addHeader('Authorization', token)
      }

      const response = await client.request<MeResponse>(getUser)
      const currentUser = response.data?.me
      setUser(currentUser)
    } catch (error: unknown) {
      window.dispatchEvent(new Event(AUTH_UNAUTHENTICATED_EVENT))
    }
  }, [client, token])

  // implementation goes here
  const signIn = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await client.request<SignInResponse>(signInMutation, { email, password })
      const authToken = response.data?.signin.token
      setToken(authToken)
      client.addHeader('Authorization', authToken)
      return true
    } catch {
      console.error('Sign in Request went wrong')
    }

    return false
  }

  const signOut = () => {
    setToken('')
    setUser(null)
    client.addHeader('Authorization', '')
  }

  return {
    abilities,
    user,
    token,
    signIn,
    signOut,
    getUserData,
  }
}
