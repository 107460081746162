import { useMemo } from 'react'
import { userActions } from '../models'
import { useAuth } from './useAuth'

export const useAbilities = (): Record<typeof userActions[number], boolean> => {
  const { abilities } = useAuth()

  return useMemo(
    () =>
      userActions.reduce((acc, el: typeof userActions[number]) => {
        acc[el] = abilities.has(el)
        return acc
      }, {} as Record<typeof userActions[number], boolean>),
    [abilities],
  )
}
