import {
  createNoteForWeekPharmacyCluster,
  deleteNoteWeekForPharmacyCluster,
  getNoteWeekPharmacyClusterAll,
  NoteWeek,
  NoteWeekResponse,
  updateNoteWeekForPharmacyCluster,
  useAuth,
  useGqlClient,
} from '@aposphaere/core-kit'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useCrmContext } from '../../contexts/crmContext'
import { handleOptimisticCreate, handleOptimisticDelete, handleOptimisticUpdate, QueryOptions } from './utils'

/*
 * QUERIES
 */
export const useNoteWeeksQuery = (options?: QueryOptions<NoteWeek[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  const { activeCluster } = useCrmContext()

  return useQuery<NoteWeek[]>(
    ['note-weeks', `cluster-${activeCluster.id}`],
    async () => {
      const response = await gqlClient.request<NoteWeekResponse>(getNoteWeekPharmacyClusterAll, { pharmacy_cluster_id: activeCluster.id })
      return response?.noteWeekPharmacyClusterAll
    },
    {
      enabled: !!auth.user,
      ...options,
    },
  )
}

/*
 * MUTATIONS
 */

type NoteWeekCreate = {
  year: string
  week: string
  pharmacy_cluster_id: string
  note: string
}

export const useNoteWeekCreateMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeCluster } = useCrmContext()

  const cacheId = ['note-weeks', `cluster-${activeCluster.id}`]
  return useMutation(async (variables: NoteWeekCreate) => await gqlClient.request<unknown>(createNoteForWeekPharmacyCluster, variables), {
    onMutate: handleOptimisticCreate({ cacheId, queryClient }),
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}

type NoteWeekUpdate = {
  id: number | undefined
  note: string
}

export const useNoteWeekUpdateMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeCluster } = useCrmContext()

  const cacheId = ['note-weeks', `cluster-${activeCluster.id}`]

  return useMutation(async (variables: NoteWeekUpdate) => await gqlClient.request<unknown>(updateNoteWeekForPharmacyCluster, variables), {
    onMutate: handleOptimisticUpdate({ cacheId, matchByKeys: ['id'], queryClient }),
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}

type NoteWeekDelete = {
  id: number
}

export const useNoteWeekDeleteMutation = (options?: QueryOptions<unknown>) => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const { activeCluster } = useCrmContext()

  const cacheId = ['note-weeks', `cluster-${activeCluster.id}`]

  return useMutation(async (variables: NoteWeekDelete) => await gqlClient.request<unknown>(deleteNoteWeekForPharmacyCluster, variables), {
    onMutate: handleOptimisticDelete({ cacheId, matchByKeys: ['id'], queryClient }),
    onSettled: () => queryClient.invalidateQueries(cacheId),
    ...options,
  })
}
