import React, { FunctionComponent } from 'react'

export type CalendarProps = {
  children?: React.ReactNode
}

const Calendar: FunctionComponent<CalendarProps> = ({ children }) => (
  <div className="w-full h-full pr-2 pt-3 pb-6">
    <div className="w-full mb-0 hidden">
      <div className="flex row font-normal pt-2 ml-6 font-body tracking-wider text-gray-600">
        <div className="text-xs font-medium tracking-wider w-15pc pb-0 uppercase">{'Datum'}</div>
        <div className="text-xs font-medium tracking-wider flex flex-wrap w-80pc ml-4 pb-0 uppercase">{'Zeitslots'}</div>
        <div className="text-xs font-medium tracking-wider flex w-5pc ml-6 pb-0 justify-center items-center uppercase">{'Bes.'}</div>
      </div>
    </div>
    <div className="hidden h-1px items-center row ml-6 mt-1 bg-white relative">
      <div className="h-1px border-t border-gray-400 border-solid w-placeholder-kw -ml-6"></div>
      <div className="flex items-center h-1px w-80pc ml-1">
        <div className="flex h-1px border-t border-gray-400 border-solid w-full m-1 "></div>
      </div>
      <div className="h-1px border-t border-gray-400 border-solid w-5pc flex ml-1"></div>
    </div>
    {children}
  </div>
)

export default Calendar
