import React, { FunctionComponent } from 'react'

export type WeekItemProps = {
  weekNumber: number
  children?: React.ReactNode
  appendComponent?: React.ReactNode
}

const WeekItem: FunctionComponent<WeekItemProps> = ({ appendComponent, children, weekNumber }) => (
  <>
    <div className="w-full flex h-8 items-center row ml-6 pt-2 bg-white relative">
      <div className="w-full flex items-center justify-center bg-white h-5 px-2 text-sm lg:text-base text-black-200 text-center font-medium -mt-1">
        <span className="pr-2">KW</span>
        {weekNumber}
        {appendComponent ? appendComponent : null}
      </div>
    </div>
    {children}
  </>
)

export default React.memo(WeekItem)
