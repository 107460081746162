import React, { HTMLInputTypeAttribute } from 'react'
import { InputLabel } from '..'

interface Props {
  name: string
  id?: string
  label: string
  checked: boolean
  labelBefore?: boolean
  labelClass?: string
}

const Checkbox = ({ id, label, labelBefore, labelClass, ...props }: Props & React.HTMLProps<HTMLInputElement>) => (
  <div className="flex items-center">
    {labelBefore && (
      <InputLabel className={labelClass ?? ''} htmlFor={props.name}>
        {label}
      </InputLabel>
    )}
    <input id={id || props.name} type="checkbox" className="mr-3" {...props} />
    {!labelBefore && <InputLabel htmlFor={props.name}>{label}</InputLabel>}
  </div>
)

export default Checkbox
