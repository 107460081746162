import React from 'react'

const PlusSignIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width={dimension} height={dimension} viewBox="0 0 36 36">
    <path d="M18,28a10,10,0,1,1,7.6175-3.521" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="12.9383" y1="18" x2="23.0617" y2="18" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="18" y1="23.0617" x2="18" y2="12.9383" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export default PlusSignIcon
