import { Reminder, parseUTC } from '@aposphaere/core-kit'
import { DeleteIcon, EditIcon, IconButton, IconButtonKind, ReminderIcon } from '@aposphaere/ui-components'
import { format } from 'date-fns'
import React from 'react'

type RemindersCardItemProps = {
  reminder: Reminder
  onOpenModal: [() => void, () => void]
  canEditReminders: boolean
  canDeleteReminders: boolean
}

const ReminderCardItem: React.FunctionComponent<RemindersCardItemProps> = ({ reminder, onOpenModal, canEditReminders, canDeleteReminders }) => {
  const time = format(parseUTC(reminder.created_at), 'dd.MM.yyyy HH:mm')?.split(' ')
  const [onEditReminder, onDeleteReminder] = onOpenModal

  const deleteModalOpen = () => {
    onDeleteReminder()
  }

  const editModalOpen = () => {
    onEditReminder()
  }

  return (
    <li className="w-full relative px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="flex justify-between gap-2 py-2 2xl:py-4">
        <div className="flex w-2/12">
          <div className="text-base leading-6 font-medium">
            <span className="font-medium">{time?.[0]} </span>
            <br />
            <span className="font-normal italic text-gray-600">{time?.[1]}</span>
          </div>
        </div>
        <div className="hidden xl:flex xl:w-4/12">
          <div className="text-base leading-6 font-medium">
            <p className="font-normal">{reminder.note}</p>
            <span className="font-normal italic text-gray-600">{`Wiedervorlage, ${reminder.contact_person || ''}`}</span>
          </div>
        </div>
        <div className="flex w-8/12 pl-2 xl:w-4/12">
          <div className="text-base leading-6 font-medium">
            <h4 className="font-normal">{`Wiedervorlage:`}</h4>
            <span className="font-normal">{`${reminder?.user?.name}`}</span>
          </div>
        </div>
        <div className="flex w-2/12 items-start justify-end">
          <IconButton additionalCss="text-yellow-600" icon={<ReminderIcon dimension="28" />} kind={IconButtonKind.default} />
          {canDeleteReminders && <IconButton icon={<DeleteIcon dimension="28" />} onClick={deleteModalOpen} kind={IconButtonKind.default} />}
          {canEditReminders && <IconButton icon={<EditIcon dimension="28" />} onClick={editModalOpen} kind={IconButtonKind.default} />}
        </div>
      </div>
    </li>
  )
}

export default ReminderCardItem
