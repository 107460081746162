import React, { FormEventHandler, useCallback, useState } from 'react'
import { Button, ButtonKind, Checkbox, toast } from '@aposphaere/ui-components'
import { Offtime, parseUTC } from '@aposphaere/core-kit'
import { useDeleteOfftimeMutation } from '../../hooks/graphql'
import { format, compareDesc } from 'date-fns'

export type DeleteModalProps = {
  offtimesToDelete: Offtime[]
  onClose: () => void
}
const OfftimeDeleteModal: React.FunctionComponent<DeleteModalProps> = ({ offtimesToDelete, onClose }) => {
  offtimesToDelete.sort((a, b) => compareDesc(parseUTC(a.date!), parseUTC(b.date!)))
  const parsedOfftimes = offtimesToDelete.filter(({ id }) => id)
  const [selectedForDeletion, setSelectedForDeletion] = useState(parsedOfftimes.map(({ id }) => id.toString()))
  const isSingleOfftime = parsedOfftimes.length === 1

  const { mutate: deleteOfftime } = useDeleteOfftimeMutation()

  const deleteUserTimeOff = useCallback(() => {
    selectedForDeletion.forEach((id) => {
      deleteOfftime({ id })
    })
    onClose()
    toast.show({
      headline: 'Auszeit wurde erfolgreich gelöscht',
      type: 'success',
    })
  }, [onClose, deleteOfftime, selectedForDeletion])

  const handleCheckboxChange: FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const checkboxValue = event.currentTarget.id
      let selected = [...selectedForDeletion, checkboxValue]
      if (selectedForDeletion.includes(checkboxValue)) {
        selected = selected.filter((id) => id !== checkboxValue)
      }
      setSelectedForDeletion(selected)
    },
    [setSelectedForDeletion, selectedForDeletion],
  )

  const instruction =
    isSingleOfftime && parsedOfftimes[0]['whole_day']
      ? 'Möchten Sie wirklich alle Ruhezeiten für diesen Tag löschen? Diese Aktion kann nicht rückgängig gemacht werden.'
      : isSingleOfftime
      ? 'Möchten Sie den Terminblocker wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.'
      : 'Welche Terminblocker möchten Sie löschen?'

  return (
    <div className="fixed z-9999 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Auszeit löschen
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{instruction}</p>
                {!isSingleOfftime && (
                  <div className="mt-1">
                    {parsedOfftimes.map(({ id, date }) => (
                      <Checkbox
                        key={id}
                        id={id.toString()}
                        checked={selectedForDeletion.includes(id.toString())}
                        name={date!}
                        label={date ? format(parseUTC(date), 'dd.MM.yyyy HH:mm') : ''}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button kind={ButtonKind.danger} disabled={!selectedForDeletion.length} onClick={deleteUserTimeOff} customStyle="md:ml-3 w-full">
              Löschen
            </Button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfftimeDeleteModal
