import React from 'react'

const SwitchOffIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M22.5,13h-9a5.5,5.5,0,0,0,0,11h9a5.5,5.5,0,0,0,0-11Zm-9,10A4.5,4.5,0,1,1,18,18.5,4.5055,4.5055,0,0,1,13.5,23Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path d="M22.5,13h-9a5.5,5.5,0,0,0,0,11h9a5.5,5.5,0,0,0,0-11Zm-9,10A4.5,4.5,0,1,1,18,18.5,4.5055,4.5055,0,0,1,13.5,23Z" />
  </svg>
)

export default SwitchOffIcon
