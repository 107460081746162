import React, { FunctionComponent } from 'react'
import IconButton, { IconButtonKind } from '../IconButton'
import { CloseIcon } from '../Icons'

export type RouteModalProps = {
  children?: React.ReactNode
  title: string
  headerAdditionalButtons?: React.ReactNode
  onClose: () => void
  viewPortWidth?: number | undefined
}

const BREAK_POINT = 1024

const RouteModal: FunctionComponent<RouteModalProps> = ({ children, title, headerAdditionalButtons, onClose, viewPortWidth }) => (
  <div className="absolute bottom-0 right-14  xl:right-12 flex h-fit z-50">
    <div className={`bg-blue-gray-800 rounded-lg shadow-lg z-50 w-56 xl:w-310px max-h-64 xl:max-h-96 overflow-y-auto`}>
      <div className="text-left h-full">
        <div className="flex justify-between items-center pb-0 pt-1 px-3 xl:pb-3 xl:pt-4 xl:px-4 sticky top-0 z-50 bg-blue-gray-800 border-solid border-b border-gray-700">
          <div className="flex">
            <p className={`xl:text-base text-sm font-medium font-body text-white`}>{title}</p>
          </div>
          <div className="flex cursor-pointer z-50 justify-end -mr-1">
            {headerAdditionalButtons}
            <IconButton
              kind={IconButtonKind.custom}
              additionalCss="text-gray-600"
              onClick={onClose}
              icon={<CloseIcon dimension={viewPortWidth && viewPortWidth <= BREAK_POINT ? '30' : '36'} />}
            />
          </div>
        </div>
        <div className="flex w-full flex-wrap px-2 xl:px-5 py-3 h-full text-white">
          <ul className="text-base w-full font-body">{children}</ul>
        </div>
      </div>
    </div>
  </div>
)

export default RouteModal
