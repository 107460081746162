import { Appointment, Pharmacy } from '@aposphaere/core-kit'
import { IFilterByOption } from '@aposphaere/ui-components'
import React, { createContext, PropsWithChildren, useContext, useMemo, useCallback, useReducer } from 'react'
import { VISIT_APPOINTMENT_TYPE_FRAGMENT } from '../constants'
import { useAppointmentsQuery, usePharmaciesQuery } from '../hooks/graphql'
import { filterMapReducer, initialMapFilterState, IMapFilterState, IMapFilterAction } from './reducers/mapFilterReducer'

export interface IPharmacyFilterContext {
  filterState: IMapFilterState
  dispatchFilterState: React.Dispatch<IMapFilterAction>
  filteredPharmacies: Pharmacy[]
  areProjectsSelected: boolean
}

export const PharmacyFilterContext = createContext<IPharmacyFilterContext | null>(null)

const PharmacyFilterProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data: pharmacies } = usePharmaciesQuery()
  const { data: appointments } = useAppointmentsQuery()
  const [filterState, dispatchFilterState] = useReducer(filterMapReducer, initialMapFilterState)

  const areProjectsSelected = filterState.selectedProjects.length > 0
  const areFiltersActive = areProjectsSelected || filterState.searchTerm

  const fitsOnlyPriority = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.isOnlyPriority) {
        return true
      }

      return pharmacy.isPriority
    },
    [filterState.isOnlyPriority],
  )

  const fitsTerminatedProjects = useCallback(
    (pharmacyProjects: { id: string; hasStatus: boolean }[]) => {
      if (filterState.includesTerminatedProjects) {
        return true
      }
      const isEveryProjectTerminated = pharmacyProjects.every(({ hasStatus }) => hasStatus)
      return !isEveryProjectTerminated
    },
    [filterState.includesTerminatedProjects],
  )

  const fitsAvailableForCallcenter = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.isAvailableForCallcenter) {
        return true
      }
      return pharmacy.available_for_callcenter
    },
    [filterState.isAvailableForCallcenter],
  )

  const fitsHasAppointmentsScheduled = useCallback(
    (pharmacy: Pharmacy, pharmacyProjects: { id: string; hasStatus: boolean }[]) => {
      if (filterState.onlyWithScheduledAppointments) {
        return true
      }
      const projectsWithAppointments =
        appointments
          ?.filter(
            ({ appointmentType, pharmacy: appointmentPharmacy }) =>
              !appointmentType?.label_short?.toLowerCase().includes(VISIT_APPOINTMENT_TYPE_FRAGMENT) &&
              pharmacy?.id.toString() === appointmentPharmacy?.id.toString(),
          )
          .reduce((acc: string[], curr: Appointment) => {
            const projectIds = curr.order_items.map(({ project_id }) => project_id.toString())
            return acc.concat(projectIds)
          }, []) ?? []

      const isEveryProjectBooked = pharmacyProjects.every(({ id: projectId }) => projectsWithAppointments.includes(projectId))

      return !isEveryProjectBooked
    },
    [filterState.onlyWithScheduledAppointments, appointments],
  )

  const fitsSelectFilter = useCallback(
    (pharmacy: Pharmacy) => {
      if (!areProjectsSelected) {
        return true
      }
      const relevantPharmacyProjects = pharmacy.projects.filter(({ id: projectId }) => filterState.selectedProjects.includes(projectId.toString()))

      if (!relevantPharmacyProjects.length) {
        return false
      }

      const pharmacyProjects = relevantPharmacyProjects.map((project) => ({ id: project.id.toString(), hasStatus: !!project.pivot?.status_id }))

      if (filterState.filterByOption === IFilterByOption.AND) {
        const hasEveryProjectSelected = !!filterState.selectedProjects.every((projectId) => !!pharmacyProjects.find(({ id }) => id === projectId))

        if (!hasEveryProjectSelected) {
          return false
        }
      }

      return (
        fitsTerminatedProjects(pharmacyProjects) &&
        fitsHasAppointmentsScheduled(pharmacy, pharmacyProjects) &&
        fitsAvailableForCallcenter(pharmacy) &&
        fitsOnlyPriority(pharmacy)
      )
    },
    [
      filterState.selectedProjects,
      fitsHasAppointmentsScheduled,
      fitsAvailableForCallcenter,
      fitsTerminatedProjects,
      fitsOnlyPriority,
      filterState.filterByOption,
      areProjectsSelected,
    ],
  )

  const fitsSearchTerm = useCallback(
    (pharmacy: Pharmacy) => {
      if (!filterState.searchTerm) {
        return true
      }

      const lowerCaseSearchTerm = filterState.searchTerm.trim().toLowerCase()
      const ifFitsName = pharmacy?.name?.toLowerCase().includes(lowerCaseSearchTerm)
      const ifFitsOkId = pharmacy?.okid?.toLowerCase().includes(lowerCaseSearchTerm)
      const ifFitsId = Number(pharmacy?.id) === Number(lowerCaseSearchTerm)
      return ifFitsName || ifFitsOkId || ifFitsId
    },
    [filterState.searchTerm],
  )

  const filteredPharmacies = useMemo(() => {
    if (!areFiltersActive) {
      return pharmacies ?? []
    }
    return pharmacies?.filter((pharmacy) => fitsSearchTerm(pharmacy) && fitsSelectFilter(pharmacy)) ?? []
  }, [pharmacies, fitsSearchTerm, fitsSelectFilter, areFiltersActive])

  const value = useMemo(
    () => ({
      filterState,
      dispatchFilterState,
      filteredPharmacies,
      areProjectsSelected,
    }),
    [filterState, dispatchFilterState, filteredPharmacies, areProjectsSelected],
  )

  return <PharmacyFilterContext.Provider value={value}>{children}</PharmacyFilterContext.Provider>
}

export const usePharmacyFilterContext = () => {
  const context = useContext(PharmacyFilterContext)
  if (!context) {
    throw new Error("Provider not found: Attemting to use the filter context, without it's provider")
  }
  return context
}

export default PharmacyFilterProvider
