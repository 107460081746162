import React from 'react'

const DangerIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M26.4352,28.0023H9.528c-1.7429,0-2.7262-2.2647-1.87-3.7826l8.34-15.0965a2.277,2.277,0,0,1,3.9661,0l5.07,8.6673,1.4812,2.6247"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="18" y1="14" x2="18" y2="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line x1="18" y1="24" x2="18" y2="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.6" />
  </svg>
)

export default DangerIcon
