import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import AppointmentModal from '../components/AppointmentModal'
import ExportModal from '../components/ExportModal'
import NoteModal from '../components/NoteModal'
import PersonenModal from '../components/personenModal'
import PharmacyModal from '../components/PharmacyModal'
import DeactivatePharmacyModal from '../components/DeactivatePharmacyModal'
import ProjectsModal from '../components/ProjectsModal'
import NoteWeekPharmacyClusterModal from '../components/NoteWeekPharmacyClusterModal'
import ReminderModal from '../components/ReminderModal'
import { Offtime, PharmacyStatus } from '@aposphaere/core-kit'
import OfftimeDeleteModal from '../components/OfftimeDeleteModal'
/*
 * ENUM
 */

export enum ModalKind {
  AppointmentCreate,
  AppointmentEdit,

  Export,

  NoteCreate,
  NoteEdit,
  NoteDelete,

  ReminderCreate,
  ReminderEdit,
  ReminderDelete,

  PharmacyDeactivate,
  PharmacyUpdate,

  ProjectsEdit,
  PersonenEdit,

  WeekNoteCreate,
  WeekNoteUpdate,
  WeekNoteDelete,

  OfftimesDelete,
}
/*
 * TYPES
 */

interface IModalContext {
  openModal: (params: ModalProps) => void
  closeModal: () => void
}

type ModalProps =
  | {
      kind: ModalKind.NoteCreate | ModalKind.ProjectsEdit | ModalKind.ReminderCreate | ModalKind.AppointmentCreate
      pharmacyId: number
    }
  | {
      kind: ModalKind.WeekNoteCreate | ModalKind.WeekNoteDelete | ModalKind.WeekNoteUpdate
      week: number
      year: number
    }
  | {
      kind:
        | ModalKind.AppointmentEdit
        | ModalKind.NoteDelete
        | ModalKind.NoteEdit
        | ModalKind.ReminderDelete
        | ModalKind.ReminderEdit
        | ModalKind.PharmacyUpdate
        | ModalKind.PharmacyDeactivate
      id: number
      status?: PharmacyStatus
    }
  | {
      kind: ModalKind.Export
    }
  | {
      kind: ModalKind.PersonenEdit
      pharmacyId: number
      id: number
    }
  | { kind: ModalKind.OfftimesDelete; offtimes: Offtime[] }

/*
 * CONTEXT
 */
const ModalContext = createContext<IModalContext | null>(null)
ModalContext.displayName = 'ModalContext'
/*
 * PROVIDER
 */
export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [activeModal, setActiveModal] = useState<ModalProps | null>()

  const openModal = useCallback((params: ModalProps) => {
    setActiveModal(params)
  }, [])
  const closeModal = useCallback(() => {
    setActiveModal(null)
  }, [])

  return (
    <ModalContext.Provider value={{ closeModal, openModal }}>
      {activeModal ? <ModalRenderer {...activeModal} onClose={closeModal} /> : null}

      {children}
    </ModalContext.Provider>
  )
}
/*
 * RENDERER
 */
const ModalRenderer = (props: ModalProps & { onClose: () => void }) => {
  switch (props.kind) {
    case ModalKind.AppointmentCreate: {
      return <AppointmentModal pharmacyId={props.pharmacyId} onClose={props.onClose} />
    }
    case ModalKind.AppointmentEdit: {
      return <AppointmentModal appointmentId={props.id} onClose={props.onClose} />
    }
    case ModalKind.Export: {
      return <ExportModal onClose={props.onClose} />
    }
    case ModalKind.NoteCreate: {
      return <NoteModal mode="create" pharmacyId={props.pharmacyId} />
    }
    case ModalKind.NoteEdit: {
      return <NoteModal mode="update" noteId={props.id} />
    }
    case ModalKind.NoteDelete: {
      return <NoteModal mode="delete" noteId={props.id} />
    }
    case ModalKind.PersonenEdit: {
      return <PersonenModal mode="edit" id={props.id} pharmacyId={props.pharmacyId} />
    }
    case ModalKind.PharmacyDeactivate: {
      return <DeactivatePharmacyModal id={props.id} status={props.status} />
    }
    case ModalKind.PharmacyUpdate: {
      return <PharmacyModal id={props.id} />
    }
    case ModalKind.ProjectsEdit: {
      return <ProjectsModal pharmacyId={props.pharmacyId} />
    }
    case ModalKind.WeekNoteCreate: {
      return <NoteWeekPharmacyClusterModal mode="create" week={props.week} year={props.year} onClose={props.onClose} />
    }
    case ModalKind.WeekNoteUpdate: {
      return <NoteWeekPharmacyClusterModal mode="edit" week={props.week} year={props.year} onClose={props.onClose} />
    }
    case ModalKind.WeekNoteDelete: {
      return <NoteWeekPharmacyClusterModal mode="delete" week={props.week} year={props.year} onClose={props.onClose} />
    }
    case ModalKind.ReminderCreate: {
      return <ReminderModal mode="create" pharmacyId={props.pharmacyId} />
    }
    case ModalKind.ReminderDelete: {
      return <ReminderModal mode="delete" id={props.id} />
    }
    case ModalKind.ReminderEdit: {
      return <ReminderModal mode="edit" id={props.id} />
    }
    case ModalKind.OfftimesDelete: {
      return <OfftimeDeleteModal onClose={props.onClose} offtimesToDelete={props.offtimes} />
    }
    default: {
      return null
    }
  }
}
/*
 * HOOK
 */
export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('Trying to use ModalContext outside of the Provider')
  }
  return context
}
