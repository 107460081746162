import { RequestDocument, Variables } from 'graphql-request/dist/types'
import { Response, useAuth, useQuery } from '@aposphaere/core-kit'
import { useEffect, useMemo, useRef } from 'react'

export const useAuthenticatedQuery = <ModelType>(query: RequestDocument, variables?: Variables): Response<ModelType> => {
  const vars = useRef(variables)
  const auth = useAuth()
  const headers = useMemo(() => new Headers({ Authorization: auth.token }), [auth.token])

  useEffect(() => {
    if (variables && JSON.stringify(variables) !== JSON.stringify(vars.current)) {
      vars.current = variables
    }
  }, [variables])

  return useQuery<ModelType>(query, vars.current, headers)
}
