import { allNotes, Note, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { useQuery, UseQueryResult } from 'react-query'
import { QueryOptions } from './utils'

export const useNotesQuery = (options?: QueryOptions<Note[]>): UseQueryResult<Note[], unknown> => {
  const gqlClient = useGqlClient()
  const auth = useAuth()

  return useQuery('notes', async () => (await gqlClient.request<{ notes: Note[] }>(allNotes))?.notes, { enabled: !!auth.user, ...options })
}
