// tslint:disable-next-line:no-any
export const debounce = <A extends any[]>(func: (...args: A) => any, wait: number) => {
  let timeout: NodeJS.Timeout
  return (...args: A) => {
    const later = () => {
      // Execute the callback
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
