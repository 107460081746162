import { gql } from 'graphql-request'

export const createNote = gql`
  mutation($pharmacy_id: String!, $user_id: String!, $title: String, $content: String!, $note_type: String!) {
    createNote(pharmacy_id: $pharmacy_id, user_id: $user_id, note_type: $note_type, content: $content, title: $title) {
      id
      content
      pharmacy {
        id
      }
      note_type
      created_at
      title
    }
  }
`

export const updateNote = gql`
  mutation($id: ID!, $pharmacy_id: String!, $user_id: String!, $title: String, $content: String, $note_type: String) {
    updateNote(id: $id, pharmacy_id: $pharmacy_id, user_id: $user_id, note_type: $note_type, content: $content, title: $title) {
      id
      content
      pharmacy {
        id
      }
      note_type
      created_at
      title
    }
  }
`

export const deleteNote = gql`
  mutation($id: ID!) {
    deleteNote(id: $id) {
      id
    }
  }
`
