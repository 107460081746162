import { gql } from 'graphql-request'
import { DateRange, UserAddress } from '../../models'
import { FederalState, FederalStateHoliday } from '../../models/federal_state'

/**
 * FEDERAL STATE
 */
export type FederalStateQueryResponse = {
  federalState: Required<Pick<FederalState, 'id' | 'name'> & { federal_state: Required<FederalStateHoliday> }>
}

export type FederalStateQueryVariables = {
  id?: string
  year?: number
  date?: DateRange
}

export const federalStateQuery = gql`
  query getFederalState($id: ID, $year: Int, $date: DateRange) {
    federalState(id: $id) {
      name
      id
      holidays(year: $year) {
        date
        label
      }
    }
  }
`

/**
 * FEDERAL STATES
 */
export type FederalStatesQueryResponse = {
  federalState: Required<Pick<FederalState, 'id' | 'name'> & { federal_state: Required<FederalStateHoliday> }>
}

export type FederalStatesQueryVariables = {
  ids?: string[]
  year?: number
  date?: DateRange
}

export const federalStatesQuery = gql`
  query getFederalStates($ids: [ID], $year: Int, $date: DateRange) {
    federalStates(id: $id) {
      name
      id
      holidays(year: $year, date: $date) {
        date
        label
      }
    }
  }
`

/**
 * MY FEDERAL STATE
 */
export type MyFederalStateQueryResponse = { me: { address?: Required<Pick<UserAddress, 'federal_state'>> } }
export type MyFederalStateQueryVariables = {
  year: number
}

export const myFederalStateQuery = gql`
  query getMyFederalState($year: Int!) {
    me {
      address {
        federal_state {
          id
          name
          holidays(year: $year) {
            date
            label
          }
        }
      }
    }
  }
`
