export enum ComputedStateType {
  NOT_SERVED = 'not_served',
  PLANNED_APPOINTMENT = 'planned_appointment',
  EXECUTED_APPOINTMENT = 'executed_appointment',
  FOLLOW_UP = 'follow_up',
  REMOTE_APPOINTMENT = 'remote_appointment',
  EXECUTED_VISIT = 'executed_visit',
  PLANNED_VISIT = 'planned_visit',
  DEACTIVATED = 'deactivated',
}
