import React from 'react'
import { Button, ButtonKind, Modal, ModalKind } from '@aposphaere/ui-components'
import ProjectList from '../ProjectList'
import { useModal } from '../../contexts/modalContext'

interface Props {
  pharmacyId: number
}

const ProjectsModal = ({ pharmacyId }: Props) => {
  const { closeModal } = useModal()
  return (
    <Modal kind={ModalKind.ms} title={'Projekte bearbeiten'} onClose={closeModal} onBack={() => null}>
      <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-end p-4 pt-8 pb-6 place-items-stretch">
        <div className="flex flex-wrap w-full">
          <ProjectList pharmacyId={pharmacyId} />
          <Button kind={ButtonKind.primary} onClick={closeModal}>
            {'Schliessen'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectsModal
