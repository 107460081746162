import React from 'react'

const PassedIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M27.63,20.6946A9.9994,9.9994,0,1,1,26.292,12.41"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="17.9979"
      y1="20.8438"
      x2="26.3375"
      y2="12.5042"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="18.0035"
      y1="20.8438"
      x2="13.1468"
      y2="15.987"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default PassedIcon
