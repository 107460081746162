import { AUTH_UNAUTHENTICATED_EVENT } from '@aposphaere/core-kit'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LOGIN_ROUTE } from '../../routes'

const Events: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    window.addEventListener(AUTH_UNAUTHENTICATED_EVENT, () => {
      history.push(LOGIN_ROUTE)
    })
  })

  return null
}

export default Events
