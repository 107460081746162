import React from 'react'

const EmailIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <path
          d="M26,26H9.1983A1.9568,1.9568,0,0,1,7,24V12a1.8112,1.8112,0,0,1,2-2H27a1.807,1.807,0,0,1,2,2v7"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M7.7786,10.92l8.52,6.48a2.5406,2.5406,0,0,0,3.28.0007l8.52-6.448"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default EmailIcon
