import React from 'react'
import { NoteAttentionIcon } from '../Icons'

const SearchInputNoResults: React.FC = () => {
  const resultStyle = 'w-full h-fit my-0 rounded text-base p-2'
  return (
    <div className="w-full p-2">
      <div className={`text-red-800 flex ${resultStyle}`}>
        <span className="mr-4">
          <NoteAttentionIcon />
        </span>{' '}
        Keine passenden Ergebnisse gefunden.
      </div>
    </div>
  )
}

export default SearchInputNoResults
