import { gql } from 'graphql-request'
import { DateRange } from '../../models'
import { FederalStateHoliday } from '../../models/federal_state'

export type FederalStateHolidaysQueryResponse = {
  stateHolidays: Required<Pick<FederalStateHoliday, 'date' | 'label'>>[]
}

export type FederalStateHolidaysQueryVariables = {
  stateId?: string
  date?: DateRange
  year?: number
}

export const federalStateHolidaysQuery = gql`
  query stateHolidays($stateId: ID, $year: Int, $date: DateRange) {
    stateHolidays(stateId: $stateId, year: $year, date: $date) {
      date
      label
    }
  }
`
