import React from 'react'

const ExpandIcon: React.FC<{ dimension?: string }> = ({ dimension = '36' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.823" height="16.171" viewBox="0 0 8.823 16.171">
    <path
      id="Pfad_205"
      data-name="Pfad 205"
      d="M56.425,30.89,63.1,37.3l6.672-6.409"
      transform="translate(38.3 -55.011) rotate(90)"
      fill="none"
      stroke="#798596"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default ExpandIcon
