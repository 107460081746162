import { Pharmacy, Project, Quarter, User } from '.'
import { AppointmentType } from './appointment_type'
import { UTCDateTime } from './generic'

export interface Appointments {
  appointments: Appointment[]
}
export interface AppointmentStatus {
  id: string
}
export enum AppointmentStatusType {
  Canceled = 7,
  Booked = 2,
  Completed = 3,
}
export interface AppointmentResponse {
  appointment: Appointment
}

export interface AppointmentOrderItem {
  project_id: number
  amount: string
  id?: string
  project?: { name: string; is_orderable: boolean }
}

export interface Appointment {
  id: number
  pharmacy?: Pharmacy
  quarter?: Quarter
  creator?: User
  lastEditor?: User
  date?: UTCDateTime
  end_date?: UTCDateTime
  note?: string
  appointmentType?: AppointmentType
  status?: AppointmentStatus
  order_items: AppointmentOrderItem[]
  status_id?: number
  projects?: Project[]
  is_deleted?: boolean
  cancellation_reason: null | string
  finished_presentations?: FinishedPresentationsResponse[]
}

export interface AppointmentSummary {
  past: number
  future: number
  quarter_id: number
}
export interface AppointmentSummaryInProject {
  name: string
  past: number
  future: number
  contact_goal: number
  amount_summary: number
}
export interface SummaryAppointmentsInQuarter {
  summary: AppointmentSummary
  projects?: AppointmentSummaryInProject[]
}

export interface AppointmentTerminationReason {
  id: number
  note_de: string
  note_en?: string
}

export interface AppointmentTerminationReasonsResponse {
  termination_reasons: AppointmentTerminationReason[]
}

export interface FinishedPresentationsResponse {
  endtime: string
  id: string
  number_of_participants: number
  device_count: number
  starttime: string
  project: {
    id: string
    name?: string
  }
  trainer: User
}
