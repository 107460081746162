type IOrder = 'asc' | 'desc'
/**
 * Sorting array of objects by keys deeply
 * @param keys Array of string [k1, k2], where k1 is first level key, k2 is second level key
 * for example obj = { a: { b: 10 } }; obj[a][b] array of keys looks ['a', 'b']
 * @param order is string 'asc' | 'desc' sort top or bottom
 * @param isNumber is boolean for parseInt string value and sort by number
 */
export const compareValues = <O, K extends keyof O>(keys: [key1: K, key2?: keyof O[K]], order: IOrder = 'asc', isNumber?: boolean) => {
  const compare = (val1, val2) => {
    if (typeof val1 === 'string' && typeof val2 === 'string') {
      const comparison = val1.localeCompare(val2)
      return order === 'desc' ? comparison * -1 : comparison
    } else if (typeof val1 === 'number' && typeof val2 === 'number') {
      const comparisonNumber = val1 - val2
      return order === 'desc' ? comparisonNumber * -1 : comparisonNumber
    }
    return 0
  }

  return (a: O, b: O) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(keys[0]) || !b.hasOwnProperty(keys[0])) {
      return 0
    }
    if (keys[1]) {
      if (!a[keys[0]].hasOwnProperty(keys[1]) || !b[keys[0]].hasOwnProperty(keys[1])) {
        return 0
      }
      const val1Deep = isNumber ? +a[keys[0]][keys[1]] : a[keys[0]][keys[1]]
      const val2Deep = isNumber ? +b[keys[0]][keys[1]] : b[keys[0]][keys[1]]
      return compare(val1Deep, val2Deep)
    }
    const val1 = isNumber ? +a[keys[0]] : a[keys[0]]
    const val2 = isNumber ? +b[keys[0]] : b[keys[0]]
    return compare(val1, val2)
  }
}
