import { convertToUTCForBackend, createAppointment, ReminderState, updateReminder, useAuth } from '@aposphaere/core-kit'
import { useCallback } from 'react'
import { useAppointmentTypesQuery, useTasksQuery } from './graphql'
import { useAuthenticatedMutation } from './useAuthenticatedMutation'
import { Variables } from 'graphql-request/dist/types'
import { TRAINING_APPOINTMENT_TYPE_FRAGMENT } from '../constants'

export const useAppointmentCreation = () => {
  const { data: reminders, refetch: refetchTasks } = useTasksQuery()
  const auth = useAuth()
  const appointmentTypesQuery = useAppointmentTypesQuery()
  const appointmentTypes = appointmentTypesQuery.data?.appointmentTypes

  const mutationCreate = useAuthenticatedMutation(createAppointment)
  const updateReminderMutation = useAuthenticatedMutation(updateReminder)

  const deactivateReminders = useCallback(
    async (pharmacyId: string) => {
      if (!reminders?.length) {
        return
      }
      const remindersForActivePharmacy = reminders.filter(({ pharmacy: { id } }) => pharmacyId.toString() === id.toString())

      if (remindersForActivePharmacy.length) {
        await Promise.allSettled(
          remindersForActivePharmacy.map(async (reminder) => {
            const reminderVariables = {
              id: reminder.id,
              pharmacy_id: reminder?.pharmacy.id,
              user_id: auth ? auth.user?.id : undefined,
              state: ReminderState.Inactive,
              contact_person: reminder?.contact_person || '',
              until: convertToUTCForBackend(reminder?.until?.toString().replace(/\s/, 'T')),
              note: reminder?.note || '',
            }
            return updateReminderMutation(reminderVariables).catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e)
            })
          }),
        )
        await refetchTasks()
      }
    },
    [reminders, updateReminderMutation, auth, refetchTasks],
  )

  const createAppointmentAndRemoveReminders = useCallback(
    async (variables: Variables) => {
      const appointmentCreatedResponse = await mutationCreate(variables)
      const isTraining = appointmentTypes
        ?.find((type) => type.id.toString() === variables.appointment_type_id)
        ?.label_short?.toLowerCase()
        .includes(TRAINING_APPOINTMENT_TYPE_FRAGMENT)
      if (appointmentCreatedResponse.errors === undefined && isTraining) {
        deactivateReminders(variables.pharmacy_id).catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
      }
    },
    [deactivateReminders, mutationCreate, appointmentTypes],
  )

  return createAppointmentAndRemoveReminders
}
