import { UTCDateTime } from './generic'
import { Pharmacy } from './pharmacy'
import { User } from './user'

export interface Reminders {
  Reminders: Reminder[]
}

export enum ReminderState {
  Active = 'aktiv',
  Inactive = 'inaktiv',
}

export interface ReminderResponse {
  reminder: Reminder
}

export interface RemindersResponse {
  tasksUser: Reminder[]
}

export interface Reminder {
  id: number
  pharmacy_id: string
  user_id?: string
  note?: string
  contact_person?: string
  until?: UTCDateTime
  state?: string
  pharmacy: Pharmacy
  user: User
  created_at: string
}
